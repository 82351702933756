import React from "react";
import {connect} from "react-redux";
import translate from "../../../../i18n/translate";
import Cards from 'react-credit-cards';
import InputMask from 'react-input-mask';


class CartPaymentCreditCard extends React.Component {

    state = {
        expiry: '',
        focus: '',
        name: '',
        number: '',
        cvc: '',
        cardIsValid: false,
        cardType: false,
    };

    handleInputFocus = (e) => {
        this.setState({focus: e.target.name});
    }

    handleInputChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
        setTimeout(() => {this.setPayment()});
    }

    setPayment = () => {
        if (this.state.cardIsValid && this.state.cardType && this.state.expiry.length === 5 && this.state.name !== '') {
            this.props.setPayment({
                provider: 'credit-card-guarantee',
                number: this.state.number,
                expiry: this.state.expiry,
                name: this.state.name,
                type: this.state.cardType,
            })
        }
    }

    getCardInfo = (type, isValid) => {
        this.setState({cardIsValid: isValid, cardType: type});
    }

    render = () => {
        return (
            <div className="cart-payment-creditcard">

                <div className="cart-payment-creditcard-input">

                    <div className="cart-payment-creditcard-input-group mb-lg">
                        <label htmlFor="number" data-required="true" className="mb-sm">{translate('card-number', this.props.query.language, this.props.translations)}</label>
                        <InputMask type="text" id="number" name="number" size={21} onChange={this.handleInputChange} onFocus={this.handleInputFocus} mask="9999 9999 9999 9999" maskChar=" " />
                    </div>

                    <div className="cart-payment-creditcard-input-group mb-lg">
                        <label htmlFor="expiry" data-required="true" className="mb-sm">{translate('expires', this.props.query.language, this.props.translations)}</label>
                        <InputMask type="text" id="expiry" name="expiry" size={5} onChange={this.handleInputChange} onFocus={this.handleInputFocus} mask="99/99" maskChar=" " />
                    </div>

                    <div className="cart-payment-creditcard-input-group mb-lg">
                        <label htmlFor="name" data-required="true" className="mb-sm">{translate('name-on-card', this.props.query.language, this.props.translations)}</label>
                        <input type="text" id="name" name="name" onChange={this.handleInputChange} onFocus={this.handleInputFocus}/>
                    </div>

                </div>

                <Cards cvc={this.state.cvc} expiry={this.state.expiry} focused={this.state.focus} name={this.state.name} number={this.state.number} callback={this.getCardInfo}/>

            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        cart: state.cart,
        exchanges: state.exchanges,
        property: state.property,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(CartPaymentCreditCard);
