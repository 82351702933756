import React from "react";
import connect from "react-redux/es/connect/connect";
import translate from "../../../i18n/translate";
import ItemOccupancyRoom from "./ItemOccupancyRoom";

class ItemOccupancy extends React.Component {

    state = {
        numberOfRooms: 1,
    }

    getNumberOfRoomsList = () => {

        let numberOfRoomList = [];
        let count = 0
        while (this.state.numberOfRooms > count) {
            count += 1;
            numberOfRoomList.push(count);
        }
        return numberOfRoomList;

    }

    getNumberOfAvailableRoomsArray = () => {

        let numberCounter = 1;
        let numberOfRoomsArray = [];

        // count rooms already in cart
        let alreadyCounter = 0;
        for (const cartItem of this.props.cart) {
            if (cartItem.roomId === this.props.item.id) {
                alreadyCounter += 1;
            }
        }

        // set new quantity
        const quantity = parseInt(this.props.availability.quantity - alreadyCounter);

        // build array
        while (quantity >= numberCounter && numberCounter <= 10) {
            numberOfRoomsArray.push(numberCounter);
            numberCounter += 1;
        }

        return numberOfRoomsArray;
    }

    setNumberOfRooms = (event) => {
        this.setState({numberOfRooms: event.target.value});
        this.props.setNumberOfRooms(event.target.value);
    }

    render = () => {

        return (
            <div className="item-occupancy mt-lg">

                <div className="item-number-of-rooms p-lg">
                    <span className="mb-md">{translate('number-of-rooms', this.props.query.language, this.props.translations)}</span>
                    <select onChange={this.setNumberOfRooms} value={this.state.numberOfRooms}>
                        {this.getNumberOfAvailableRoomsArray().map((number, numberKey) =>
                            <option key={numberKey} value={number}>{number}</option>
                        )}
                    </select>
                </div>

                {this.getNumberOfRoomsList().map((number, numberKey) =>
                    <ItemOccupancyRoom key={numberKey} number={number} item={this.props.item} />
                )}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(ItemOccupancy);
