import React from "react";
import {Col} from "react-grid-system";


export default class SkeletonListItem extends React.Component {

    render = () => {
        return (
            <Col xl={4} lg={6} md={12}>
                <div className="list-item skeleton"/>
            </Col>
        );
    };
}


