export const getContent = (contentArray, language, defaultValue = '') => {

    let content = '';
    for (const contentKey in contentArray) {
        if (contentArray.hasOwnProperty(contentKey)) {
            const contentItem = contentArray[contentKey];
            if (contentItem.language === language) {
                content = contentItem.content;
            }
        }
    }

    if (content === '' && language !== 'en') {
        return getContent(contentArray, 'en', defaultValue);
    } else if (content === '' && language === 'en') {
        return defaultValue;
    } else {
        return content;
    }
}
