export const setCorporatePassword = (corporatePassword) => ({
    type: 'SET_CORPORATE_PASSWORD',
    corporatePassword: corporatePassword
});

export const setCorporatePasswordInvalid = (corporatePasswordInvalid) => ({
    type: 'SET_CORPORATE_PASSWORD_INVALID',
    corporatePasswordInvalid: corporatePasswordInvalid
});

export const setCorporatePasswordValid = (corporatePasswordValid) => ({
    type: 'SET_CORPORATE_PASSWORD_VALID',
    corporatePasswordValid: corporatePasswordValid
});

export const setCorporateMessageShow = (corporateMessageShow) => ({
    type: 'SET_CORPORATE_MESSAGE_SHOW',
    corporateMessageShow: corporateMessageShow,
});

export const setShowExtras = (showExtras) => ({
    type: 'SET_SHOW_EXTRAS',
    showExtras: showExtras
});

export const setMenuOpen = (menuOpen) => ({
    type: 'SET_MENU_OPEN',
    menuOpen: menuOpen
});

export const setCartOpen = (cartOpen) => ({
    type: 'SET_CART_OPEN',
    cartOpen: cartOpen
});

export const setFilters = (filters) => ({
    type: 'SET_FILTERS',
    filters: filters
})

export const resetFilters = () => ({
    type: 'RESET_FILTERS',
})

export const addFilter = (filterId, currentFilters) => {

    return (dispatch) => {

        let newFilters = null;
        if (currentFilters) {

            // copy filters
            newFilters = [...currentFilters]

            // remove if necessary
            const filterIndex = newFilters.indexOf(filterId);
            if (filterIndex > -1) {
                newFilters.splice(filterIndex, 1);
            } else {
                newFilters.push(filterId);
            }

        } else {
            newFilters = [filterId];
        }

        dispatch(setFilters(newFilters));
    }
};

