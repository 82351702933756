import React from "react";
import {connect} from "react-redux";
import Currency from "../../generic/Currency";
import moment from "moment";
import format from "../../../i18n/format";
import {getContent} from "../../../utils/content";
import translate from "../../../i18n/translate";
import {removeFromCart} from "../../../actions/cart";
import {calculateDiscount} from "../../../utils/rates";
import styled, {ThemeProvider} from "styled-components";
import {darken} from "polished";
import {Link} from "react-router-dom";
import TagManager from "react-gtm-module";


const ItemLinkH3 = styled.h3`
    a {
        color: ${props => props.theme.colors.primary};
        &:hover {
            color: ${props => props.theme.primaryHover};
        }
    }
`;

class CartPackageItem extends React.Component {

    state = {
        availability: null,
        packageInfo: null,
        roomInfo: null,
        price: null,
    }

    theme = {
        colors: this.props.property.settings.colors,
        primaryHover: darken(0.1, this.props.property.settings.colors.primary),
    };

    componentDidMount = () => {
        this.setState({packageInfo: this.getPackageInfo(), roomInfo: this.getRoomInfo()});
    }

    getDatesString = () => {
        const checkin = moment(this.props.item.checkin).locale(this.props.query.language);
        const checkout = moment(this.props.item.checkout).locale(this.props.query.language);
        return `${checkin.format(format('date', this.props.query.language))} - ${checkout.format(format('date', this.props.query.language))}`;
    }

    getPackageInfo = () => {
        let packageInfo = null;
        for (const packageKey in this.props.property.packages) {
            if (this.props.property.packages.hasOwnProperty(packageKey)) {
                if (this.props.property.packages[packageKey].id === this.props.item.packageId) {
                    packageInfo = this.props.property.packages[packageKey];
                }
            }
        }
        return packageInfo;
    }

    getRoomInfo = () => {
        let roomInfo = null;
        for (const roomKey in this.props.property.rooms) {
            if (this.props.property.rooms.hasOwnProperty(roomKey)) {
                if (this.props.property.rooms[roomKey].id === this.props.item.roomId) {
                    roomInfo = this.props.property.rooms[roomKey];
                }
            }
        }
        return roomInfo;
    }

    getDiscountedPrice = () => {
        const roomInfo = this.getRoomInfo();
        const checkin = moment(this.props.item.checkin).locale(this.props.query.language);
        const checkout = moment(this.props.item.checkout).locale(this.props.query.language);
        const numberOfPersons = roomInfo.max_occupancy;
        const numberOfNights = checkout.diff(checkin, 'days');
        return calculateDiscount(this.props.promo.discount, this.props.itemPrice, numberOfPersons, numberOfNights).discountedRate;
    }

    getOccupancyString = () => {

        let occupancyString = '';

        // adults
        if (this.props.item.adults === 1) {
            occupancyString += `${this.props.item.adults} ${translate('adult', this.props.query.language, this.props.translations)}`;
        } else if (this.props.item.adults > 1) {
            occupancyString += `${this.props.item.adults} ${translate('adults', this.props.query.language, this.props.translations)}`;
        }

        // children
        if (this.props.item.children === 1) {
            occupancyString += `, ${this.props.item.children} ${translate('child', this.props.query.language, this.props.translations)}`;
        } else if (this.props.item.children > 1) {
            occupancyString += `, ${this.props.item.children} ${translate('children', this.props.query.language, this.props.translations)}`;
        }

        // infants
        if (this.props.item.infants === 1) {
            occupancyString += `, ${this.props.item.infants} ${translate('infant', this.props.query.language, this.props.translations)}`;
        } else if (this.props.item.infants > 1) {
            occupancyString += `, ${this.props.item.infants} ${translate('infants', this.props.query.language, this.props.translations)}`;
        }

        return occupancyString;

    }

    removeItem = (item) => {

        // send datalayer
        let dataLayer = {
            'event': 'remove_from_cart',
            'ecommerce': {
                'items': {
                    'item': {
                        'item_name': item.external_name,
                        'affiliation': 'Paxxio OBE'
                    }
                }
            }
        }

        // set tag manager
        TagManager.dataLayer({dataLayer: dataLayer});

        // remove from card
        this.props.dispatch(removeFromCart(item));
    }

    render = () => {

        if (this.state.packageInfo && this.state.roomInfo) {

            return (

                <ThemeProvider theme={this.theme}>

                    <div className="mb-md">
                        <div className="cart-item">

                            <div className="cart-item-actions p-lg">

                                <div onClick={() => {this.removeItem(this.props.item)}} className="cart-item-remove pr-lg">
                                    <i className="fas fa-trash-alt"/>
                                </div>

                                <div className="cart-item-info">
                                    <ItemLinkH3>
                                        <Link to={`/${this.props.property.id}/packages/${this.props.item.packageId}/`}>{getContent(this.state.packageInfo.names, this.props.query.language)}</Link>
                                    </ItemLinkH3>
                                    <div className="cart-item-info-rate-name mt-md"><i className="fas fa-door-open"/> {getContent(this.state.roomInfo.names, this.props.query.language)}</div>
                                    <div className="cart-item-info-occupancy mt-xs"><i className="fas fa-user"/> {this.getOccupancyString()}</div>
                                    <div className="cart-item-info-dates mt-xs"><i className="fas fa-calendar"/> {this.getDatesString()}</div>
                                </div>

                            </div>

                            <div className="cart-item-price p-lg">
                                {this.props.promo.discount ? (
                                    <span className="discounted-rate">
                                        <Currency>{this.props.itemPrice}</Currency> <Currency>{this.getDiscountedPrice()}</Currency>
                                    </span>
                                ) : (
                                    <Currency>{this.props.itemPrice}</Currency>
                                )}
                            </div>

                        </div>
                    </div>
                </ThemeProvider>
            )
        } else {
            return <span/>
        }

    }
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        cart: state.cart,
        exchanges: state.exchanges,
        promo: state.promo,
        property: state.property,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(CartPackageItem);
