import axios from "axios";

export const setProperty = (property) => ({
    type: 'SET_PROPERTY',
    property: property,
});

export const resetProperty = () => ({
    type: 'RESET_PROPERTY',
});

export const startSetProperty = (propertyId) => {

    return (dispatch) => {

        // reset property
        dispatch(resetProperty());

        // do request
        const resourceUrl = `${process.env.API_URL}/v1/properties/${propertyId}/`;
        axios.get(resourceUrl)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch(setProperty(response.data));
                } else {
                    alert('Something wen\'t wrong');
                }
            })
            .catch(function (error, response) {
                if (error.response.status === 404) {
                    window.location = `/${propertyId}/404/`
                } else {
                    alert(error);
                }
            });
    }
};
