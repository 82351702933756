const defaultState = () => {
    return [];
};

export default (state = defaultState(), action) => {

    let stateClone = [...state];
    let newItems = [];

    switch (action.type) {

        case 'ADD_TO_EXTRAS':

            // check current state, and delete if needed
            if (stateClone) {
                for (const itemKey in stateClone) {
                    if (stateClone.hasOwnProperty(itemKey)) {
                        if (stateClone[itemKey].id !== action.item.id) {
                            newItems.push(stateClone[itemKey]);
                        }
                    }
                }
            }

            // add to items
            newItems.push(action.item);

            // return state
            return newItems;

        case 'REMOVE_FROM_EXTRAS':

            // check current state, and delete
            if (stateClone) {
                for (const itemKey in stateClone) {
                    if (stateClone.hasOwnProperty(itemKey)) {
                        if (stateClone[itemKey].id !== action.item.id) {
                            newItems.push(stateClone[itemKey]);
                        }
                    }
                }
            }

            // return state
            return newItems;

        case 'EMPTY_EXTRAS':

            return defaultState();

        default:
            return state;
    }
};
