import React from "react";
import {connect} from "react-redux";
import {Container, Row, Col, Visible, Hidden} from "react-grid-system";
import SearchBar from "./SearchBar";
import {setMenuOpen} from "../../actions/settings";
import styled, {ThemeProvider} from "styled-components";
import {Link} from "react-router-dom";


const Icon = styled.i`
    color: #343a40;
    &:hover {
        color: ${props => props.theme.colors.primary};
    }
`;

const HomeLink = styled.span`
    color: #343a40;
    transition: 0.2s all;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        color: ${props => props.theme.colors.primary};
    }
`;

class Header extends React.Component {

    theme = {
        colors: this.props.property.settings.colors,
    };


    render = () => {

        return (

            <ThemeProvider theme={this.theme}>
                <section id="header">
                    <Container>


                            <Hidden xs sm md>

                                <Row style={{ alignItems: 'center' }}>

                                    <Col xl={4} lg={3}>
                                        <Link to={`/${this.props.property.id}/search/`}>

                                            {this.props.property.logo ? (
                                                <img className="logo pl-lg" src={this.props.property.logo}/>
                                            ) : (
                                                <HomeLink className="font-size-lg font-bold pl-lg">{this.props.property.name}</HomeLink>
                                            )}

                                        </Link>
                                    </Col>

                                    <Col xl={4} lg={6}>
                                        <SearchBar numberOfCalendars={2} dateFormat={'default'}/>
                                    </Col>

                                    <Col xl={4} lg={3} className="text-right">
                                        {this.props.property.settings.has_corporate && (
                                            <Link to={`/${this.props.property.id}/corporate/?language=${this.props.query.language}&currency=${this.props.query.currency}`}>
                                                <Icon className="cart-button far fa-lock pr-lg"/>
                                            </Link>
                                        )}
                                        <Link to={`/${this.props.property.id}/cart/`}>
                                            <Icon className="cart-button far fa-shopping-cart pr-lg"/>
                                        </Link>
                                        <Icon className="menu-button far fa-globe-americas pr-lg" onClick={() => {this.props.dispatch(setMenuOpen(true))}}/>
                                    </Col>

                                </Row>

                            </Hidden>

                            <Hidden lg xl xxl>

                                <Row style={{ alignItems: 'center', justifyContent: 'center' }}>

                                    <Col xs={7} className="mt-lg mb-md">
                                        <Link to={`/${this.props.property.id}/search/`}>
                                            <HomeLink className="font-size-lg font-bold pl-lg">
                                                {this.props.property.name}
                                            </HomeLink>
                                        </Link>
                                    </Col>

                                    <Col xs={5} className="text-right mt-lg mb-md">

                                        {this.props.property.settings.has_corporate && (
                                            <Link to={`/${this.props.property.id}/corporate/?language=${this.props.query.language}&currency=${this.props.query.currency}`}>
                                                <Icon className="cart-button far fa-lock pr-lg"/>
                                            </Link>
                                        )}

                                        <Link to={`/${this.props.property.id}/cart/`}>
                                            <Icon className="cart-button far fa-shopping-cart pr-lg"/>
                                        </Link>
                                        <Icon className="menu-button far fa-globe-americas pr-lg" onClick={() => {this.props.dispatch(setMenuOpen(true))}}/>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md={12} className="mb-lg">
                                        <SearchBar numberOfCalendars={1} dateFormat={'short'}/>
                                    </Col>
                                </Row>

                            </Hidden>


                    </Container>
                </section>
            </ThemeProvider>
        );
    };
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        property: state.property,
        rooms: state.rooms,
        query: state.query,
    };
};

export default connect(mapStateToProps)(Header);
