import moment from "moment";

const defaultState = () => {
    return {
        showPromoModal: null,
        discount: null,
        notFound: false,
    }
};

export default (state = defaultState(), action) => {

    switch (action.type) {

        case 'SHOW_PROMO_CODE_MODAL':
            return {
                ...state,
                showPromoModal: true,
            };

        case 'HIDE_PROMO_CODE_MODAL':
            return {
                ...state,
                showPromoModal: false,
            };

        case 'SET_DISCOUNT':
            return {
                ...state,
                discount: action.discount,
                notFound: false,
            };

        case 'SET_NOT_FOUND':
            return {
                ...state,
                notFound: true,
            };

       case 'UNSET_NOT_FOUND':
            return {
                ...state,
                notFound: false,
            };

        default:
            return state;
    }
};

