const format = {

    // languages
    'en': {
        'date': 'ddd M/D/YYYY',
        'date_default': 'ddd M/D/YYYY',
        'date_short': 'M/D/YYYY',
        'date_pretty': 'dd, MMM D',
    },
    'nl': {
        'date': 'dd D-M-YYYY',
        'date_default': 'dd D-M-YYYY',
        'date_short': 'D-M-YYYY',
        'date_pretty': 'dd, D MMM',
    },
    'de': {
        'date': 'dd D-M-YYYY',
        'date_default': 'dd D-M-YYYY',
        'date_short': 'D-M-YYYY',
        'date_pretty': 'dd, D MMM',
    },
    'fr': {
        'date': 'dd D-M-YYYY',
        'date_default': 'dd D-M-YYYY',
        'date_short': 'D-M-YYYY',
        'date_pretty': 'dd D MMM',
    },
    'es': {
        'date': 'dd D-M-YYYY',
        'date_default': 'dd D-M-YYYY',
        'date_short': 'D-M-YYYY',
        'date_pretty': 'dd, D MMM',
    },
    'it': {
        'date': 'dd D-M-YYYY',
        'date_default': 'dd D-M-YYYY',
        'date_short': 'D-M-YYYY',
        'date_pretty': 'dd D MMM',
    },
    'no': {
        'date': 'dd D-M-YYYY',
        'date_default': 'dd D-M-YYYY',
        'date_short': 'D-M-YYYY',
        'date_pretty': 'dd D MMM',
    },
    'dk': {
        'date': 'dd D-M-YYYY',
        'date_default': 'dd D-M-YYYY',
        'date_short': 'D-M-YYYY',
        'date_pretty': 'dd D MMM',
    },
    'se': {
        'date': 'dd D-M-YYYY',
        'date_default': 'dd D-M-YYYY',
        'date_short': 'D-M-YYYY',
        'date_pretty': 'dd D MMM',
    },
    'fi': {
        'date': 'dd D-M-YYYY',
        'date_default': 'dd D-M-YYYY',
        'date_short': 'D-M-YYYY',
        'date_pretty': 'dd D MMM',
    },

    // currencies
    'EUR': {
        'symbol': '€',
        'thousandSeparator': '.',
        'decimalSeparator': ',',
    },
    'JPY': {
        'symbol': '¥',
        'thousandSeparator': ',',
        'decimalSeparator': '.',
    },
    'GBP': {
        'symbol': '£',
        'thousandSeparator': ',',
        'decimalSeparator': '.',
    },
    'USD': {
        'symbol': 'USD',
        'thousandSeparator': ',',
        'decimalSeparator': '.',
    },
    'AUD': {
        'symbol': 'AUD',
        'thousandSeparator': ',',
        'decimalSeparator': '.',
    },
    'CAD': {
        'symbol': 'CAD',
        'thousandSeparator': ',',
        'decimalSeparator': '.',
    },
    'CHF': {
        'symbol': 'CHF',
        'thousandSeparator': '\'',
        'decimalSeparator': ',',
    },
    'CNY': {
        'symbol': 'CNY',
        'thousandSeparator': ',',
        'decimalSeparator': '.',
    },
    'SEK': {
        'symbol': 'SEK',
        'thousandSeparator': ' ',
        'decimalSeparator': ',',
    },
    'NZD': {
        'symbol': 'NZD',
        'thousandSeparator': ',',
        'decimalSeparator': '.',
    },
    'NOK': {
        'symbol': 'NOK',
        'thousandSeparator': '.',
        'decimalSeparator': ',',
    },
    'DKK': {
        'symbol': 'DKK',
        'thousandSeparator': '.',
        'decimalSeparator': ',',
    },
    'GEL': {
        'symbol': 'GEL',
        'thousandSeparator': '.',
        'decimalSeparator': ',',
    },
    'ANG': {
        'symbol': 'NAƒ',
        'thousandSeparator': '.',
        'decimalSeparator': ',',
    },
};


export default (key, language) => {
    return format[language][key];
};
