import React from "react";
import {connect} from "react-redux";
import { motion } from "framer-motion";
import {setCartOpen, setMenuOpen} from "../../actions/settings";

const variants = {
    open: {opacity: 0.6},
    closed: {opacity: 0},
}

class Overlay extends React.Component {

    constructor(props) {
        super(props);
        this.overlayRef = React.createRef();
    }

    componentDidUpdate = (prevProps) => {

        if (this.props.settings.menuOpen && this.props.settings.menuOpen !== prevProps.settings.menuOpen) {
            this.overlayRef.current.style.display = 'block';
            this.overlayRef.current.style.visibility = 'visible';
        } else if (!this.props.settings.menuOpen && this.props.settings.menuOpen !== prevProps.settings.menuOpen) {
            setTimeout(() => {
                this.overlayRef.current.style.display = 'none';
                this.overlayRef.current.style.visibility = 'hidden';
            }, 200);
        }

        if (this.props.settings.cartOpen && this.props.settings.cartOpen !== prevProps.settings.cartOpen) {
            this.overlayRef.current.style.display = 'block';
            this.overlayRef.current.style.visibility = 'visible';
        } else if (!this.props.settings.cartOpen && this.props.settings.cartOpen !== prevProps.settings.cartOpen) {
            setTimeout(() => {
                this.overlayRef.current.style.display = 'none';
                this.overlayRef.current.style.visibility = 'hidden';
            }, 200);
        }

    }

    closeOverlays = () => {
        this.props.dispatch(setMenuOpen(false));
        this.props.dispatch(setCartOpen(false));
    }

    render = () => {
        return (
            <motion.nav ref={this.overlayRef} onClick={this.closeOverlays} animate={this.props.settings.menuOpen || this.props.settings.cartOpen ? 'open' : 'closed'} variants={variants} id="overlay"/>
        );
    };
}


const mapStateToProps = (state) => {
    return {
        settings: state.settings,
    };
};

export default connect(mapStateToProps)(Overlay);
