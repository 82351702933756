import CurrencyFormat from "react-currency-format";
import React from "react";
import connect from "react-redux/es/connect/connect";
import format from "../../i18n/format";


class Currency extends React.Component {

    state = {
        value: null,
    }

    getCurrencySymbol = () => {
        return format('symbol', this.props.query.currency) + ' ';
    };

    getThousandSeparator = () => {
        return format('thousandSeparator', this.props.query.currency);
    };

    getDecimalSeparator = () => {
        return format('decimalSeparator', this.props.query.currency);
    };

    getPrefix = () => {
        if (this.props.prefix) {
            return this.props.prefix + this.getCurrencySymbol();
        } else {
            return this.getCurrencySymbol()
        }
    }

    componentDidMount = () => {
        this.setValue();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.query.currency && this.props.query.currency !== prevProps.query.currency) {
            this.setValue();
        }
        if(!isNaN(this.props.children)) {
            if (this.props.children !== prevProps.children) {
                this.setValue();
            }
        }
    }

    setValue = () => {
        if (this.props.property.settings.source_currency !== this.props.query.currency) {
            const convertedValue = this.convertExchange(this.props.children, this.props.query.currency);
            this.setState({value: convertedValue});
        } else {
            this.setState({value: this.props.children});
        }
    }

    convertExchange = (value, target) => {
        for (const exchangeKey in this.props.exchanges) {
            if (this.props.exchanges.hasOwnProperty(exchangeKey)) {
                const exchange = this.props.exchanges[exchangeKey];
                if (this.props.property.settings.source_currency === exchange.base && target === exchange.currency) {
                    return parseFloat(value * exchange.rate);
                }
            }
        }
    }

    render = () => {
        if (this.state.value) {
            return (
                <CurrencyFormat
                    value={this.state.value}
                    displayType={'text'}
                    thousandSeparator={this.getThousandSeparator()}
                    decimalSeparator={this.getDecimalSeparator()}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={this.getPrefix()}
                />
            )
        } else {
            return <span>...</span>
        }

    }
}

const mapStateToProps = (state) => {
    return {
        exchanges: state.exchanges,
        property: state.property,
        query: state.query,
    };
};

export default connect(mapStateToProps)(Currency);
