import {combineReducers, createStore, applyMiddleware, compose} from "redux";
import availabilityReducer from "../reducers/availability";
import cartReducer from "../reducers/cart";
import exchangesReducer from "../reducers/exchanges";
import extrasReducer from "../reducers/extras";
import itemsReducer from "../reducers/items";
import promoReducer from "../reducers/promo";
import propertyReducer from "../reducers/property";
import queryReducer from "../reducers/query";
import settingsReducer from "../reducers/settings";
import translationsReducer from "../reducers/translations";
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create store
export default () => {
    return createStore(combineReducers({
            availability: availabilityReducer,
            cart: cartReducer,
            exchanges: exchangesReducer,
            extras: extrasReducer,
            items: itemsReducer,
            promo: promoReducer,
            property: propertyReducer,
            query: queryReducer,
            settings: settingsReducer,
            translations: translationsReducer,
        }),
        composeEnhancers(applyMiddleware(thunk))
    );
};
