const defaultState = () => {
    return null
};

export default (state = defaultState(), action) => {

    switch (action.type) {

        case 'SET_EXCHANGES':
            return {
                ...action.exchanges
            };

        default:
            return state;
    }
};
