import React from "react";
import connect from "react-redux/es/connect/connect";
import {calculateDiscount, getTotalPriceByRateAndGuest} from "../../../utils/rates";
import {getContent} from "../../../utils/content";
import Currency from "../../generic/Currency";
import styled, {ThemeProvider} from "styled-components";
import translate from "../../../i18n/translate";
import Button from "../../generic/Button";
import {removeItem} from "../../../actions/items";
import {addToCart} from "../../../actions/cart";
import { withRouter } from "react-router-dom"
import ReactHtmlParser from 'react-html-parser';
import {setShowExtras} from "../../../actions/settings";
import {getFees} from "../../../i18n/fees";


class ItemRoomRates extends React.Component {

    state = {
        selectedRate: null,
        orderedRates: null,
    };

    theme = {
        colors: this.props.property.settings.colors
    };

    componentDidMount = () => {
        this.setOrderedRatesList();
    }

    componentDidUpdate = () => {

        if (this.state.orderedRates && !this.state.selectedRate) {
            this.setInitialSelectedRate();
        }

    }

    getRateInfo = (rateId) => {
        for (const rateInfo of this.props.property.rates) {
            if (rateInfo.id === rateId) {
                return rateInfo;
            }
        }
    };

    setOrderedRatesList = () => {

        // build list
        let orderedRatesList = [];
        for (const rate of this.props.rates) {
            const minOccupancy = this.props.item.min_occupancy;
            const lowestPrice = getTotalPriceByRateAndGuest(rate, 'adult', minOccupancy);
            if (rate.available) {
                orderedRatesList.push({lowestPrice: lowestPrice, rate: rate});
            }
        }

        // order list
        orderedRatesList.sort((a, b) => (a.lowestPrice > b.lowestPrice) ? 1 : -1);

        // save to state
        this.setState({orderedRates: orderedRatesList});
    }

    addToCart = (rate) => {

        // loop each item
        for (const itemKey in this.props.items) {

            if (this.props.items.hasOwnProperty(itemKey)) {

                // get item
                const item = this.props.items[itemKey];

                // go and add to cart
                const cartItem = {
                    id: Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
                    checkin: this.props.query.checkin.format('YYYY-MM-DD'),
                    checkout: this.props.query.checkout.format('YYYY-MM-DD'),
                    type: 'room',
                    roomId: this.props.item.id,
                    rateId: rate.id,
                    adults: item.adults,
                    children: item.children,
                    infants: item.infants,
                    extras: [],
                    fees: this.props.item.fees,
                }

                // remove item from items
                this.props.dispatch(removeItem(item));

                // add to cart
                this.props.dispatch(addToCart(cartItem));


                // check if there are extras
                if (this.props.property.extras && this.props.extras.length === 0 && this.props.numberOfRooms === 1) {

                    // show extras
                    this.props.dispatch(setShowExtras(true));

                } else {

                    // redirect to cart
                    this.props.history.push(`/${this.props.property.id}/cart/`);

                }



            }
        }
    }

    setInitialSelectedRate = () => {
        this.setState({selectedRate: this.state.orderedRates[0].rate});
    }

    getTotalPriceByOccupancy = (rate) => {

        let adultsTotal = 0;
        let childrenTotal = 0;
        let infantsTotal = 0;
        let numberOfPersons = 0;
        for (const itemKey in this.props.items) {
            if (this.props.items.hasOwnProperty(itemKey)) {

                // get item
                const item = this.props.items[itemKey];

                // check if children are set
                let additionalAdults = 0;
                if (item.children > 0) {

                    // get child price
                    const childPrice = getTotalPriceByRateAndGuest(rate, 'child', item.children);
                    if (childPrice === 0) {

                        // add additional adults
                        additionalAdults = item.children;

                    } else {
                        // add to children total
                        childrenTotal += childPrice;
                    }

                }

                // get infants price
                if (item.infants > 0) {
                    infantsTotal += getTotalPriceByRateAndGuest(rate, 'infant', item.infants);
                }

                // get adults price
                adultsTotal += getTotalPriceByRateAndGuest(rate, 'adult', item.adults + additionalAdults);

                // add to numberOfPersons
                numberOfPersons = parseInt(item.adults + item.children);

            }
        }

        // return totals
        const totals = adultsTotal + childrenTotal + infantsTotal;

        // calculate fees
        const numberOfNights = this.props.query.checkout.diff(this.props.query.checkin, 'days');

        // parse price
        let parsedPrice = (totals / 100);

        // get fees
        const feeTotals = getFees(
            this.props.item.fees,
            parsedPrice,
            numberOfNights,
            numberOfPersons,
            this.props.property.settings.default_currency,
            this.props.exchanges,
        )

        // add fees to parsedPrice
        parsedPrice = parsedPrice + feeTotals;

        // check if price per night is set
        if (this.props.property.settings.interface.show_price_per_night) {
            parsedPrice = parseFloat(parsedPrice / numberOfNights);
        }

        // add discount if set
        let discount = null;
        let returnData = null;
        if (this.props.promo.discount) {

            discount = calculateDiscount(this.props.promo.discount, parsedPrice, numberOfPersons, numberOfNights, this.props.property.settings.interface.show_price_per_night);

            returnData = {
                parsedPrice: parsedPrice,
                discountedPrice: discount.discountedRate,
            };

        } else {
            returnData = {
                parsedPrice: parsedPrice
            };
        }

        // return data
        return returnData;

    }

    render = () => {


        if (this.state.orderedRates && this.state.selectedRate) {
            return (

                <ThemeProvider theme={this.theme}>

                    <div className="item-room-rates mb-xl">

                        {this.state.orderedRates.map((orderedRate, index) => (

                            <div key={index} className="item-rate p-lg mb-lg">

                                <div className="item-rate-header mb-lg">

                                    <h3 className="item-name">
                                        {getContent(this.getRateInfo(orderedRate.rate.id).names, this.props.query.language)}
                                    </h3>

                                    <span className="item-more">
                                        {translate('more', this.props.query.language, this.props.translations)}
                                        <i className="far fa-chevron-down ml-sm"/>
                                    </span>

                                </div>

                                <div className="item-rate-body">
                                    {ReactHtmlParser(getContent(this.getRateInfo(orderedRate.rate.id).descriptions, this.props.query.language))}
                                </div>

                                <div className="item-rate-cta mt-md">

                                    <div className="item-rate-cta-price">

                                        <h3>
                                            {this.props.promo.discount ? (
                                                <span className="discounted-rate">
                                                    <Currency>{this.getTotalPriceByOccupancy(orderedRate.rate).parsedPrice}</Currency> <Currency>{this.getTotalPriceByOccupancy(orderedRate.rate).discountedPrice}</Currency>
                                                </span>
                                            ) : (
                                                <Currency>{this.getTotalPriceByOccupancy(orderedRate.rate).parsedPrice}</Currency>
                                            )}
                                        </h3>

                                        {this.props.property.settings.interface.show_price_per_night && (
                                            <span className="item-rat-per-night">&nbsp;/&nbsp;{translate('per-night', this.props.query.language, this.props.translations)}</span>
                                        )}
                                    </div>

                                    <Button onClick={() => this.addToCart(orderedRate.rate)} type={'primary'}>{translate('book-now', this.props.query.language, this.props.translations)}</Button>

                                </div>

                            </div>
                        ))}
                    </div>

                </ThemeProvider>
            )
        } else {
            return <span/>
        }

    }
}

const mapStateToProps = (state) => {
    return {
        exchanges: state.exchanges,
        extras: state.extras,
        items: state.items,
        promo: state.promo,
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default withRouter(connect(mapStateToProps)(ItemRoomRates));
