import React from "react";
import axios from "axios";

class RedirectPage extends React.Component {

    state = {
        password: ''
    }

    componentDidMount = () => {

        // create url
        let resourceUrl = `${process.env.API_URL}/v1/corporate/${this.props.match.params.code}`;

        // get info
        axios.get(resourceUrl)
            .then(function (response) {
                if (response.status === 200) {

                    // build url
                    let url;
                    if (response.data.corporate_password !== null) {
                        url = `/${response.data.property}/?cp=${response.data.corporate_password.password}`;
                    } else {
                        url = `/${response.data.property}/corporate/`;
                    }

                    // redirect
                    window.location = url;

                } else {
                    window.location = '/404/';
                }
            })
            .catch(function (error) {
                window.location = '/404/';
            });

    }

    render = () => {
        return (
            <span/>
        );
    };
}


export default RedirectPage;
