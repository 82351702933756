import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from "./store/configureStore";
import {Provider} from "react-redux";
import AppRouter from "./routers/AppRouter";
import {setConfiguration} from 'react-grid-system';
import './styles/styles.scss';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {setCorporatePassword} from "./actions/settings";


// grid config
setConfiguration({
    breakpoints: [768, 992, 1200, 1600, 1680],
    containerWidths: [740, 960, 1140, 1540, 1620],
    gutterWidth: 30,
});

// get config
require('dotenv').config();

// setup sentry
Sentry.init({
    dsn: "https://bc7fcc29532f486a8a3bb2745037ad21@o160938.ingest.sentry.io/5463943",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});


// set store
const store = configureStore();

// redirect legacy reservations
if (window.location.pathname.includes('/reservation/')) {

    // redirect to legacy
    window.location = `https://legacy.paxxio.com${window.location.pathname}`;

} else {

    // check if there is a corporate password (cp)
    const urlParams = new URLSearchParams(window.location.search);
    const corporatePassword = urlParams.get('cp');

    // set cp
    store.dispatch(setCorporatePassword(corporatePassword));


    // Initial jsx
    const jsx = (
        <Provider store={store}>
            <AppRouter/>
        </Provider>
    );

    // render app
    ReactDOM.render(jsx, document.getElementById('app'));

}

