import React from "react";
import {connect} from "react-redux";
import Base from "../../Base";
import {Container, Row, Col} from "react-grid-system";
import Overlay from "../../partials/Overlay";
import Menu from "../../partials/Menu";
import Header from "../../partials/Header";
import {getContent} from "../../../utils/content";
import Button from "../../generic/Button";
import {Link} from "react-router-dom";
import styled, {ThemeProvider} from "styled-components";
import {startSetAvailability} from "../../../actions/availability";
import translate from "../../../i18n/translate";
import Cart from "../../partials/Cart";
import ItemOccupancy from "./ItemOccupancy";
import ItemPackageRooms from "./ItemPackageRooms";
import ItemRoomRates from "./ItemRoomRates";
import PromoModal from "../../generic/PromoModal";
import ReactHtmlParser from "react-html-parser";
import Extras from "../../generic/Extras";
import Modal from "../../generic/Modal";
import {setCorporateMessageShow} from "../../../actions/settings";
import TagManager from "react-gtm-module";


const Icon = styled.i`
    color: ${props => props.theme.colors.primary};
`;

class ItemPage extends React.Component {

    state = {
        selectedItem: null,
        selectedImage: null,
        selectedAvailability: null,
        numberOfItems: 1,
        numberOfRooms: 1,
        dataLayerSend: false,
        fees: [],

    };

    theme = {
        colors: this.props.property.settings.colors,
    };

    componentDidMount = () => {

        if (!this.state.selectedItem) {
            this.setSelectedItem();
        }

        if (!this.props.availability) {
            this.props.dispatch(startSetAvailability(this.props.property.id, this.props.query.checkin, this.props.query.checkout,  this.props.settings.corporatePassword));
        }

    }

    componentDidUpdate = (prevProps, prevState) => {

        if (this.state.selectedItem && this.state.selectedItem !== prevState.selectedItem) {
            this.setItemFees();
        }

        if (this.state.selectedItem && !this.state.selectedImage) {
            this.setInitialSelectedImage();
        }

        if (this.props.availability && !this.state.selectedAvailability) {
            this.setSelectedAvailability();
        }

        if (this.props.availability && this.props.availability !== prevProps.availability) {
            this.setSelectedAvailability();
        }

        if (!this.props.availability && this.props.availability !== prevProps.availability) {
            this.setState({selectedAvailability: null});
        }
    }

    setInitialSelectedImage = () => {
        if (this.state.selectedItem.images.length > 0) {
            this.setState({selectedImage: this.state.selectedItem.images[0]});
        } else {
            this.setState({selectedImage: {
                id: 0,
                file: 'https://via.placeholder.com/900x500?text=No+image',
                description: 'Placeholder'
            }});
        }
    }

    setNumberOfRooms = (numberOfRooms) => {
        this.setState({numberOfRooms: numberOfRooms});
    }

    setSelectedItem = () => {

        let selectedItem = null;

        // get selected room
        if (this.props.match.params.type === 'rooms') {
            for (const roomKey in this.props.property.rooms) {
                if (this.props.property.rooms.hasOwnProperty(roomKey)) {
                    const currentItem = this.props.property.rooms[roomKey];
                    if (currentItem.id === this.props.match.params.item) {
                        selectedItem = currentItem;
                    }
                }
            }
        }

        // get selected package
        else if (this.props.match.params.type === 'packages') {
            for (const packageKey in this.props.property.packages) {
                if (this.props.property.packages.hasOwnProperty(packageKey)) {
                    const currentItem = this.props.property.packages[packageKey];
                    if (currentItem.id === this.props.match.params.item) {
                        selectedItem = currentItem;
                    }
                }
            }
        }

        // check and set selectedItem
        if (selectedItem) {
            this.setState({selectedItem: selectedItem});
        } else {
            console.error('Could not select item');
        }

    }

    setSelectedAvailability = () => {

        // get selected availability
        let selectedAvailability = null;

        for (const roomAvailability of this.props.availability[this.props.match.params.type]) {
            if (roomAvailability.id === this.state.selectedItem.id) {
                selectedAvailability = roomAvailability;
            }
        }

        // set selected availability
        this.setState({selectedAvailability: selectedAvailability});


        // check if data is already send
        setTimeout(500, () => {

            if (this.state.dataLayerSend === false) {

                // save state
                this.setState({dataLayerSend: true});

                // datalayer items
                // let items = [];
                // items.push({'item': {
                //     'item_name': this.state.selectedItem.external_name
                // }})

                // datalayer data
                let dataLayer = {
                    'event': 'view_item',
                    'ecommerce': {
                        'items': {
                            'item': {
                                'item_name': this.state.selectedItem.external_name,
                            }
                        }
                    }
                }

                // datalayer send
                TagManager.dataLayer({dataLayer: dataLayer});

            }

        })


    }

    setItemFees = () => {
        let fees = [];
        for (const fee of this.state.selectedItem.fees) {
            fees.push(fee['fee']);
        }
        this.setState({fees: fees});
    }

    render = () => {
        return (
            <div id="item-page">

                {this.props.property.extras && this.props.extras.length === 0 && this.state.numberOfRooms === 1 && (
                    <Extras/>
                )}

                <Overlay/>

                <Menu/>

                <Cart/>

                <Header/>

                <PromoModal/>

                <ThemeProvider theme={this.theme}>

                    {this.state.selectedItem && this.state.selectedImage ? (

                        <Container>

                            <Row className="pl-lg pr-lg mt-lg">
                                <Col>
                                    <Link to={`/${this.props.property.id}/search/`}>
                                        <Button>
                                            <i className="fas fa-long-arrow-left"/> {translate('back-to-overview', this.props.query.language, this.props.translations)}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>

                            <Row className="pl-lg pr-lg">
                                <Col xl={7} lg={7} md={12}>

                                    <div className="image border-radius item-main-image mt-md">

                                        <img src={this.state.selectedImage.file} alt={this.state.selectedImage.description}/>
                                    </div>

                                    <div className="item-thumbnails">
                                        {this.state.selectedItem.images.map((image, index) => {
                                            return (
                                                <div key={index} onClick={() => {this.setState({selectedImage: image})}} className={this.state.selectedImage.id === image.id ? `image item-thumbnail selected` : `image item-thumbnail`}>
                                                    <img src={image.file} alt={image.description}/>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </Col>

                                <Col xl={5} lg={5} md={12}>

                                    <div className="item-content">

                                        <h2 className="mt-md pt-lg pl-lg pr-lg">
                                            {getContent(this.state.selectedItem.names, this.props.query.language, this.state.selectedItem.external_name)}
                                        </h2>

                                        <div className="pl-lg pr-lg">
                                            {ReactHtmlParser(getContent(this.state.selectedItem.descriptions, this.props.query.language))}
                                        </div>

                                        {this.state.fees.length > 0 && (
                                            <div className="item-fees pl-lg pr-lg pt-lg">
                                                {/*{this.state.fees.map((fee, index) => {*/}
                                                {/*    if (!fee.is_optional) {*/}
                                                {/*        return (*/}
                                                {/*            <div key={index} className="item-fee mt-sm">*/}
                                                {/*                <i className="fad fa-info-circle mr-sm"/>{getContent(fee.names, this.props.query.language, fee.name)}*/}
                                                {/*            </div>*/}
                                                {/*        )*/}
                                                {/*    } else {*/}
                                                {/*        return <p/>*/}
                                                {/*    }*/}
                                                {/*})}*/}
                                            </div>
                                        )}

                                        <div className="item-filters pl-lg pr-lg pb-lg">
                                            {this.state.selectedItem.filters.map((filter, index) => {
                                                return (
                                                    <div key={index} className="item-filter">
                                                        <Icon className="fas fa-check"/> {getContent(filter.names, this.props.query.language)}
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </div>

                                    {this.props.match.params.type === 'rooms' && this.state.selectedAvailability && this.state.selectedAvailability.available && (
                                        <ItemOccupancy item={this.state.selectedItem} availability={this.state.selectedAvailability} setNumberOfRooms={this.setNumberOfRooms} />
                                    )}

                                    <div className="item-choices mt-lg">

                                        {this.props.match.params.type === 'rooms' && this.state.selectedAvailability && this.state.selectedAvailability.available && (
                                            <ItemRoomRates rates={this.state.selectedAvailability.rates} item={this.state.selectedItem} numberOfRooms={this.state.numberOfRooms}/>
                                        )}

                                        {this.props.match.params.type === 'packages' && this.state.selectedAvailability && this.state.selectedAvailability.available && (
                                            <ItemPackageRooms item={this.state.selectedItem}/>
                                        )}

                                        {this.state.selectedAvailability && !this.state.selectedAvailability.available && (
                                            <div className="item-choices-unavailable p-lg"><strong>{translate('no-availability-change-selection', this.props.query.language, this.props.translations)}</strong></div>
                                        )}

                                        {!this.state.selectedAvailability && (
                                            <span className="p-lg">...</span>
                                        )}

                                    </div>

                                </Col>
                            </Row>

                        </Container>

                    ) : (
                        <span>Loading</span>
                    )}
                </ThemeProvider>

                {this.props.settings.corporateMessageShow && this.props.settings.corporatePasswordValid && (
                    <Modal title={translate('reduced-rates', this.props.query.language, this.props.translations)} isOpen={true} canClose={true} closeCallback={() => {this.props.dispatch(setCorporateMessageShow(false))}}>
                        {translate('reduced-rates-text', this.props.query.language, this.props.translations)}
                    </Modal>
                )}

                {this.props.settings.corporateMessageShow && this.props.settings.corporatePasswordInvalid && (
                    <Modal title={translate('reduced-rates', this.props.query.language, this.props.translations)} isOpen={true} canClose={true} isError={true} closeCallback={() => {this.props.dispatch(setCorporateMessageShow(false))}}>
                        {translate('reduced-rates-error', this.props.query.language, this.props.translations)}
                    </Modal>
                )}

            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        exchanges: state.exchanges,
        extras: state.extras,
        property: state.property,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(Base(ItemPage));
