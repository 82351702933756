import React from "react";
import {connect} from "react-redux";
import Base from "../../Base";
import Button from "../../generic/Button";
import translate from "../../../i18n/translate";


class CorporatePage extends React.Component {

    state = {
        password: ''
    }

    onInputChange = (event) => {
        this.setState({password: event.target.value});
    }

    onKeyUp = (event) => {
        if (event.keyCode === 13) {
            this.onButtonClick();
        }
    }

    onButtonClick = () => {
        window.location = `/${this.props.property.id}/?cp=${this.state.password}&language=${this.props.query.language}&currency=${this.props.query.currency}`;
    }

    render = () => {
        return (
            <div id="corporate-page">
                <div className="password-box">
                    <p>{translate('corp_rate_input', this.props.query.language, this.props.translations)}</p>
                    <input type="text" size="30" onChange={(event) => {this.onInputChange(event)}} onKeyUp={(event) => {this.onKeyUp(event)}} name="corporate-password"/>
                    <Button type={'primary'} block={true} onClick={this.onButtonClick}>{translate('continue', this.props.query.language, this.props.translations)}</Button>
                </div>
            </div>
        );
    };
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        exchanges: state.exchanges,
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(Base(CorporatePage));
