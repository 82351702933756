import React from "react";
import {connect} from "react-redux";
import Base from "../../Base";
import {Container, Row, Col} from "react-grid-system";
import Overlay from "../../partials/Overlay";
import Menu from "../../partials/Menu";
import Header from "../../partials/Header";
import {ThemeProvider} from "styled-components";
import Cart from "../../partials/Cart";
import CartRoomItem from "./CartRoomItem";
import {withRouter} from "react-router-dom"
import Currency from "../../generic/Currency";
import translate from "../../../i18n/translate";
import {Link} from "react-router-dom";
import Button from "../../generic/Button";
import CartPackageItem from "./CartPackageItem";
import axios from "axios";
import CartForm from "./CartForm";
import CartPayment from "./payment/CartPayment";
import CartPaymentSkeleton from "./payment/CartPaymentSkeleton";
import {emptyCart, removeFromCart} from "../../../actions/cart";
import Modal from "../../generic/Modal";
import {calculateExtraTotal} from "../../../utils/extras";

class CartPage extends React.Component {

    state = {
        bookingId: null,
        totals: null,
        recalculate: false,
        payment: false,
        data: false,
        redirect: false,
        errorUnavailable: false,
        isSending: false,
        isRedirecting: false,
        paymentNotSet: false,
        cartEmptied: false,
    }

    theme = {
        colors: this.props.property.settings.colors,
    };

    componentDidMount = () => {
        this.setTotals();
    }

    componentDidUpdate = (prevProps) => {

        if (prevProps.cart !== this.props.cart) {
            this.setTotals();
        }

        if (this.props.promo.discount && prevProps.promo.discount !== this.props.promo.discount) {
            this.setTotals();
        }

        if (this.state.redirect !== false) {
            if (!this.state.cartEmptied) {

                // redirect
                this.props.dispatch(emptyCart());
                this.setState({cartEmptied: true});
                window.location = this.state.redirect;

            }
        }
    }

    // getRoomInfo = (roomId) => {
    //     for (const roomInfo of this.props.property.rooms) {
    //         if (roomInfo.id === roomId) {
    //             return roomInfo;
    //         }
    //     }
    // }
    //
    // getPackageInfo = (packageId) => {
    //     for (const packageInfo of this.props.property.packages) {
    //         if (packageInfo.id === packageId) {
    //             return packageInfo;
    //         }
    //     }
    // }
    //
    // getCartItemInfo = (item) => {
    //     let info = null;
    //     if (item.type === 'room') {
    //         info = this.getRoomInfo(item.roomId);
    //     } else if (item.type === 'package') {
    //         info = this.getPackageInfo(item.packageId);
    //     }
    //     return info;
    // }

    getItemById = (itemId) => {
        for (const item of this.props.cart) {
            if (item.id === itemId) {
                return item;
            }
        }
    }

    createBooking = () => {

        // check payment
        if (!this.state.payment) {
            this.setState({paymentNotSet: true});
            return false;
        }

        // get locale
        const locale = navigator.language.toLowerCase() || navigator.userLanguage.toLowerCase();


        // get cart info
        const data = {
            property: this.props.property.id,
            cart: this.props.cart,
            payment: this.state.payment,
            data: this.state.data,
            code: this.props.promo.discount ? this.props.promo.discount.code : null,
            language: this.props.query.language,
            currency: this.props.query.currency,
            locale: locale,
        }

        // set sending
        this.setState({isSending: true});

        // create booking
        const dataJson = JSON.stringify(data);
        axios.post(`${process.env.API_URL}/v1/bookings/`, dataJson)
            .then((response) => {

                // set isSending
                this.setState({isSending: false});

                if (response.status === 201) {

                    // set isRedirecting
                    this.setState({isRedirecting: true});

                    // redirect
                    this.setState({
                        redirect: response.data.redirect,
                        bookingId: response.data.id
                    });

                } else if (response.status === 200) {

                    // remove all unavailable items
                    for (const unavailableItem of response.data.unavailable) {

                        // get item
                        const item = this.getItemById(unavailableItem);

                        // remove from cart
                        this.props.dispatch(removeFromCart(item));

                        // show error
                        this.setState({errorUnavailable: true});

                    }

                } else {
                    alert('Something wen\'t wrong');
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }

    setPayment = (payment) => {
        this.setState({payment: payment});
    }

    setData = (data) => {
        this.setState({data: data});
    }

    setTotals = () => {

        // set recalculate
        this.setState({recalculate: true});

        // check if discount
        let resourceUrl = `${process.env.API_URL}/v1/cart/`;
        if (this.props.promo.discount) {
            resourceUrl = `${process.env.API_URL}/v1/cart/?code=${this.props.promo.discount.code}`;
        }

        // get cart prices
        const cartJson = JSON.stringify(this.props.cart);
        axios.post(resourceUrl, cartJson)
            .then((response) => {
                if (response.status === 200) {

                    this.setState({
                        totals: response.data,
                        recalculate: false
                    });
                } else {
                    alert('Something wen\'t wrong');
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }

    getItemPrice = (itemId) => {
        for (const price of this.state.totals.prices) {
            if (itemId === price.id) {
                return parseFloat(price.price / 100);
            }
        }
    }

    getExtraInfo = (extraId) => {
        for (const extra of this.props.property.extras) {
            if (extra.id === extraId) {
                return extra;
            }
        }
        return false;
    }

    getSubTotal = () => {

        // get subtotal
        const subtotal = parseFloat(this.state.totals.subtotal / 100);
        let extraTotal = 0;

        // get all cart items
        for (const cartItem of this.props.cart) {

            if (cartItem.extras) {

                // get extras
                for (const extraId of cartItem.extras) {

                    // extra info
                    const extraInfo =  this.getExtraInfo(extraId);

                    // calculate
                    extraTotal += calculateExtraTotal(cartItem, extraInfo.price, extraInfo.calculation);
                }
            }
        }

        return extraTotal + subtotal;

    }

    getTotal = () => {

        // get total
        const total = parseFloat(this.state.totals.total / 100);
        let extraTotal = 0;

        // get all cart items
        for (const cartItem of this.props.cart) {

            if (cartItem.extras) {

                // get extras
                for (const extraId of cartItem.extras) {

                    // extra info
                    const extraInfo =  this.getExtraInfo(extraId);

                    // calculate
                    extraTotal += calculateExtraTotal(cartItem, extraInfo.price, extraInfo.calculation);
                }
            }


        }

        return extraTotal + total;

    }

    render = () => {

        return (
            <div id="cart-page">

                <Overlay/>

                <Menu/>

                <Cart/>

                <Header/>

                {this.state.errorUnavailable && (
                    <Modal title={translate('unavailable', this.props.query.language, this.props.translations)} isError={true} closeCallback={() => {
                        this.setState({errorUnavailable: false})
                    }} canClose={true} isOpen={true}>
                        {translate('some-items-removed', this.props.query.language, this.props.translations)}
                    </Modal>
                )}

                {this.state.isSending && (
                    <Modal title={translate('finish', this.props.query.language, this.props.translations)} isOpen={true}
                           showLoading={true}>
                        {translate('finishing-booking', this.props.query.language, this.props.translations)}
                    </Modal>
                )}

                {this.state.isRedirecting && (
                    <Modal title={translate('redirecting', this.props.query.language, this.props.translations)} isOpen={true} showLoading={true}>
                        {translate('now-redirecting', this.props.query.language, this.props.translations)}
                    </Modal>
                )}

                {this.state.paymentNotSet && (
                    <Modal title={translate('payment', this.props.query.language, this.props.translations)}
                           closeCallback={() => {
                               this.setState({paymentNotSet: false})
                           }} isError={true} canClose={true} isOpen={true}>
                        {translate('select-payment-method', this.props.query.language, this.props.translations)}
                    </Modal>
                )}

                <ThemeProvider theme={this.theme}>

                    {this.props.cart.length > 0 ? (

                        <Container>

                            <Row className="pl-lg pr-lg mt-lg">
                                <Col>
                                    <Link to={`/${this.props.property.id}/search/`}>
                                        <Button>
                                            <i className="fas fa-long-arrow-left"/> {translate('back-to-overview', this.props.query.language, this.props.translations)}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>

                            <Row className="pl-lg pr-lg mt-lg">

                                {this.state.totals ? (

                                    <Col xl={6} lg={6} className="mb-lg">
                                        <div className="cart-items">
                                            {this.props.cart.map((cartItem, index) => {
                                                if ('packageId' in cartItem) {
                                                    return <CartPackageItem key={index} item={cartItem} itemPrice={this.getItemPrice(cartItem.id)}/>
                                                } else {
                                                    return <CartRoomItem key={index} item={cartItem} itemPrice={this.getItemPrice(cartItem.id)}/>
                                                }
                                            })}
                                        </div>

                                        <div className="cart-totals p-lg">
                                            <div className="cart-totals-item">
                                                <span>{translate('subtotal', this.props.query.language, this.props.translations)}</span>
                                                {!this.state.recalculate ? (
                                                    <Currency>{this.getSubTotal()}</Currency>
                                                ) : (
                                                    <span>...</span>
                                                )}
                                            </div>

                                            {this.state.totals.city_tax > 0 && (
                                                <div className="cart-totals-item mt-md">
                                                    <span>{translate('citytax', this.props.query.language, this.props.translations)}</span>
                                                    {!this.state.recalculate ? (
                                                        <Currency>{parseFloat(this.state.totals.city_tax / 100)}</Currency>
                                                    ) : (
                                                        <span>...</span>
                                                    )}
                                                </div>
                                            )}

                                            {this.state.totals.fee_total > 0 && (
                                                <div className="cart-totals-item mt-md">
                                                    <span>{translate('otherfees', this.props.query.language, this.props.translations)}</span>
                                                    {!this.state.recalculate ? (
                                                        <Currency>{parseFloat(this.state.totals.fee_total / 100)}</Currency>
                                                    ) : (
                                                        <span>...</span>
                                                    )}
                                                </div>
                                            )}

                                            <div className="cart-totals-item mt-md">
                                                <span>{translate('total', this.props.query.language, this.props.translations)}</span>
                                                {!this.state.recalculate ? (
                                                    <Currency>{this.getTotal()}</Currency>
                                                ) : (
                                                    <span>...</span>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                ) : (
                                    <Col xl={6} lg={6}>
                                        <div className="cart-items">
                                            ...
                                        </div>
                                    </Col>
                                )}

                                <Col xl={6} lg={6}>

                                    {this.state.totals ? (
                                        <CartPayment setPayment={this.setPayment} totals={this.state.totals}/>
                                    ) : (
                                        <CartPaymentSkeleton/>
                                    )}

                                    <CartForm setData={this.setData} createBooking={this.createBooking}/>

                                </Col>

                            </Row>

                        </Container>
                    ) : (
                        <Container>

                            <Row className="pl-lg pr-lg mt-lg">
                                <Col>
                                    <Link to={`/${this.props.property.id}/search/`}>
                                        <Button>
                                            <i className="fas fa-long-arrow-left"/> {translate('back-to-overview', this.props.query.language, this.props.translations)}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>

                            <Row className="pl-lg pr-lg mt-lg">
                                <Col>
                                    <div className="cart-empty p-lg">
                                        {translate('cart-empty', this.props.query.language, this.props.translations)}
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    )}


                </ThemeProvider>

            </div>
        )


    }
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        cart: state.cart,
        exchanges: state.exchanges,
        extras: state.extras,
        promo: state.promo,
        property: state.property,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default withRouter(connect(mapStateToProps)(Base(CartPage)));
