import moment from "moment";

export const calculateExtraTotal = (cartItem, price, calculation) => {

    // number of night
    const startDate = moment(cartItem.checkin);
    const endDate = moment(cartItem.checkout);
    const nights = endDate.diff(startDate, 'days');

    switch (calculation) {

        case 'per_stay':
            return price;

        case 'per_night':
            return parseFloat(price * nights);

        case 'per_person':
            return parseFloat(price * cartItem.adults);

        case 'per_person_per_night':
            return parseFloat(parseFloat(price * cartItem.adults) * nights);

        default:
            return price;
    }
};


