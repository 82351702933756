import React from "react";
import {startSetProperty} from "../actions/property";
import {startSetExchanges} from "../actions/exchanges";
import {startSetTranslations} from "../actions/translations";
import * as queryString from "query-string";
import moment from "moment";
import {setCurrency, setDates, setLanguage} from "../actions/query";
import {getBrowserSettings} from "../i18n/browser";
import {addFilter, resetFilters, setFilters} from "../actions/settings";
import TagManager from 'react-gtm-module';
import {startSetDiscount} from "../actions/promo";


export default (BaseComponent) => {

    class Base extends React.Component {

        state = {
            propertyId: this.props.match.params.property,
            querySet: false,
            tagManagerSet: false,
            setInitialGetParametersSet: false,
        };

        componentDidMount = () => {

            // set initial parameters
            this.setInitialGetParameters();

            // set initials
            if (!this.props.property) {
                this.props.dispatch(startSetProperty(this.state.propertyId));
            }
            if (!this.props.exchanges) {
                this.props.dispatch(startSetExchanges());
            }
            if (!this.props.translations) {
                this.props.dispatch(startSetTranslations());
                this.props.dispatch(startSetTranslations());
            }

        };

        componentDidUpdate = (prevProps) => {
            if (prevProps.property !== this.props.property && this.props.property) {
                this.setQuery();
                this.setGetParameters();
                this.setTagManager();
                document.title = this.props.property.name;
            }
        }

        setTagManager = () => {
            if (this.props.property.settings.tracking.gtm && !this.state.tagManagerSet) {
                TagManager.initialize({gtmId: this.props.property.settings.tracking.gtm});
                this.setState({tagManagerSet: true});
            }
        }

        setQuery = () => {

            // check if query is set
            if (!this.state.querySet) {

                let language = null;
                let currency = null
                const browserSettings = getBrowserSettings();

                // language
                if (this.props.property.settings.languages.includes(browserSettings.language)) {
                    language = browserSettings.language
                } else {
                    language = this.props.property.settings.default_language;
                }

                // currency
                if (this.props.property.settings.currencies.includes(browserSettings.currency)) {
                    currency = browserSettings.currency
                } else {
                    currency = this.props.property.settings.default_currency;
                }

                // set language and currency
                this.props.dispatch(setLanguage(language));
                this.props.dispatch(setCurrency(currency));

                // set state
                this.setState({querySet: true});

            }
        }

        setInitialGetParameters = () => {

            // get parameters
            const getParams = queryString.parse(window.location.search);

            // checkin and checkout
            if (getParams.checkin && getParams.checkout) {

                // set dates
                const checkin = moment(getParams.checkin);
                const checkout = moment(getParams.checkout);

                // do checks and set state
                if (checkin.isValid() && checkout.isValid() && checkin.isBefore(checkout) && checkin.isAfter(moment().subtract(2, 'days'))) {
                    setTimeout(() => {this.props.dispatch(setDates(checkin.locale(this.props.query.language), checkout.locale(this.props.query.language)))});
                }
            }

            // adults / persons
            let adults = parseInt(getParams.adults);
            if (adults) {
                this.props.dispatch(addFilter(`occupancy-${adults}`, this.props.settings.filters));
            }

            // persons
            let persons = parseInt(getParams.persons);
            if (persons) {
                this.props.dispatch(addFilter(`occupancy-${persons}`, this.props.settings.filters));
            }



        }

        setGetParameters = () => {

            // get parameters
            const getParams = queryString.parse(window.location.search);

            // language
            let language = getParams.language;
            if (language) {
                if (this.props.property.settings.languages.includes(language)) {
                    this.props.dispatch(setLanguage(language));
                }
            }

            // currency
            let currency = getParams.currency;
            if (currency) {
                if (this.props.property.settings.currencies.includes(currency)) {
                    this.props.dispatch(setCurrency(currency));
                }
            }

            // promo
            let promo = getParams.promo;
            if (promo) {
                this.props.dispatch(startSetDiscount(promo, this.props.property.id));
            }

            // filters
            let filterString = getParams.filters;
            if (filterString) {
                const filters = filterString.split(',');
                for (const filter of filters) {
                    setTimeout(() => {
                        this.toggleFilter(filter);
                    });

                }
            }
        }

        toggleFilter = (filterId) => {

        // get current and set new filters
        let newFilters = null;
        const currentFilters = this.props.settings.filters;
        if (currentFilters) {

            // set new filters
            newFilters = [...currentFilters]

            // remove if necessary
            const filterIndex = newFilters.indexOf(filterId);
            if (filterIndex > -1) {
                newFilters.splice(filterIndex, 1);
            } else {
                newFilters.push(filterId);
            }

        } else {
            newFilters = [filterId];
        }

        // check if newFilters array
        if (newFilters && newFilters.length > 0) {
            this.props.dispatch(setFilters(newFilters));
        } else {
            this.props.dispatch(resetFilters());
        }

    };

        render = () => {

            // wait for property and rooms to be loaded
            if (this.props.property && this.props.exchanges && this.props.translations) {
                return <BaseComponent {...this.props}/>
            } else {
                return (
                    <div className="page-loader">
                        <i className="fas fa-spinner-third"/>
                    </div>
                )
            }
        }

    }

    return Base;
}


