import React from "react";
import {connect} from "react-redux";
import {motion} from "framer-motion";
import {setMenuOpen} from "../../actions/settings";
import ReactCountryFlag from "react-country-flag";
import styled, {ThemeProvider} from "styled-components";
import {setLanguage, setCurrency} from "../../actions/query";
import translate from "../../i18n/translate";

const variants = {
    open: {opacity: 1, x: 0},
    closed: {opacity: 0, x: '100%'},
}

const OptionDiv = styled.div`
    i.fa-check-circle {
        color: ${props => props.theme.colors.primary};
    }
    &:hover {
        i.fa-circle {
            color: ${props => props.theme.colors.primary};
        }
    }
`;

class Menu extends React.Component {

    theme = {
        colors: this.props.property.settings.colors,
    };

    languages = [
        {value: 'en', text: 'English', code: 'GB'},
        {value: 'nl', text: 'Nederlands', code: 'NL'},
        {value: 'de', text: 'Deutsch', code: 'DE'},
        {value: 'fr', text: 'Français', code: 'FR'},
        {value: 'es', text: 'Español', code: 'ES'},
        {value: 'it', text: 'Italiano', code: 'IT'},
        {value: 'no', text: 'Norsk', code: 'NO'},
        {value: 'dk', text: 'Danish', code: 'DK'},
        {value: 'se', text: 'Swedish', code: 'SE'},
        {value: 'fi', text: 'Finnish', code: 'FI'},
    ]

    currencies = [
        {value: 'USD', text: '$ USD'},
        {value: 'EUR', text: '€ EUR'},
        {value: 'JPY', text: '¥ JPY'},
        {value: 'GBP', text: '£ GBP'},
        {value: 'AUD', text: '$ AUD'},
        {value: 'CAD', text: '$ CAD'},
        {value: 'CHF', text: 'Fr CHF'},
        {value: 'CNY', text: '¥ CNF'},
        {value: 'SEK', text: 'kr SEK'},
        {value: 'NZD', text: '$ NZD'},
        {value: 'NOK', text: 'kr NOK'},
        {value: 'DKK', text: 'kr DKK'},
        {value: 'GEL', text: '₾ GEL'},
        {value: 'ANG', text: 'NAƒ ANG'},
    ]

    setLanguage = (language) => {
        this.props.dispatch(setLanguage(language));
        setTimeout(() => {
            this.props.dispatch(setMenuOpen(false));
        }, 400);
    }

    setCurrency = (currency) => {
        this.props.dispatch(setCurrency(currency));
        setTimeout(() => {
            this.props.dispatch(setMenuOpen(false));
        }, 400);
    }

    render = () => {

        return (

            <ThemeProvider theme={this.theme}>

                <motion.nav animate={this.props.settings.menuOpen ? 'open' : 'closed'} variants={variants} id="menu">

                    <div className="menu-close">
                        <i onClick={() => {this.props.dispatch(setMenuOpen(false))}} className="far fa-times"/>
                    </div>

                    <div className="option-group">
                        <h3>{translate('languages', this.props.query.language, this.props.translations)}</h3>
                        {this.languages.map((object, index) => {
                            if (this.props.property.settings.languages.includes(object.value)) {
                                return (
                                    <OptionDiv key={object.value} className="option" onClick={() => {this.setLanguage(object.value)}}>
                                        <i className={this.props.query.language === object.value ? 'fas fa-check-circle mr-sm' : 'far fa-circle mr-sm'}/>
                                        <ReactCountryFlag countryCode={object.code} svg/>
                                        <span className="ml-xs">{object.text}</span>
                                    </OptionDiv>
                                )
                            }
                        })}
                    </div>

                    <div className="option-group">
                        <h3>{translate('currencies', this.props.query.language, this.props.translations)}</h3>
                        {this.currencies.map((object, index) => {
                            return (
                                <OptionDiv key={object.value} className="option" onClick={() => {this.setCurrency(object.value)}}>
                                    <i className={this.props.query.currency === object.value ? 'fas fa-check-circle mr-sm' : 'far fa-circle mr-sm'}/>
                                    <span className="ml-xs">{object.text}</span>
                                </OptionDiv>
                            )
                        })}
                    </div>

                </motion.nav>
            </ThemeProvider>

        );
    };
}


const mapStateToProps = (state) => {
    return {
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(Menu);
