import axios from "axios";

export const setTranslations = (translations) => ({
    type: 'SET_TRANSLATIONS',
    translations: translations,
});

export const startSetTranslations = (propertyId) => {

    return (dispatch) => {

        // do request
        const resourceUrl = `${process.env.API_URL}/v1/translations/`;
        axios.get(resourceUrl)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch(setTranslations(response.data));
                } else {
                    alert('Something wen\'t wrong');
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }
};
