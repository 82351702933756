import React from "react";
import {connect} from "react-redux";
import {getContent} from "../../../utils/content";
import Currency from "../../generic/Currency";
import {removeExtraFromItem, removeFromCart} from "../../../actions/cart";
import translate from "../../../i18n/translate";
import moment from "moment";
import format from "../../../i18n/format";
import {calculateDiscount} from "../../../utils/rates";
import {Link} from "react-router-dom";
import styled, {ThemeProvider} from "styled-components";
import {darken} from "polished";
import TagManager from "react-gtm-module";


const ItemLinkH3 = styled.h3`
    a {
        color: ${props => props.theme.colors.primary};
        &:hover {
            color: ${props => props.theme.primaryHover};
        }
    }
`;


class CartRoomItem extends React.Component {

    state = {
        roomInfo: null,
        rateInfo: null,
        price: null,
    }

    theme = {
        colors: this.props.property.settings.colors,
        primaryHover: darken(0.1, this.props.property.settings.colors.primary),
    };

    componentDidMount = () => {
        this.setState({roomInfo: this.getRoomInfo(this.props.item.roomId)});
        this.setState({rateInfo: this.getRateInfo(this.props.item.rateId)});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.item !== this.props.item) {
            this.setState({roomInfo: this.getRoomInfo(this.props.item.roomId)});
            this.setState({rateInfo: this.getRateInfo(this.props.item.rateId)});
        }
    }

    getRoomInfo = (roomId) => {
        for (const roomInfo of this.props.property.rooms) {
            if (roomInfo.id === roomId) {
                return roomInfo;
            }
        }
    }

    getRateInfo = (rateId) => {
        for (const rateInfo of this.props.property.rates) {
            if (rateInfo.id === rateId) {
                return rateInfo;
            }
        }
    };

    removeItem = (item) => {

        // send datalayer
        let dataLayer = {
            'event': 'remove_from_cart',
            'ecommerce': {
                'items': {
                    'item': {
                        'item_name': item.external_name,
                        'affiliation': 'Paxxio OBE'
                    }
                }
            }
        }

        // set tag manager
        TagManager.dataLayer({dataLayer: dataLayer});

        // remove from cart
        this.props.dispatch(removeFromCart(item));
    }

    getDatesString = () => {
        const checkin = moment(this.props.item.checkin).locale(this.props.query.language);
        const checkout = moment(this.props.item.checkout).locale(this.props.query.language);
        return `${checkin.format(format('date', this.props.query.language))} ${translate('t-m', this.props.query.language, this.props.translations)} ${checkout.format(format('date', this.props.query.language))}`;
    }

    getDiscountedPrice = () => {
        const checkin = moment(this.props.item.checkin).locale(this.props.query.language);
        const checkout = moment(this.props.item.checkout).locale(this.props.query.language);
        const numberOfPersons = parseInt(this.props.item.adults + this.props.item.children);
        const numberOfNights = checkout.diff(checkin, 'days');
        return calculateDiscount(this.props.promo.discount, this.props.itemPrice, numberOfPersons, numberOfNights).discountedRate;
    }

    getOccupancyString = () => {

        let occupancyString = '';

        // adults
        if (this.props.item.adults === 1) {
            occupancyString += `${this.props.item.adults} ${translate('adult', this.props.query.language, this.props.translations)}`;
        } else if (this.props.item.adults > 1) {
            occupancyString += `${this.props.item.adults} ${translate('adults', this.props.query.language, this.props.translations)}`;
        }

        // children
        if (this.props.item.children === 1) {
            occupancyString += `, ${this.props.item.children} ${translate('child', this.props.query.language, this.props.translations)}`;
        } else if (this.props.item.children > 1) {
            occupancyString += `, ${this.props.item.children} ${translate('children', this.props.query.language, this.props.translations)}`;
        }

        // infants
        if (this.props.item.infants === 1) {
            occupancyString += `, ${this.props.item.infants} ${translate('infant', this.props.query.language, this.props.translations)}`;
        } else if (this.props.item.infants > 1) {
            occupancyString += `, ${this.props.item.infants} ${translate('infants', this.props.query.language, this.props.translations)}`;
        }

        return occupancyString;

    }

    getExtraInfo = (extraId) => {
        for (const extra of this.props.property.extras) {
            if (extra.id === extraId) {
                return extra;
            }
        }
        return false;
    }

    calculateExtraTotal = (price, calculation) => {

        // get latest cart item
        let lastCartItem = this.props.cart[this.props.cart.length - 1];

        if (lastCartItem) {

            // number of night
            const startDate = moment(lastCartItem.checkin);
            const endDate = moment(lastCartItem.checkout);
            const nights = endDate.diff(startDate, 'days')

            switch (calculation) {

                case 'per_stay':
                    return price;

                case 'per_night':
                    return parseFloat(price * nights);

                case 'per_person':
                    return parseFloat(price * lastCartItem.adults);

                case 'per_person_per_night':
                    return parseFloat(parseFloat(price * lastCartItem.adults) * nights);

                default:
                    return price;
            }
        }
    }

    removeExtraFromItem = (itemId, extraId) => {
        this.props.dispatch(removeExtraFromItem(itemId, extraId));
    }

    render = () => {


        if (this.state.roomInfo && this.state.rateInfo) {
            return (
                <ThemeProvider theme={this.theme}>

                    <div className="mb-md">

                        <div className="cart-item">
                            <div className="cart-item-actions p-lg">
                                <div onClick={() => {
                                    this.removeItem(this.props.item)
                                }} className="cart-item-remove pr-lg">
                                    <i className="fas fa-trash-alt"/>
                                </div>
                                <div className="cart-item-info">
                                    <ItemLinkH3>
                                        <Link to={`/${this.props.property.id}/rooms/${this.props.item.roomId}/`}>{getContent(this.state.roomInfo.names, this.props.query.language)}</Link>
                                    </ItemLinkH3>
                                    <div className="cart-item-info-rate-name mt-md">
                                        <i className="fas fa-tags"/> {getContent(this.state.rateInfo.names, this.props.query.language)}
                                    </div>
                                    <div className="cart-item-info-occupancy mt-xs">
                                        <i className="fas fa-user"/> {this.getOccupancyString()}
                                    </div>
                                    <div className="cart-item-info-dates mt-xs">
                                        <i className="fas fa-calendar"/> {this.getDatesString()}
                                    </div>
                                </div>
                            </div>

                            <div className="cart-item-price p-lg">
                                {this.props.promo.discount ? (
                                    <span className="discounted-rate">
                                        <Currency>{this.props.itemPrice}</Currency> <Currency>{this.getDiscountedPrice()}</Currency>
                                    </span>
                                ) : (
                                    <Currency>{this.props.itemPrice}</Currency>
                                )}
                            </div>

                        </div>

                        <div className="cart-extras">

                            {this.props.item.extras.map((extra, index) => {
                                const extraInfo = this.getExtraInfo(extra);
                                return (
                                    <div className="cart-extra" key={index}>
                                        <div className="cart-extra-title">
                                            <i className="fas fa-trash-alt" onClick={() => {this.removeExtraFromItem(this.props.item.id, extra)}}/>
                                            <span>{getContent(extraInfo.names, this.props.query.language)}</span>
                                        </div>
                                        <div className="cart-extra-price"><Currency>{this.calculateExtraTotal(extraInfo.price, extraInfo.calculation)}</Currency></div>
                                    </div>
                                )
                            })}

                        </div>

                    </div>

                </ThemeProvider>
            )
        } else {
            return <span/>
        }

    }
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        cart: state.cart,
        exchanges: state.exchanges,
        extras: state.extras,
        promo: state.promo,
        property: state.property,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(CartRoomItem);
