const defaultState = () => {
    return null
};

export default (state = defaultState(), action) => {

    switch (action.type) {

        case 'SET_PROPERTY':
            return {
                ...action.property
            };

        case 'RESET_PROPERTY':
            return null;

        default:
            return state;
    }
};
