export const getBrowserSettings = () => {

    let locale = undefined;
    const language = navigator.language.toLowerCase() || navigator.userLanguage.toLowerCase();
    switch (language) {
        case 'nl':
        case 'nl-be':
        case 'nl-nl':
            locale = {
                'language': 'nl',
                'currency': 'EUR'
            };
            break;
        case 'en-us':
        case 'en-bz':
        case 'en-jm':
        case 'en-nz':
        case 'en-ph':
        case 'en-za':
        case 'en-tt':
        case 'en-zw':
            locale = {
                'language': 'en',
                'currency': 'USD'
            };
            break;
        case 'en':
        case 'en-uk':
        case 'en-ie':
            locale = {
                'language': 'en',
                'currency': 'GBP'
            };
            break;
        case 'en-au':
            locale = {
                'language': 'en',
                'currency': 'AUD'
            };
            break;
        case 'en-ca':
            locale = {
                'language': 'en',
                'currency': 'CAD'
            };
            break;
        case 'fr':
        case 'fr-be':
        case 'fr-fr':
        case 'fr-lu':
        case 'fr-mc':
            locale = {
                'language': 'fr',
                'currency': 'EUR'
            };
            break;
        case 'fr-ch':
            locale = {
                'language': 'fr',
                'currency': 'CHF'
            };
            break;
        case 'de':
        case 'de-at':
        case 'de-de':
        case 'de-li':
        case 'de-lu':
            locale = {
                'language': 'de',
                'currency': 'EUR'
            };
            break;
        case 'de-ch':
            locale = {
                'language': 'de',
                'currency': 'CHF'
            };
            break;
        case 'es':
        case 'es-es':
            locale = {
                'language': 'es',
                'currency': 'EUR'
            };
            break;
        case 'es-ar':
        case 'es-bo':
        case 'es-cl':
        case 'es-co':
        case 'es-cr':
        case 'es-do':
        case 'es-ec':
        case 'es-sv':
        case 'es-gt':
        case 'es-hn':
        case 'es-mx':
        case 'es-ni':
        case 'es-pa':
        case 'es-py':
        case 'es-pe':
        case 'es-pr':
        case 'es-uy':
        case 'es-ve':
            locale = {
                'language': 'es',
                'currency': 'USD'
            };
            break;
        case 'it':
        case 'it-it':
            locale = {
                'language': 'it',
                'currency': 'USD'
            };
            break;
        case 'it-ch':
            locale = {
                'language': 'it',
                'currency': 'CHF'
            };
            break;
        case 'jp':
        case 'jp-jp':
            locale = {
                'language': 'en',
                'currency': 'JPY'
            };
            break;
        case 'zh':
        case 'zh-cn':
        case 'zh-zk':
            locale = {
                'language': 'en',
                'currency': 'CNY'
            };
            break;
        case 'zh-hk':
        case 'zh-sg':
        case 'zh-tw':
            locale = {
                'language': 'en',
                'currency': 'USD'
            };
            break;
        case 'sv':
        case 'sv-sv':
            locale = {
                'language': 'en',
                'currency': 'SEK'
            };
            break;
        case 'sv-fi':
            locale = {
                'language': 'en',
                'currency': 'EUR'
            };
            break;
        default:
            locale = {
                'language': 'en',
                'currency': 'USD'
            }
    }

    return locale;
};
