function nl2br (str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export default (key, language, translations, props = undefined) => {
    for (const translationKey in translations) {
        if (translations.hasOwnProperty(translationKey)) {
            const translation = translations[translationKey];
            if (translation.key === key) {
                for (const content of translation.contents) {
                    if (content.language === language) {
                        let translation = content.content;
                        if (props) {
                            const keys = Object.keys(props);
                            for (const key of keys) {
                                translation = translation.replace('[' + key + ']', props[key]);
                            }
                        }
                        return nl2br(translation);
                    }
                }
            }
        }
    }
    return '{' + key + '}';
};
