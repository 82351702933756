import React from "react";
import {connect} from "react-redux";
import translate from "../../../i18n/translate";
import Button from "../../generic/Button";
import validator from 'validator';
import styled, {ThemeProvider} from "styled-components";
import {darken} from "polished";

const ConditionLink = styled.a`
    color: ${props => props.theme.colors.primary};
    &:hover {
        color: ${props => props.theme.primaryHover};
    }
`;


class CartForm extends React.Component {

    state = {
        sex: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        comments: '',
        company: '',
        streetName: '',
        houseNumber: '',
        zipCode: '',
        city: '',
        firstNameValid: false,
        lastNameValid: false,
        emailValid: false,
        phoneValid: false,
        companyValid: null,
        streetNameValid: null,
        houseNumberValid: null,
        zipCodeValid: null,
        cityValid: null,
        conditionsValid: null,
        firstNameError: false,
        lastNameError: false,
        emailNameError: false,
        phoneNameError: false,
        companyError: null,
        streetNameError: null,
        houseNumberError: null,
        zipCodeError: null,
        cityError: null,
        conditionsError: null,
    };

    theme = {
        colors: this.props.property.settings.colors,
        primaryHover: darken(0.1, this.props.property.settings.colors.primary),
    };

    componentDidMount = () => {
        if (this.props.property.settings.interface.show_company) {
            this.setState({
                companyValid: false,
                companyError: false
            });
        }
        if (this.props.property.settings.interface.show_address) {
            this.setState({
                streetNameValid: false,
                streetNameError: false,
                houseNumberValid: false,
                houseNumberError: false,
                zipCodeValid: false,
                zipCodeError: false,
                cityValid: false,
                cityError: false,
            });
        }
    }

    handleInputChange = (e) => {

        const {name, value} = e.target;
        this.setState({[name]: value});

        // clear errors
        this.setState({[`${name}Error`]: false});

        // validate
        if (name === 'email') {
            if (validator.isEmail(value)) {
                this.setState({[`${name}Valid`]: true});
            } else {
                this.setState({[`${name}Valid`]: false});
            }
        } else {
            if (!validator.isEmpty(value)) {
                this.setState({[`${name}Valid`]: true});
            } else {
                this.setState({[`${name}Valid`]: false});
            }
        }
    }

    createBooking = () => {

        // all valid
        let allValid = true;

        // firstName
        if (!this.state.firstNameValid) {
            allValid = false;
            this.setState({firstNameError: true});
        }

        // lastName
        if (!this.state.lastNameValid) {
            allValid = false;
            this.setState({lastNameError: true});
        }

        // email
        if (!this.state.emailValid) {
            allValid = false;
            this.setState({emailError: true});
        }

        // email
        if (!this.state.phoneValid) {
            allValid = false;
            this.setState({phoneError: true});
        }

        // company
        // if (this.state.companyValid === false) {
        //     //allValid = false;
        //     this.setState({companyError: true});
        // }

        // streetName
        if (this.state.streetNameValid === false) {
            allValid = false;
            this.setState({streetNameError: true});
        }

        // houseNumber
        if (this.state.houseNumberValid === false) {
            allValid = false;
            this.setState({houseNumberError: true});
        }

        // zipCode
        if (this.state.zipCodeValid === false) {
            allValid = false;
            this.setState({zipCodeError: true});
        }

        // city
        if (this.state.cityValid === false) {
            allValid = false;
            this.setState({cityError: true});
        }

        // conditions
        if (this.props.property.settings.conditions.terms_and_conditions) {
            if (document.querySelector('#conditions:checked') === null) {
                allValid = false;
                this.setState({conditionsError: true});
            }
        }

        // // check if all valid
        if (allValid) {
            this.props.setData({
                sex: this.state.sex,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                comments: this.state.comments,
                company: this.state.company,
                streetName: this.state.streetName,
                houseNumber: this.state.houseNumber,
                zipCode: this.state.zipCode,
                city: this.state.city,
            });
            setTimeout(() => {this.props.createBooking()});
        }
    }

    render = () => {
        return (
            <ThemeProvider theme={this.theme}>
                <div className="cart-form p-lg">

                    <h2 className="title">{translate('your-info', this.props.query.language, this.props.translations)}</h2>

                    {this.props.property.settings.interface.show_company && (
                        <div className="cart-form-group mb-lg">
                            <label htmlFor="company" className="mb-sm" data-format="text" >{translate('company', this.props.query.language, this.props.translations)}</label>
                            <input type="text" className={this.state.companyError ? 'error' : ''} onChange={this.handleInputChange}  name="company"/>
                        </div>
                    )}

                    {this.props.property.settings.interface.show_sex && (
                        <div className="cart-form-group mb-lg">
                            <label htmlFor="sex" className="mb-sm" data-format="text" >{translate('sex', this.props.query.language, this.props.translations)}</label>
                            <select name="sex" id="sex" onChange={this.handleInputChange}>
                                <option>&nbsp;</option>
                                <option value="male">{translate('male', this.props.query.language, this.props.translations)}</option>
                                <option value="female">{translate('female', this.props.query.language, this.props.translations)}</option>
                                <option value="other">{translate('other', this.props.query.language, this.props.translations)}</option>
                            </select>
                        </div>
                    )}

                    <div className="cart-form-group mb-lg">
                        <label htmlFor="first_name" className="mb-sm" data-format="text" data-required="true">{translate('first-name', this.props.query.language, this.props.translations)} *</label>
                        <input type="text" className={this.state.firstNameError ? 'error' : ''} onChange={this.handleInputChange}  name="firstName"/>
                    </div>

                    <div className="cart-form-group mb-lg">
                        <label htmlFor="last_name" className="mb-sm" data-format="text" data-required="true">{translate('surname', this.props.query.language, this.props.translations)} *</label>
                        <input type="text" className={this.state.lastNameError ? 'error' : ''} onChange={this.handleInputChange}  name="lastName"/>
                    </div>

                    {this.props.property.settings.interface.show_address && (
                        <div>

                            <div className="cart-form-group mb-lg">
                                <label htmlFor="street_name" className="mb-sm" data-format="text" data-required="true">{translate('street_name', this.props.query.language, this.props.translations)} *</label>
                                <input type="text" className={this.state.streetNameError ? 'error' : ''} onChange={this.handleInputChange}  name="streetName"/>
                            </div>

                            <div className="cart-form-group mb-lg">
                                <label htmlFor="house_number" className="mb-sm" data-format="text" data-required="true">{translate('house_number', this.props.query.language, this.props.translations)} *</label>
                                <input type="text" className={this.state.houseNumberError ? 'error' : ''} onChange={this.handleInputChange}  name="houseNumber"/>
                            </div>

                            <div className="cart-form-group mb-lg">
                                <label htmlFor="zip_code" className="mb-sm" data-format="email" data-required="true">{translate('zip_code', this.props.query.language, this.props.translations)} *</label>
                                <input type="text" className={this.state.zipCodeError ? 'error' : ''} onChange={this.handleInputChange}  name="zipCode"/>
                            </div>

                            <div className="cart-form-group mb-lg">
                                <label htmlFor="city" className="mb-sm" data-format="email" data-required="true">{translate('city', this.props.query.language, this.props.translations)} *</label>
                                <input type="text" className={this.state.cityError ? 'error' : ''} onChange={this.handleInputChange}  name="city"/>
                            </div>

                        </div>
                    )}

                    <div className="cart-form-group mb-lg">
                        <label htmlFor="email" className="mb-sm" data-format="email" data-required="true">{translate('email-address', this.props.query.language, this.props.translations)} *</label>
                        <input type="text" className={this.state.emailError ? 'error' : ''} onChange={this.handleInputChange}  name="email"/>
                    </div>

                    <div className="cart-form-group mb-lg">
                        <label htmlFor="phone" className="mb-sm" data-format="integer" data-required="true">{translate('phone-number', this.props.query.language, this.props.translations)} *</label>
                        <input type="text" className={this.state.phoneError ? 'error' : ''} onChange={this.handleInputChange}  name="phone"/>
                    </div>

                    <div className="cart-form-group mb-lg">
                        <label htmlFor="comments" className="mb-sm" >{translate('comments', this.props.query.language, this.props.translations)}</label>
                        <textarea cols={50} rows={5} onChange={this.handleInputChange}  name="comments"/>
                    </div>

                    {this.props.property.settings.conditions.terms_and_conditions && (
                        <div className="cart-form-group-check mb-lg">
                            <input id="conditions" name="conditions" type="checkbox" onChange={this.handleInputChange} className={this.state.conditionsError ? 'error' : ''}/>
                            <label htmlFor="conditions">
                                {this.props.property.settings.conditions.terms_and_conditions && (
                                    <ConditionLink href={this.props.property.settings.conditions.terms_and_conditions} target="_blank">{translate('terms_and_conditions', this.props.query.language, this.props.translations)}</ConditionLink>
                                )}
                            </label>
                        </div>
                    )}

                    <Button onClick={this.createBooking} type="primary">{translate('book-now', this.props.query.language, this.props.translations)}</Button>

                </div>
            </ThemeProvider>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        cart: state.cart,
        exchanges: state.exchanges,
        property: state.property,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(CartForm);
