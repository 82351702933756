import React from "react";
import {connect} from "react-redux";
import {Container, Row, Col} from "react-grid-system";
import Popover from "../generic/Popover";
import styled, {ThemeProvider} from "styled-components";
import DateRangePicker from "../generic/DateRangePicker";
import {setDates} from "../../actions/query";
import {resetAvailability, startSetAvailability} from "../../actions/availability";
import format from "../../i18n/format";

const DateDiv = styled.div`
    background-color: ${props => props.theme.colors.white};
    &.active {
        background-color: ${props => props.theme.colors.primary};
    }
`;

const StayDiv = styled.div`
    &:hover, &.active {
        border-color: ${props => props.theme.colors.primary};
    }
`;

class SearchBar extends React.Component {

    state = {
        stayPopoverIsOpen: false,
    };

    theme = {
        colors: this.props.property.settings.colors,
    };

    constructor(props) {
        super(props);
        this.checkinRef = React.createRef();
        this.checkoutRef = React.createRef();
        this.stayRef = React.createRef();
    }

    toggleStayPopover = () => {
        this.setCheckinActive();
        if (this.state.stayPopoverIsOpen) {
            this.setState({stayPopoverIsOpen: false});
            this.stayRef.current.classList.remove('active');
            this.removeActives();
        } else {
            this.setState({stayPopoverIsOpen: true});
            this.stayRef.current.classList.add('active');
        }
    };

    setCheckinActive = () => {
        this.checkinRef.current.classList.add('active');
        this.checkoutRef.current.classList.remove('active');
    }

    setCheckoutActive = () => {
        this.checkinRef.current.classList.remove('active');
        this.checkoutRef.current.classList.add('active');
    }

    removeActives = () => {
        this.checkinRef.current.classList.remove('active');
        this.checkoutRef.current.classList.remove('active');
    }

    onChangeCheckin = (checkinDate, checkoutDate) => {
        this.props.dispatch(setDates(checkinDate, checkoutDate));
        this.setCheckoutActive();
        this.props.dispatch(startSetAvailability(this.props.property.id, checkinDate, checkoutDate, this.props.settings.corporatePassword));
    };

    onChangeCheckout = (checkinDate, checkoutDate) => {
        this.props.dispatch(setDates(checkinDate, checkoutDate));
        this.props.dispatch(resetAvailability());
        setTimeout(() => {
            this.toggleStayPopover();
        }, 100);
        setTimeout(() => {
            this.props.dispatch(startSetAvailability(this.props.property.id, checkinDate, checkoutDate,  this.props.settings.corporatePassword));
        }, 200);
    };

    render = () => {

        return (
            <ThemeProvider theme={this.theme}>
                <div>
                    <Container>
                        <Row className="search-bar">
                            <Col md={12}>
                                <StayDiv id="search-bar-stay" className="stay" ref={this.stayRef} onClick={() => {this.toggleStayPopover()}}>
                                    <i className="far fa-calendar"/>
                                    <DateDiv className="date" ref={this.checkinRef}>
                                        {this.props.query.checkin.format(format(`date_${this.props.dateFormat}`, this.props.query.language))}
                                    </DateDiv>
                                    <i className="far fa-chevron-right"/>
                                    <DateDiv className="date" ref={this.checkoutRef}>
                                        {this.props.query.checkout.format(format(`date_${this.props.dateFormat}`, this.props.query.language))}
                                    </DateDiv>
                                </StayDiv>
                            </Col>
                        </Row>
                    </Container>

                    <Popover
                        parentId="search-bar-stay"
                        isOpen={this.state.stayPopoverIsOpen}
                    >
                        <DateRangePicker
                            checkin={this.props.query.checkin}
                            checkout={this.props.query.checkout}
                            highlightColor={this.props.property.settings.colors.primary}
                            locale={this.props.query.language}
                            minLos={1}
                            numberOfCalendars={this.props.numberOfCalendars}
                            onCheckinChange={this.onChangeCheckin}
                            onCheckoutChange={this.onChangeCheckout}
                            propertyId={this.props.property.id}
                            toggleStayPopover={this.toggleStayPopover}
                        />

                    </Popover>

                </div>
            </ThemeProvider>
        );
    };
}


const mapStateToProps = (state) => {
    return {
        property: state.property,
        settings: state.settings,
        query: state.query,
    };
};

export default connect(mapStateToProps)(SearchBar);
