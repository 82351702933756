import React from "react";
import {connect} from "react-redux";
import Base from "../../Base";
import {Container, Row, Col} from "react-grid-system";
import styled, {ThemeProvider} from "styled-components";
import translate from "../../../i18n/translate";
import axios from "axios";
import BookingStayRoom from "./BookingStayRoom";
import {setCurrency, setLanguage} from "../../../actions/query";
import BookingStayPackage from "./BookingStayPackage";
import Currency from "../../generic/Currency";
import TagManager from "react-gtm-module";
import {getContent} from "../../../utils/content";


const ColoredH3 = styled.h3`
    border-bottom: 0.2rem dashed ${props => props.theme.colors.primary};
`;

const ColoredH4 = styled.h4`
    border-bottom: 0.1rem dashed ${props => props.theme.colors.primary};
`;

const InfoBar = styled.div`
    background-color:  ${props => props.theme.colors.primary};
    color: #FFF;
    font-weight: 700;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 0.3rem;
`;



class BookingPage extends React.Component {

    state = {
        reservation: null,
        payment: null,
        getPayment: false,
        testMode: false,
    }

    theme = {
        colors: this.props.property.settings.colors,
    }

    componentDidMount = () => {
        this.setReservation();
    }

    componentDidUpdate = () => {
        if (this.state.reservation && this.state.getPayment === false && this.state.payment === null) {
            this.setPayment();
        }
    }

    setReservation = () => {
        axios.get(`${process.env.API_URL}/v1/bookings/${this.props.match.params.booking}/`,)
            .then((response) => {
                if (response.status === 200) {

                    this.setState({reservation: response.data});
                    this.props.dispatch(setLanguage(response.data.guest_language));
                    this.props.dispatch(setCurrency(response.data.guest_currency));

                    if (this.props.property.settings.tracking.gtm) {
                        this.sendCheckOut();
                    }

                } else {
                    alert('Something wen\'t wrong');
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }

    setPayment = () => {

        if (this.state.reservation.payment_provider === 'mollie') {

            if (this.state.getPayment === false) {

                // set state
                this.setState({getPayment: true});

                // get payment status
                axios.get(`${process.env.API_URL}/v1/payments/${this.state.reservation.payment}/`,)
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data.success) {
                                this.setState({payment: true});
                            } else {
                                this.setState({payment: false});
                            }
                        } else {
                            alert('Something wen\'t wrong');
                        }
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            }

        } else if(this.state.reservation.payment_provider === 'smartpay') {
            this.setState({testMode: true, payment: true});
        } else {
            this.setState({payment: true});
        }

    }

    calculateSubtotal = () => {

        let subtotal = 0;
        for (const stay of this.state.reservation.stays) {
            if (stay.discounted_price) {
                subtotal += stay.discounted_price;
            } else {
                subtotal += stay.total_price;
            }
            for (const extra of stay.extras) {
                subtotal += parseInt(extra.price * 100);
            }
        }
        return parseFloat(subtotal / 100);
    }

    calculateTotal = () => {
        const subtotal = this.getSubtotal();
        return parseFloat(subtotal + parseFloat(this.state.reservation.fees / 100) + parseFloat(this.state.reservation.taxes / 100));
    }

    getSubtotal = () => {
        return this.calculateSubtotal()
    }

    getTotal = () => {
        return this.calculateTotal();
    }

    getRoomInfo = (roomId) => {
        for (const roomInfo of this.props.property.rooms) {
            if (roomInfo.id === roomId) {
                return roomInfo;
            }
        }
    }

    getPackageInfo = (packageId) => {
        for (const packageInfo of this.props.property.packages) {
            if (packageInfo.id === packageId) {
                return packageInfo;
            }
        }
    }

    getExtraName = (extraId) => {
        for (const extra of this.props.property.extras) {
            if (extra.id === extraId) {
                return getContent(extra.names, 'en');
            }
        }
        return 'Unknown';
    }

    getStayInfo = (stay) => {
        let info = null;
        let type = null;
        if (!stay.package_id) {
            info = this.getRoomInfo(stay.room_id);
            type = 'room';
        } else {
            info = this.getPackageInfo(stay.package_id);
            type = 'package';
        }

        return {
            info: info,
            type: type
        }
    }

    // sendOsias = () => {
    //
    //     // check if already requested
    //     if (this.state.reservation.is_requested === true) {
    //         return;
    //     }
    //
    //     // add products
    //     let products = [];
    //     for (const stay of this.state.reservation.stays) {
    //
    //         // get room or package price
    //         let stay_price = 0;
    //         if (stay.discounted_price) {
    //             stay_price = stay.discounted_price;
    //         } else {
    //             stay_price = stay.total_price;
    //         }
    //
    //         // get info
    //         const stay_info = this.getStayInfo(stay);
    //
    //         // add to products
    //         products.push({
    //             'name': stay_info.info.external_name,
    //             'currency': 'EUR',
    //             'id': stay_info.info.id,
    //             'price': parseFloat(stay_price / 100),
    //             'category': stay_info.type,
    //             'quantity': 1
    //         });
    //
    //         // get extra's
    //         for (const index in stay.extras) {
    //
    //             const extraInfo = stay.extras[index]
    //             const extraName = this.getExtraName(extraInfo.external_id);
    //
    //             // add extra's
    //             products.push({
    //                 'name': extraName,
    //                 'currency': 'EUR',
    //                 'id': extraInfo.external_id,
    //                 'price': extraInfo.price,
    //                 'category': 'extra',
    //                 'quantity': 1
    //             });
    //         }
    //
    //     }
    //
    //     // send to osias
    //     osias.checkout(products);
    //
    // }

    sendCheckOut = () => {

        // check if already requested
        if (this.state.reservation.is_requested === true) {
            return;
        }

        // get products
        let products = [];
        for (const stay of this.state.reservation.stays) {

            // get room or package price
            let stay_price = 0;
            if (stay.discounted_price) {
                stay_price = stay.discounted_price;
            } else {
                stay_price = stay.total_price;
            }

            // get info
            const stay_info = this.getStayInfo(stay);

            // add to products
            products.push({
                'name': stay_info.info.external_name,
                'id': stay_info.info.id,
                'price': parseFloat(stay_price / 100),
                'category': stay_info.type,
                'quantity': 1
            });

            // get extra's
            for (const index in stay.extras) {

                const extraInfo = stay.extras[index]
                const extraName = this.getExtraName(extraInfo.external_id);

                // add extra's
                products.push({
                    'name': extraName,
                    'id': extraInfo.external_id,
                    'price': extraInfo.price,
                    'category': 'extra',
                    'quantity': 1
                });
            }

        }

        // calculate subtotal
        let subtotal_price = 0;
        for(let product of products) {
            subtotal_price += product.price;
        }

        // create dataLayer
        let dataLayer = {
            'event': 'checkout',
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id': this.state.reservation.reservation_id,
                        'affiliation': 'Paxxio OBE',
                        'revenue': subtotal_price,
                        'tax': parseFloat(this.state.reservation.taxes / 100),
                    },
                    'products': products
                }
            }
        }

        // set tag manager
        TagManager.dataLayer({dataLayer: dataLayer});

        // // get products
        // let products = [];
        // for (const price of this.state.totals.prices) {
        //
        //     // set price
        //     let product_price = null
        //     if (price.discounted_price) {
        //         product_price = parseFloat(price.discounted_price / 100);
        //     } else {
        //         product_price = parseFloat(price.price / 100);
        //     }
        //
        //     // get info
        //     const item = this.getItemById(price.id);
        //     const info = this.getCartItemInfo(item);
        //
        //     // add to products
        //     products.push({
        //         'name': info.external_name,
        //         'id': info.id,
        //         'price': product_price,
        //         'category': item.type,
        //         'quantity': 1
        //     });
        //
        // }
        //
        // // create dataLayer
        // let dataLayer = {
        //     'event': 'checkout',
        //     'ecommerce': {
        //         'purchase': {
        //             'actionField': {
        //                 'id': this.state.bookingId,
        //                 'affiliation': 'Paxxio OBE',
        //                 'revenue': parseFloat(this.state.totals.total / 100),
        //                 'tax': parseFloat(this.state.totals.city_tax / 100),
        //                 'coupon': promoCode
        //             },
        //             'products': products
        //         }
        //     }
        // }
        //
        // // set tag manager
        // TagManager.dataLayer({dataLayer: dataLayer});

    }

    render = () => {

        if (this.state.reservation && this.state.payment !== null) {
            return (
                <div id="booking-page">

                    <ThemeProvider theme={this.theme}>

                        <Container>

                            <Row className="pl-lg pr-lg mt-lg">
                                <Col>

                                    {this.state.testMode && (
                                        <InfoBar>{translate('payment-being-processed', this.props.query.language, this.props.translations)}</InfoBar>
                                    )}

                                    {this.state.payment ? (

                                        <div className="booking p-lg">

                                            <ColoredH3 className="pb-md">{this.props.property.name}</ColoredH3>
                                            <div className="booking-property-address mt-md">
                                                {this.props.property.address.street} {this.props.property.address.number}<br/>
                                                {this.props.property.address.zip}, {this.props.property.address.city}, {this.props.property.address.country}<br/>
                                                {translate('phone-number', this.props.query.language, this.props.translations)}: {this.props.property.address.phone}<br/>
                                                {this.props.property.address.email && (
                                                    <span>{translate('email-address', this.props.query.language, this.props.translations)}: {this.props.property.address.email}</span>
                                                )}

                                            </div>

                                            <ColoredH4
                                                className="pb-md mt-xl">{translate('your-reservation', this.props.query.language, this.props.translations)}</ColoredH4>
                                            <div className="booking-reservation mt-md">
                                                {translate('reservation-id', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.reservation_id}</strong><br/>
                                                {translate('checkin', this.props.query.language, this.props.translations)}: <strong>{this.props.property.settings.checkin_time}</strong><br/>
                                                {translate('checkout', this.props.query.language, this.props.translations)}: <strong>{this.props.property.settings.checkout_time}</strong><br/>
                                            </div>

                                            <ColoredH4
                                                className="pb-md mt-xl">{translate('your-info', this.props.query.language, this.props.translations)}</ColoredH4>
                                            <div className="booking-info mt-md">
                                                {this.state.reservation.company_name && (
                                                    <span>{translate('company', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.company_name}</strong><br/></span>
                                                )}
                                                {this.state.reservation.sex && (
                                                    <span>{translate('sex', this.props.query.language, this.props.translations)}: <strong>{translate(this.state.reservation.sex, this.props.query.language, this.props.translations)}</strong><br/></span>
                                                )}
                                                {translate('first-name', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.first_name}</strong><br/>
                                                {translate('surname', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.last_name}</strong><br/>
                                                {this.state.reservation.street_name && (
                                                    <span>
                                                        {translate('street_name', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.street_name}</strong><br/>
                                                        {translate('house_number', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.house_number}</strong><br/>
                                                        {translate('zip_code', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.zip_code}</strong><br/>
                                                        {translate('city', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.city}</strong><br/>
                                                    </span>
                                                )}
                                                {translate('email-address', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.email}</strong><br/>
                                                {translate('phone-number', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.phone_number}</strong><br/>
                                                {translate('comments', this.props.query.language, this.props.translations)}: <strong>{this.state.reservation.comments}</strong><br/>
                                            </div>

                                            <ColoredH4
                                                className="pb-md mt-xl">{translate('your-stay', this.props.query.language, this.props.translations)}</ColoredH4>

                                            <div className="booking-stays mt-md">
                                                {this.state.reservation.stays.map((stay, stayKey) => {
                                                    if (stay.package_id) {
                                                        return <BookingStayPackage
                                                            key={stayKey}
                                                            translations={this.props.translations}
                                                            query={this.props.query}
                                                            property={this.props.property}
                                                            stay={stay}
                                                        />
                                                    } else {
                                                        return <BookingStayRoom
                                                            key={stayKey}
                                                            translations={this.props.translations}
                                                            query={this.props.query}
                                                            property={this.props.property}
                                                            stay={stay}
                                                        />
                                                    }
                                                })}
                                            </div>

                                            <div className="booking-totals pt-md">

                                                <div className="booking-totals-item">
                                                    <span>{translate('subtotal', this.props.query.language, this.props.translations)}</span>
                                                    <Currency>{this.getSubtotal()}</Currency>
                                                </div>

                                                {this.state.reservation.taxes > 0 && (
                                                    <div className="booking-totals-item mt-md">
                                                        <span>{translate('citytax', this.props.query.language, this.props.translations)}</span>
                                                        <Currency>{parseFloat(this.state.reservation.taxes / 100)}</Currency>
                                                    </div>
                                                )}

                                                {this.state.reservation.fees > 0 && (
                                                <div className="booking-totals-item mt-md">
                                                    <span>{translate('otherfees', this.props.query.language, this.props.translations)}</span>
                                                    {!this.state.recalculate ? (
                                                        <Currency>{parseFloat(this.state.reservation.fees / 100)}</Currency>
                                                    ) : (
                                                        <span>...</span>
                                                    )}
                                                </div>
                                            )}

                                                <div className="booking-totals-item mt-md">
                                                    <span>{translate('total', this.props.query.language, this.props.translations)}</span>
                                                    <Currency>{this.getTotal()}</Currency>
                                                </div>

                                            </div>

                                        </div>
                                    ) : (
                                        <div className="booking p-lg">

                                            <ColoredH3 className="pb-md">{this.props.property.name}</ColoredH3>
                                            <div
                                                className="mt-lg">{translate('payment-failed', this.props.query.language, this.props.translations)}</div>

                                        </div>
                                    )}


                                </Col>
                            </Row>

                        </Container>

                    </ThemeProvider>

                </div>
            )
        } else {
            return <span/>
        }

    }
}

const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        cart: state.cart,
        exchanges: state.exchanges,
        extras: state.extras,
        promo: state.promo,
        property: state.property,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(Base(BookingPage));
