import React from "react";
import {connect} from "react-redux";
import translate from "../../../../i18n/translate";


class CartPaymentSkeleton extends React.Component {

    render = () => {

        return (
            <div className="cart-payment p-lg mb-lg">
                <h2 className="title">{translate('payment', this.props.query.language, this.props.translations)}</h2>
                <span>...</span>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        translations: state.translations,
        query: state.query,
    };
};

export default connect(mapStateToProps)(CartPaymentSkeleton);
