import React from "react";
import translate from "../../../i18n/translate";
import {getContent} from "../../../utils/content";
import moment from "moment";
import format from "../../../i18n/format";
import Currency from "../../generic/Currency";


class BookingStayRoom extends React.Component {

    state = {
        rateInfo: null,
        roomInfo: null
    }

    componentDidMount = () => {
        this.setState({
            rateInfo: this.getRateInfo(),
            roomInfo: this.getRoomInfo(),
        })
    }

    getRoomInfo = () => {
        for (const roomInfo of this.props.property.rooms) {
            if (roomInfo.id === this.props.stay.room_id) {
                return roomInfo;
            }
        }
    }

    getRateInfo = () => {
        for (const rateInfo of this.props.property.rates) {
            if (rateInfo.id === this.props.stay.rate_id) {
                return rateInfo;
            }
        }
    }

    getExtraName = (extraId) => {

        for (const extra of this.props.property.extras) {
            if (extra.id === extraId) {
                return getContent(extra.names, this.props.query.language);
            }
        }

        return 'Unknown';

    }

    getDatesString = () => {
        const checkin = moment(this.props.stay.checkin).locale(this.props.query.language);
        const checkout = moment(this.props.stay.checkout).locale(this.props.query.language);
        return `${checkin.format(format('date', this.props.query.language))} ${translate('t-m', this.props.query.language, this.props.translations)} ${checkout.format(format('date', this.props.query.language))}`;
    }

    getOccupancyString = () => {

        let occupancyString = '';

        // adults
        if (this.props.stay.adults === 1) {
            occupancyString += `${this.props.stay.adults} ${translate('adult', this.props.query.language, this.props.translations)}`;
        } else if(this.props.stay.adults > 1) {
            occupancyString += `${this.props.stay.adults} ${translate('adults', this.props.query.language, this.props.translations)}`;
        }

        // children
        if (this.props.stay.children === 1) {
            occupancyString += `, ${this.props.stay.children} ${translate('child', this.props.query.language, this.props.translations)}`;
        } else if(this.props.stay.children > 1) {
            occupancyString += `, ${this.props.stay.children} ${translate('children', this.props.query.language, this.props.translations)}`;
        }

        // infants
        if (this.props.stay.infants === 1) {
            occupancyString += `, ${this.props.stay.infants} ${translate('infant', this.props.query.language, this.props.translations)}`;
        } else if(this.props.stay.infants > 1) {
            occupancyString += `, ${this.props.stay.infants} ${translate('infants', this.props.query.language, this.props.translations)}`;
        }

        return occupancyString;

    }

    render = () => {

        if (this.state.rateInfo && this.state.roomInfo) {
            return (
                <div>

                    <div className="booking-stay mt-lg pb-lg">

                        <div className="booking-stay-info">
                            <h5>{getContent(this.state.roomInfo.names, this.props.query.language)}</h5>
                            <div className="cart-item-info-rate-name mt-sm">
                                <i className="fas fa-tags"/> {getContent(this.state.rateInfo.names, this.props.query.language)}
                            </div>
                            <div className="cart-item-info-occupancy mt-xs">
                                <i className="fas fa-user"/> {this.getOccupancyString()}
                            </div>
                            <div className="cart-item-info-dates mt-xs">
                                <i className="fas fa-calendar"/> {this.getDatesString()}
                            </div>
                        </div>

                        <div className="booking-stay-price">
                            {this.props.stay.discounted_price ? (
                                <span className="discounted-rate">
                                    <Currency>{parseFloat(this.props.stay.total_price / 100)}</Currency> <Currency>{parseFloat(this.props.stay.discounted_price / 100)}</Currency>
                                </span>
                            ) : (
                                <Currency>{parseFloat(this.props.stay.total_price / 100)}</Currency>
                            )}
                        </div>

                    </div>

                    {this.props.stay.extras.map((extra, extraKey) => {
                        return (
                            <div key={extraKey} className="booking-extra">
                                <div><i className="fas fa-plus"/>&nbsp;{this.getExtraName(extra.external_id)}</div>
                                <div><Currency>{extra.price}</Currency></div>
                            </div>
                        )
                    })}

                </div>
            )
        } else {
            return <span/>
        }


    }
}


export default BookingStayRoom;
