import React from "react";
import {Container, Col, Row} from "react-grid-system";

export default () => (
    <Container>
        <Row className="pl-lg pr-lg mt-xl text-center">
            <Col>
                <h1>404</h1>
                <h2>Property not found</h2>
            </Col>
        </Row>
    </Container>
);
