import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import NotFoundPage from "../components/pages/NotFoundPage";
import ListPage from "../components/pages/list-page/ListPage";
import ItemPage from "../components/pages/item-page/ItemPage";
import CartPage from "../components/pages/cart-page/CartPage";
import BookingPage from "../components/pages/booking-page/BookingPage";
import ScrollToTop from "../components/generic/ScrollToTop";
import CorporatePage from "../components/pages/corporate-page/CorporatePage";
import RedirectPage from "../components/pages/corporate-page/RedirectPage";


const AppRouter = () => (
    <BrowserRouter>
        <ScrollToTop>
            <div id="layout">
                <Switch location={location}>
                    <Route path="/404/" component={NotFoundPage} exact={true}/>
                    <Route path="/c/:code/" component={RedirectPage} exact={true}/>
                    <Route path="/:property/booking/:booking/" component={BookingPage} exact={true}/>
                    <Route path="/:property/corporate/" component={CorporatePage} exact={true}/>
                    <Route path="/:property/:type/:item/rates/" component={ItemPage} exact={true}/>
                    <Route path="/:property/:type/:item/" component={ItemPage} exact={true}/>
                    <Route path="/:property/cart/" component={CartPage} exact={true}/>
                    <Route path="/:property/rooms/" component={ListPage} exact={true}/>
                    <Route path="/:property/search/" component={ListPage} exact={true}/>
                    <Route path="/:property/" component={ListPage} exact={true}/>
                    <Route component={NotFoundPage}/>
                </Switch>
            </div>
        </ScrollToTop>
    </BrowserRouter>
);

export default AppRouter;
