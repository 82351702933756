import React from "react";
import {connect} from "react-redux";
import styled, {ThemeProvider} from "styled-components";
import {resetFilters, setFilters} from "../../../actions/settings";
import translate from "../../../i18n/translate";
import Button from "../../generic/Button";
import {Visible} from "react-grid-system";
import {getContent} from "../../../utils/content";


const FilterDiv = styled.div`

    .fa-check-square,.fa-check-circle {
        color: ${props => props.theme.colors.primary};
    }

    &.active, &:hover {
        .fa-square, .fa-circle {
            color: ${props => props.theme.colors.primary};        
        }
    }
`;

class FilterListDefault extends React.Component {

    state = {
        occupancyArray: [],
    }

    theme = {
        colors: this.props.property.settings.colors,
    };

    componentDidMount = () => {
        this.setOccupancyArray();
    }

    inputOccupancy = (event) => {

        // remove all current occupancy filters
        let otherFilters = [];
        if (this.props.settings.filters) {
            for(const filter of this.props.settings.filters) {
                if (filter.indexOf('occupancy')) {
                    otherFilters.push(filter);
                }
            }
        }


        // set requested occupancy
        let minOccupancy = parseInt(event.target.value);
        if (!!minOccupancy === false || minOccupancy < 0) {
            return;
        }

        // set calculations
        let maxOccupancy = minOccupancy + 5;
        let countOccupancy = minOccupancy;

        // set occupancy filters
        let occupancyFilters = []
        while(countOccupancy <= maxOccupancy) {
            if (!(this.state.occupancyArray.indexOf(countOccupancy) < 0)) {
                occupancyFilters.push(`occupancy-${countOccupancy}`);
            }
            countOccupancy++;
        }

        // combine filters
        let combinedFilters = [];
        if (otherFilters) {
            combinedFilters = occupancyFilters.concat(otherFilters);
        } else {
            combinedFilters = occupancyFilters;
        }

        this.props.dispatch(setFilters(combinedFilters));

    }

    setOccupancyArray = () => {

        // get smallest an biggest occupancy
        let smallestOccupancy = null;
        let biggestOccupancy = null;
        for (const room of this.props.property.rooms) {

            // smallest
            if (smallestOccupancy === null || smallestOccupancy > room.min_occupancy) {
                smallestOccupancy = room.min_occupancy;
            }

            // biggest
            if (biggestOccupancy === null || biggestOccupancy < room.max_occupancy) {
                biggestOccupancy = room.max_occupancy;
            }

        }

        // create array
        const occupancyArray = [];
        for (let i = smallestOccupancy; i <= biggestOccupancy; i++) {
            occupancyArray.push(i);
        }

        // set state
        this.setState({occupancyArray: occupancyArray});

    }

    toggleDynamicFilter = (filterId) => {

        // get current and set new filters
        let newFilters = null;
        const currentFilters = this.props.settings.filters;
        if (currentFilters) {

            // set new filters
            newFilters = [...currentFilters]

            // remove if necessary
            const filterIndex = newFilters.indexOf(filterId);
            if (filterIndex > -1) {
                newFilters.splice(filterIndex, 1);
            } else {
                newFilters.push(filterId);
            }

        } else {
            newFilters = [filterId];
        }

        // check if newFilters array
        if (newFilters && newFilters.length > 0) {
            this.props.dispatch(setFilters(newFilters));
        } else {
            this.props.dispatch(resetFilters());
        }

    };

    render = () => {
        return (
            <div className="filter-list">

                <ThemeProvider theme={this.theme}>

                    {this.props.property.packages.length > 0 && (

                        <div className="filter-group">

                            <h3>{translate('type', this.props.query.language, this.props.translations)}</h3>

                            <FilterDiv className="filter" onClick={() => {this.toggleDynamicFilter('type-rooms')}}>

                                {this.props.settings.filters && this.props.settings.filters.includes('type-rooms') ? (
                                    <i className="fas fa-check-square"/>
                                ) : (
                                    <i className="far fa-square"/>
                                )}

                                <span>{translate('rooms', this.props.query.language, this.props.translations)}</span>

                            </FilterDiv>

                            <FilterDiv className="filter" onClick={() => {this.toggleDynamicFilter('type-packages')}}>

                                {this.props.settings.filters && this.props.settings.filters.includes('type-packages') ? (
                                    <i className="fas fa-check-square"/>
                                ) : (
                                    <i className="far fa-square"/>
                                )}

                                <span>{translate('package-deals', this.props.query.language, this.props.translations)}</span>

                            </FilterDiv>

                        </div>

                    )}

                    {/*<div className="filter-group">*/}
                    {/*    <h3>{translate('occupancy', this.props.query.language, this.props.translations)}</h3>*/}
                    {/*    <input type="number" onKeyUp={this.inputOccupancy} onMouseUp={this.inputOccupancy}/>*/}
                    {/*</div>*/}

                    {this.state.occupancyArray && (
                        <div className="filter-group">
                            <h3>{translate('occupancy', this.props.query.language, this.props.translations)}</h3>
                            {this.state.occupancyArray.map((occupancy, key) => {
                                return (
                                    <FilterDiv key={key} className="filter" onClick={() => {this.toggleDynamicFilter(`occupancy-${occupancy}`)}}>

                                        {this.props.settings.filters && this.props.settings.filters.includes(`occupancy-${occupancy}`) ? (
                                            <i className="fas fa-check-square"/>
                                        ) : (
                                            <i className="far fa-square"/>
                                        )}

                                        {occupancy === 1 ? (
                                            <span>{occupancy} {translate('person', this.props.query.language, this.props.translations)}</span>
                                        ) : (
                                            <span>{occupancy} {translate('persons', this.props.query.language, this.props.translations)}</span>
                                        )}

                                    </FilterDiv>
                                )
                            })}
                        </div>
                    )}

                    {/* load filter groups */}
                    {this.props.property.filter_groups && (
                        <div>
                            {Object.keys(this.props.property.filter_groups).map((filterGroupKey) => {
                                const filterGroup = this.props.property.filter_groups[filterGroupKey]
                                return (
                                    <div key={filterGroup.id} className="filter-group">
                                        <h3>{getContent(filterGroup.names, this.props.query.language)}</h3>
                                        {Object.keys(filterGroup.filters).map((filterKey) => {
                                            const filter = filterGroup.filters[filterKey];
                                            return (
                                                <FilterDiv key={filter.id} className="filter" onClick={() => {this.toggleDynamicFilter(`dynamic-${filter.id}`)}}>
                                                    {this.props.settings.filters && this.props.settings.filters.includes(`dynamic-${filter.id}`) ? (
                                                        <i className="fas fa-check-square"/>
                                                    ) : (
                                                        <i className="far fa-square"/>
                                                    )}
                                                    <span>{getContent(filter.names, this.props.query.language)}</span>
                                                </FilterDiv>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    <div className="filter-reset">

                        <span className={this.props.settings.filters ? `button block mb-md` : `button block mb-md disabled`} onClick={() => {this.props.dispatch(resetFilters())}}>
                            {translate('reset-filters', this.props.query.language, this.props.translations)}
                        </span>

                        <Visible xs sm>
                            <Button type="primary" onClick={() => {this.props.closeFilterModal()}} block={true}>{translate('apply', this.props.query.language, this.props.translations)}</Button>
                        </Visible>

                    </div>

                </ThemeProvider>

            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(FilterListDefault);
