import axios from "axios";
import {setExchanges} from "./exchanges";

export const showPromoCodeModal = () => ({
    type: 'SHOW_PROMO_CODE_MODAL',
});

export const hidePromoCodeModal = () => ({
    type: 'HIDE_PROMO_CODE_MODAL',
});

export const setDiscount = (discount) => ({
    type: 'SET_DISCOUNT',
    discount: discount,
});

export const setNotFound = () => ({
    type: 'SET_NOT_FOUND',
});

export const unsetNotFound = () => ({
    type: 'UNSET_NOT_FOUND',
});

export const startSetDiscount = (promoCode, property) => {

    return (dispatch) => {

        // do request
        const resourceUrl = `${process.env.API_URL}/v1/promos/${promoCode}/?property=${property}`;
        axios.get(resourceUrl)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch(setDiscount(response.data));
                } else {
                    alert('Something wen\'t wrong');
                }
            })
            .catch(function (error) {
                dispatch(setNotFound());
            });
    }
};
