const defaultState = () => {
    return null
};

export default (state = defaultState(), action) => {

    switch (action.type) {

        case 'SET_TRANSLATIONS':
            return {
                ...action.translations
            };


        default:
            return state;
    }
};
