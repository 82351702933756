import React from "react";
import connect from "react-redux/es/connect/connect";
import translate from "../../i18n/translate";
import {motion} from "framer-motion";
import Button from "./Button";
import {hidePromoCodeModal, showPromoCodeModal, startSetDiscount, unsetNotFound} from "../../actions/promo";

const variants = {
    visible: {opacity: 1, scale: 1},
    hidden: {opacity: 0, scale: 0}
};

class PromoModal extends React.Component {

    state = {
        promoCode: ''
    }

    openModal = () => {
        this.props.dispatch(showPromoCodeModal());
    }

    closeModal = () => {
        this.props.dispatch(hidePromoCodeModal());
    }

    componentDidMount = () => {
        setTimeout(() => {
            if (this.props.promo.showPromoModal === null) {
                this.props.dispatch(showPromoCodeModal());
            }
        }, 3000);
    }

    updatePromoCode = (event) => {
        this.setState({promoCode: event.target.value});
        if (this.props.promo.notFound) {
            this.props.dispatch(unsetNotFound());
        }
    }

    setDiscount = () => {
        this.props.dispatch(startSetDiscount(this.state.promoCode, this.props.property.id));
    }

    render = () => {

        if (this.props.property.settings.has_promo &&  !this.props.settings.corporatePassword) {

            return (
                <div>

                    <motion.div animate={this.props.promo.showPromoModal ? "visible" : "hidden"} variants={variants} className="promo-modal p-lg m-lg">

                        <div className="promo-modal-header">
                            <h3>{translate('use-a-promo-code', this.props.query.language, this.props.translations)}</h3>
                            <i onClick={this.closeModal} className="far fa-times ml-xl"/>
                        </div>

                        {this.props.promo.discount ? (
                            <div className="promo-modal-body mt-lg">
                                <div className="promo-modal-found">
                                    {translate('promo-added', this.props.query.language, this.props.translations)}
                                </div>
                            </div>
                        ) : (
                             <div className="promo-modal-body mt-lg">
                                {this.props.promo.notFound && (
                                    <div className="promo-modal-not-found mb-lg">
                                        <i className="fas fa-exclamation-triangle mr-sm"/>
                                        <span>{translate('invalid-code', this.props.query.language, this.props.translations)}</span>
                                    </div>
                                )}
                                <input className="mb-md" type="text" name="promo" id="promo" onKeyUp={this.updatePromoCode}/>
                                <Button type="primary" onClick={this.setDiscount}>{translate('apply', this.props.query.language, this.props.translations)}</Button>
                            </div>
                        )}
                    </motion.div>

                    <motion.div animate={this.props.promo.showPromoModal ? "hidden" : "visible"} onClick={this.openModal} variants={variants} className="promo-modal-button p-lg mb-lg">
                        <i className="fad fa-tags"/>
                    </motion.div>

                </div>
            )

        } else {
            return <span/>
        }


    }
}

const mapStateToProps = (state) => {
    return {
        promo: state.promo,
        property: state.property,
        settings: state.settings,
        translations: state.translations,
        query: state.query,
    };
};

export default connect(mapStateToProps)(PromoModal);
