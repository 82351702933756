import React from "react";
import {connect} from "react-redux";
import styled, {ThemeProvider} from "styled-components";

const PaymentMethod = styled.div`
    cursor: pointer;
    &.active {
        border: 0.2rem solid ${props => props.theme.colors.primary} !important;
    }
`;

class CartPaymentMollie extends React.Component {

    state = {
        selectedMethod: null,
    }

    theme = {
        colors: this.props.property.settings.colors,
    };

    getFriendlyProviderName = (name) => {
        const names = {
            'applepay': 'Apple Pay',
            'bancontact': 'Bancontact',
            'creditcard': 'Creditcard',
            'ideal': 'iDeal',
            'paypal': 'PayPal',
        }
        return names[name];
    }

    setPaymentMethod = (method) => {
        this.setState({selectedMethod: method});
        this.props.setPayment({
            provider: 'mollie',
            method: method
        })
    }

    render = () => {

        return (
            <ThemeProvider theme={this.theme}>
                <div className="cart-payment-mollie">
                    {this.props.property.payment.methods.map((method, index) => {
                        return (
                            <PaymentMethod key={index} className={method === this.state.selectedMethod ? `cart-payment-mollie-method active mb-md` : `cart-payment-mollie-method mb-md`} onClick={() => {this.setPaymentMethod(method)}}>
                                <img src={`https://cdn.paxxio.net/obe/payment/${method}_mod.png`} alt={method}/>
                                <span className="ml-sm">{this.getFriendlyProviderName(method)}</span>
                            </PaymentMethod>
                        )
                    })}
                </div>
            </ThemeProvider>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        cart: state.cart,
        exchanges: state.exchanges,
        property: state.property,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(CartPaymentMollie);
