import React from "react";
import {motion} from "framer-motion"

const variants = {
    open: {opacity: 1, y: 0},
    closed: {opacity: 0, y: 50}
};

export default class Popover extends React.Component {

    state = {
        isOpen: false
    };

    constructor(props) {
        super(props);
        this.popoverRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({isOpen: this.props.isOpen});
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
            this.popoverRef.current.style.display = 'block';
            this.popoverRef.current.style.visibility = 'visible';
            this.setPlacement();
        } else if (!this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
            setTimeout(() => {
                this.popoverRef.current.style.display = 'none';
                this.popoverRef.current.style.visibility = 'hidden';
            }, 200);
        }
    }

    setPlacement = () => {

        // get dimensions
        const parentElement = document.getElementById(this.props.parentId);
        const parentRect = parentElement.getBoundingClientRect();
        const currentElement = this.popoverRef.current;
        const currentRect = currentElement.getBoundingClientRect();

        // place popoverElement
        this.popoverRef.current.style.top = parentRect.height + parentRect.top + 'px';
        this.popoverRef.current.style.left = parentRect.left + (parentRect.width / 2) - (currentRect.width / 2) + 'px';

    };

    render = () => {

        return (
            <motion.div
                ref={this.popoverRef}
                id={`${this.props.parentId}-popover`}
                animate={this.props.isOpen ? "open" : "closed"}
                variants={variants}
                transition={{duration: 0.2}}
                className={`popover`}
            >
                <div className="popover-body">
                    {this.props.children}
                </div>

            </motion.div>
        );

    };
}
