export const setDates = (checkin, checkout) => ({
    type: 'SET_DATES',
    checkin: checkin,
    checkout: checkout,
});

export const setAdults = (adults) => ({
    type: 'SET_ADULTS',
    adults: adults,
});

export const setChildren = (children) => ({
    type: 'SET_CHILDREN',
    children: children,
});

export const setInfants = (infants) => ({
    type: 'SET_INFANTS',
    infants: infants,
});

export const setLanguage = (language) => ({
    type: 'SET_LANGUAGE',
    language: language,
});

export const setCurrency = (currency) => ({
    type: 'SET_CURRENCY',
    currency: currency,
});
