import React from "react";
import {connect} from "react-redux";
import {Col} from "react-grid-system";
import {motion} from "framer-motion"
import {calculateDiscount, getTotalPriceByRateAndGuest} from "../../../utils/rates";
import styled, {ThemeProvider} from "styled-components";
import Button from "../../generic/Button";
import Currency from "../../generic/Currency";
import {Link} from "react-router-dom";
import {getContent} from "../../../utils/content";
import translate from "../../../i18n/translate";
import {getFees} from "../../../i18n/fees";
import TagManager from "react-gtm-module";

const StartingAtDiv = styled.div`
    color: ${props => props.theme.colors.primary};
`;

class RoomListItem extends React.Component {

    state = {
        roomInfo: null,
        nights: null,
    }

    theme = {
        colors: this.props.property.settings.colors,
    };

    componentDidMount = () => {

        this.setState({
            roomInfo: this.getRoomInfo(),
            nights: this.props.query.checkout.diff(this.props.query.checkin, 'days')
        });


    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevState.roomInfo !== this.state.roomInfo && this.state.roomInfo !== null) {
            this.getLowestRate();
        }
    }

    getRoomInfo = () => {
        let roomInfo = null;
        for (const roomKey in this.props.property.rooms) {
            if (this.props.property.rooms.hasOwnProperty(roomKey)) {
                if (this.props.property.rooms[roomKey].id === this.props.room.id) {
                    roomInfo = this.props.property.rooms[roomKey];
                }
            }
        }
        return roomInfo;
    }

    getLowestRate = () => {

        // todo: select by persons filter if selected

        // get rates by lowest adult guest
        const rates = this.props.room.rates;

        let roomRates = [];
        let lowestRate = null;
        for (const rateKey in rates) {
            if (rates.hasOwnProperty(rateKey)) {
                const rate = rates[rateKey];
                if (rate.available) {
                    const currentRate = getTotalPriceByRateAndGuest(rate, 'adult', this.props.room.min_occupancy) / 100;
                    if (lowestRate === null || currentRate < lowestRate) {
                        lowestRate = currentRate;
                    }
                }
            }
        }

        // add fees
        let fees = [];
        for (const fee of this.state.roomInfo.fees) {
            fees.push(fee);
        }

        // check if there are any additional fees
        let feeTotals = 0;
        if (fees.length > 0) {

            // get number of nights
            const numberOfNights = this.props.query.checkout.diff(this.props.query.checkin, 'days');

            // get fees
            feeTotals = getFees(
                fees,
                lowestRate,
                numberOfNights,
                this.props.room.min_occupancy,
                this.props.property.settings.default_currency,
                this.props.exchanges,
            );

        }

        // add fees to lowestRate
        lowestRate = lowestRate + feeTotals;

        // get discount
        let discount = null;
        if (this.props.promo.discount) {
            discount = calculateDiscount(this.props.promo.discount, lowestRate, this.props.room.min_occupancy, this.state.nights,)
        }

        // price per night
        if (this.props.property.settings.interface.show_price_per_night) {
            if (discount) {
                return (
                    <span className="discounted-rate">
                        <Currency>{parseFloat(discount.originalRate / this.state.nights)}</Currency> <Currency>{parseFloat(discount.discountedRate / this.state.nights)}</Currency>
                    </span>
                )
            } else {
                return <Currency>{parseFloat(lowestRate / this.state.nights)}</Currency>;
            }
        } else {
            if (discount) {
                return (
                    <span className="discounted-rate">
                        <Currency>{discount.originalRate}</Currency> <Currency>{discount.discountedRate}</Currency>
                    </span>
                )
            } else {
                return <Currency>{lowestRate}</Currency>;
            }
        }
    }

    hasSelectedFilters = () => {

        // check setting filters
        if (!this.props.settings.filters) {
            return true;
        }

        // get room filters
        let roomFilters = [];
        if (this.state.roomInfo.filters) {
            for (const roomFilterKey in this.state.roomInfo.filters) {
                if (this.state.roomInfo.filters.hasOwnProperty(roomFilterKey)) {
                    roomFilters.push(`dynamic-${this.state.roomInfo.filters[roomFilterKey].id}`);
                }
            }
        }

        // add room type
        roomFilters.push('type-rooms');

        // add occupancy to available filters
        for (let i = this.state.roomInfo.min_occupancy; i <= this.state.roomInfo.max_occupancy; i++) {
            roomFilters.push(`occupancy-${i}`);
        }

        // check filters
        for (const settingsFilterKey in this.props.settings.filters) {
            if (this.props.settings.filters.hasOwnProperty(settingsFilterKey)) {
                const settingsFilter = this.props.settings.filters[settingsFilterKey];
                if (!roomFilters.includes(settingsFilter)) {
                    return false;
                }
            }
        }

        // everything is fine
        return true;

    }

    getOccupancy = () => {
        if (this.state.roomInfo.min_occupancy === this.state.roomInfo.max_occupancy) {
            return this.state.roomInfo.min_occupancy;
        } else {
            return this.state.roomInfo.min_occupancy + '-' + this.state.roomInfo.max_occupancy;
        }
    }

    render = () => {

        if (this.state.roomInfo && this.hasSelectedFilters()) {

            return (

                <ThemeProvider theme={this.theme}>

                    <Col xl={4} lg={6} md={12}>
                        <motion.div
                            animate={{opacity: 1}}
                            whileHover={{y: -3}}
                            className={!this.props.room.available ? `list-item unavailable` : `list-item`}
                        >
                            <div className="image border-radius">
                                {this.state.roomInfo.images.length > 0 ? (
                                    <img src={this.state.roomInfo.images[0].file} alt={this.state.roomInfo.images[0].description}/>
                                ) : (
                                    <img src="https://via.placeholder.com/900x500?text=No+image" alt="placeholder"/>
                                )}
                            </div>

                            <div className="item-title" onClick={this.pageview}>
                                <h3>{getContent(this.state.roomInfo.names, this.props.query.language, this.state.roomInfo.external_name)}</h3>
                            </div>

                            <div className="item-tags">
                                <div className="tag">
                                    <i className="fas fa-user"/>
                                    <span>{this.getOccupancy()}</span>
                                </div>

                                {!this.props.room.available && (
                                    <div className="tag error ml-xs">
                                        <i className="fas fa-frown"/>
                                        <span>{translate('unavailable', this.props.query.language, this.props.translations)}</span>
                                    </div>
                                )}

                            </div>

                            <div className="item-cta">

                                {this.props.room.available ? (
                                    <div className="item-rate">
                                        <StartingAtDiv className="item-starting-at">{translate('starting-at', this.props.query.language, this.props.translations)}</StartingAtDiv>
                                        {this.props.property.settings.interface.show_price_per_night ? (
                                            <div className="item-price">{this.getLowestRate()}
                                                <div> / {translate('per-night', this.props.query.language, this.props.translations)}</div>
                                            </div>
                                        ) : (
                                            <div className="item-price">{this.getLowestRate()}</div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="item-rate">
                                        <StartingAtDiv className="item-starting-at">{translate('starting-at', this.props.query.language, this.props.translations)}</StartingAtDiv>
                                        <div className="item-price">0</div>
                                    </div>
                                )}

                                <div className="item-button">
                                    <Link to={`/${this.props.property.id}/rooms/${this.props.room.id}/`}>
                                        {this.props.room.available ? (
                                            <Button
                                                type={'primary'}>{translate('show-rates', this.props.query.language, this.props.translations)}</Button>
                                        ) : (
                                            <Button
                                                type={'primary'}>{translate('show', this.props.query.language, this.props.translations)}</Button>
                                        )}
                                    </Link>
                                </div>
                            </div>

                        </motion.div>
                    </Col>

                </ThemeProvider>
            )
        } else {
            return <span/>
        }
    };

}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        exchanges: state.exchanges,
        promo: state.promo,
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(RoomListItem);
