import React from "react";
import {connect} from "react-redux";
import Base from "../../Base";
import Header from "../../partials/Header";
import {Container, Row, Col} from "react-grid-system";
import {startSetAvailability} from "../../../actions/availability";
import RoomListItem from "./RoomListItem";
import PackageListItem from "./PackageListItem";
import SkeletonListItem from "./SkeletonListItem";
import Menu from "../../partials/Menu";
import Overlay from "../../partials/Overlay";
import Cart from "../../partials/Cart";
import PromoModal from "../../generic/PromoModal";
import FilterListDefault from "./FilterListDefault";
import { Visible, Hidden } from 'react-grid-system';
import Button from "../../generic/Button";
import translate from "../../../i18n/translate";
import Modal from "../../generic/Modal";
import {setCorporateMessageShow} from "../../../actions/settings";


class ListPage extends React.Component {

    state = {
        filterModalOpen: false,
        selectedFilterCount: null,
    }

    componentDidMount = () => {

        if (!this.props.availability) {
            this.props.dispatch(startSetAvailability(this.props.property.id, this.props.query.checkin, this.props.query.checkout, this.props.settings.corporatePassword));
        }

    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.settings.filters !== this.props.settings.filters) {
            this.setSelectedFilterCount();
        }
    }

    setSelectedFilterCount = () => {
        if (this.props.settings.filters) {
            this.setState({selectedFilterCount: this.props.settings.filters.length});
        } else {
            this.setState({selectedFilterCount: null});
        }
    }

    closeFilterModal = () => {
        this.setState({filterModalOpen: false});
    }

    render = () => {

        return (

            <div id="list-page">

                <Overlay/>

                <Menu/>

                <Cart/>

                <Header/>

                <PromoModal/>

                <Container>

                    <Row className="pl-lg pr-lg mt-xl">

                        <Hidden xs sm>
                            <Col xl={2} lg={3} md={4}>
                                <FilterListDefault />
                            </Col>
                        </Hidden>

                        <Visible xs sm>

                            <Col xs={12} sm={12} className="mb-lg text-right">
                                <Button onClick={() => {this.setState({filterModalOpen: true})}}>

                                    <i className="fas fa-filter"/>{translate('filters', this.props.query.language, this.props.translations)}&nbsp;

                                    {this.state.selectedFilterCount && (
                                        <span className="ml-lg">{this.state.selectedFilterCount}</span>
                                    )}

                                </Button>
                            </Col>

                            <Modal title={translate('filters', this.props.query.language, this.props.translations)} closeCallback={() => {this.setState({filterModalOpen: false})}} canClose={true} isOpen={this.state.filterModalOpen}>
                                <FilterListDefault closeFilterModal={this.closeFilterModal}/>
                            </Modal>

                        </Visible>

                        {/* check if availability is set */}
                        {this.props.availability ? (

                            <Col>

                                {this.props.property.settings.sort_order === 'pr' ? (

                                    <Row>

                                        {/* load available packages */}
                                        {this.props.availability.packages.map(packageItem => {
                                            if (packageItem.available) {
                                                return (
                                                    <PackageListItem
                                                        key={packageItem.id}
                                                        package={packageItem}
                                                    />
                                                )
                                            }
                                        })}

                                        {/* load available rooms */}
                                        {this.props.availability.rooms.map(roomItem => {
                                            if (roomItem.available) {
                                                return (
                                                    <RoomListItem
                                                        key={roomItem.id}
                                                        room={roomItem}
                                                    />
                                                )
                                            }
                                        })}

                                        {/* load unavailable packages */}
                                        {this.props.availability.packages.map(packageItem => {
                                            if (!packageItem.available) {
                                                return (
                                                    <PackageListItem
                                                        key={packageItem.id}
                                                        package={packageItem}
                                                    />
                                                )
                                            }
                                        })}

                                        {/* load unavailable rooms */}
                                        {this.props.availability.rooms.map(roomItem => {
                                            if (!roomItem.available) {
                                                return (
                                                    <RoomListItem
                                                        key={roomItem.id}
                                                        room={roomItem}
                                                    />
                                                )
                                            }
                                        })}

                                    </Row>

                                ) : (

                                    <Row>

                                        {/* load available rooms */}
                                        {this.props.availability.rooms.map(roomItem => {
                                            if (roomItem.available) {
                                                return (
                                                    <RoomListItem
                                                        key={roomItem.id}
                                                        room={roomItem}
                                                    />
                                                )
                                            }
                                        })}

                                        {/* load available packages */}
                                        {this.props.availability.packages.map(packageItem => {
                                            if (packageItem.available) {
                                                return (
                                                    <PackageListItem
                                                        key={packageItem.id}
                                                        package={packageItem}
                                                    />
                                                )
                                            }
                                        })}

                                        {/* load unavailable rooms */}
                                        {this.props.availability.rooms.map(roomItem => {
                                            if (!roomItem.available) {
                                                return (
                                                    <RoomListItem
                                                        key={roomItem.id}
                                                        room={roomItem}
                                                    />
                                                )
                                            }
                                        })}

                                        {/* load unavailable packages */}
                                        {this.props.availability.packages.map(packageItem => {
                                            if (!packageItem.available) {
                                                return (
                                                    <PackageListItem
                                                        key={packageItem.id}
                                                        package={packageItem}
                                                    />
                                                )
                                            }
                                        })}

                                    </Row>

                                )}

                            </Col>
                        ) : (
                            <Col>
                                <Row>
                                    <SkeletonListItem/>
                                    <SkeletonListItem/>
                                    <SkeletonListItem/>
                                    <SkeletonListItem/>
                                    <SkeletonListItem/>
                                    <SkeletonListItem/>
                                </Row>
                            </Col>
                        )}

                    </Row>

                </Container>

                {this.props.settings.corporateMessageShow && this.props.settings.corporatePasswordValid && (
                    <Modal title={translate('reduced-rates', this.props.query.language, this.props.translations)} isOpen={true} canClose={true} closeCallback={() => {this.props.dispatch(setCorporateMessageShow(false))}}>
                        {translate('reduced-rates-text', this.props.query.language, this.props.translations)}
                    </Modal>
                )}

                {this.props.settings.corporateMessageShow && this.props.settings.corporatePasswordInvalid && (
                    <Modal title={translate('reduced-rates', this.props.query.language, this.props.translations)} isOpen={true} canClose={true} isError={true} closeCallback={() => {this.props.dispatch(setCorporateMessageShow(false))}}>
                        {translate('reduced-rates-error', this.props.query.language, this.props.translations)}
                    </Modal>
                )}

            </div>

        );
    };
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        exchanges: state.exchanges,
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(Base(ListPage));
