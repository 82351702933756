import moment from "moment";

const defaultState = () => {

    //localStorage.setItem('token', 'dslfjdsflkjs');


    return {
        showExtras: false,
        showOverlay: false,
        menuOpen: false,
        cartOpen: false,
        filters: null,
        corporateMessageShow: null,
    }
};

export default (state = defaultState(), action) => {

    switch (action.type) {

        case 'SET_CORPORATE_PASSWORD':
            return {
                ...state,
                corporatePassword: action.corporatePassword,
            };

        case 'SET_CORPORATE_PASSWORD_INVALID':
            return {
                ...state,
                corporatePasswordInvalid: action.corporatePasswordInvalid,
            };

        case 'SET_CORPORATE_PASSWORD_VALID':
            return {
                ...state,
                corporatePasswordValid: action.corporatePasswordValid,
            };

        case 'SET_CORPORATE_MESSAGE_SHOW':

            if (state.corporateMessageShow === null || state.corporateMessageShow === true) {
                return {
                    ...state,
                    corporateMessageShow: action.corporateMessageShow,
                };
            } else {
                return state;
            }

        case 'SET_SHOW_EXTRAS':
            return {
                ...state,
                showExtras: action.showExtras,
            };

        case 'SET_MENU_OPEN':
            return {
                ...state,
                menuOpen: action.menuOpen,
            };

        case 'SET_CART_OPEN':
            return {
                ...state,
                cartOpen: action.cartOpen,
            };

        case 'SET_FILTERS':
            return {
                ...state,
                filters: action.filters,
            };

        case 'RESET_FILTERS':
            return {
                ...state,
                filters: null,
            };

        default:
            return state;
    }
};

