import React from "react";
import {connect} from "react-redux";
import styled, {ThemeProvider} from "styled-components";
import axios from "axios";
import translate from "../../../../i18n/translate";


const PaymentMethod = styled.div`
    cursor: pointer;
    &.active {
        border: 0.2rem solid ${props => props.theme.colors.primary} !important;
    }
`;

class CartPaymentSmartPay extends React.Component {

    state = {
        selectedMethod: null,
        methods: [],
    }

    theme = {
        colors: this.props.property.settings.colors,
    };

    componentDidMount = () => {
        this.getPaymentMethods();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.query.currency !== this.props.query.currency) {
            this.getPaymentMethods();
        }
    }

    getFriendlyProviderName = (name) => {
        const names = {

            // credit and debit cards
            'bcmc': 'Bancontact',
            'bcmc_mobile': 'Bancontact',
            'scheme': 'Creditcard',

            // middle east cards
            'benefit': 'Benefit',
            'knet': 'KNET',
            'naps': 'NAPS',
            'omannet': 'OmanNet',

            // Cash and ATM payment methods
            'molpay_cash': '7-Eleven',
            'doku_alfamart': 'Alfamart',
            'boletobancario_santander': 'Boleto',
            'molpay_epay': 'ePay',
            'doku_indomaret': 'Indomaret',
            'doku_permata_lite_atm': 'Indonesian bank transfer',
            'econtext_stores': '7-Eleven Japan',
            'econtext_seven_eleven': '7-Eleven Japan',
            'multibanco': 'Multibanco',
            'oxxo': 'OXXO',
            'econtext_atm': 'Pay-easy',
            'molpay_paysbuy': 'Paysbuy',
            'dragonpay_otc_philippines': 'Philippines convenience stores',
            'dragonpay_otc_banking': 'Philippines Over the counter bank & ATM',
            'yandex_cash': 'Russian cash terminals',

            // Direct Debit
            'ach': 'ACH Direct Debit',
            'directdebit_GB': 'BACS Direct Debit',
            'ratepay_directdebit': 'RatePay',
            'sepadirectdebit': 'SEPA Direct Debit',

            // Online banking
            'yandex_alfaclick': 'Alfa-Click',
            'blik': 'BLIK',
            'eps': 'EPS',
            'giropay': 'giropay',
            'ideal': 'iDEAL',
            'interac': 'Interac Online',
            'econtext_online': 'Japanese online banking',
            'kcp_banktransfer': 'Korean online banking',
            'molpay_ebanking_fpx_MY': 'Malaysian online banking',
            'mbway': 'MB WAY',
            'dragonpay_ebanking': 'Philippines online banking',
            'dotpay': 'Polish online banking',
            'yandex_promsvyazbank': 'Promsvyazbank online',
            'yandex_sberbank': 'Sberbank Online',
            'directEbanking': 'Sofort',
            'klarna_paynow': 'Klarna - Pay now',
            'molpay_ebanking_TH': 'Thai online banking',
            'trustly': 'Trustly',
            'momo_atm': 'MoMo ATM',

            // Buy Now Pay Later
            'facilypay_3x': '3x 4x Oney',
            'facilypay_4x': '3x 4x Oney',
            'facilypay_6x': '3x 4x Oney',
            'facilypay_10x': '3x 4x Oney',
            'facilypay_12x': '3x 4x Oney',
            'affirm': 'Affirm',
            'afterpaytouch': 'Afterpay',
            'clearpay': 'Clearpay',
            'fawry': 'Fawry',
            'grabpay_postpaid_SG': 'GrabPay PayLater',
            'grabpay_instalment4_SG': 'GrabPay PayLater',
            'grabpay_postpaid_MY': 'GrabPay PayLater',
            'grabpay_instalment4_MY': 'GrabPay PayLater',
            'klarna': 'Klarna - Pay later',
            'klarna_b2b': 'Klarna - Pay later',
            'klarna_account': 'Klarna - Pay over time',
            'paybright': 'PayBright',
            'ratepay': 'RatePay',
            'zip': 'Zip',

            // Prepaid and gift cards
            'Multiple': 'Gift cards',
            'paysafecard': 'PaySafeCard',

            // Wallets
            'cellulant': 'African eWallets and eBanking',
            'alipay': 'Alipay',
            'alipay_hk': 'Alipay HK',
            'amazonpay': 'Amazon Pay',
            'applepay': 'Apple Pay',
            'dana': 'DANA',
            'doku_wallet': 'Doku Wallet',
            'gcash': 'GCash',
            'grabpay_MY': 'GrabPay',
            'grabpay_PH': 'GrabPay',
            'grabpay_SG': 'GrabPay',
            'paywithgoogle': 'Google Pay',
            'kakaopay': 'KakaoPay',
            'masterpass': 'Masterpass',
            'mobilepay': 'MobilePay',
            'molpay_points': 'MOLPoints',
            'momo_wallet': 'MoMo Wallet',
            'kcp_payco': 'PayCo',
            'paypal': 'PayPal',
            'paytm': 'Paytm',
            'pix': 'Pix',
            'qiwiwallet': 'Qiwi',
            'swish': 'Swish',
            'samsungpay': 'Samsung Pay',
            'twint': 'TWINT',
            'vipps': 'Vipps',
            'visacheckout': 'Visa Click to Pay',
            'yandex_webmoney': 'WebMoney',
            'wechatpayWeb': 'WeChat Pay',
            'wechatpaySDK': 'WeChat Pay',
            'wechatpayQR': 'WeChat Pay',
            'yandex_money': 'Yandex Money',
        }
        return names[name];
    }

    getMethodName = (method) => {
        const names = {
            'scheme': 'card',
        }
        if (names[method]) {
            return names[method];
        } else {
            return method
        }

    }

    setPaymentMethod = (method) => {
        this.setState({selectedMethod: method});
        this.props.setPayment({
            provider: 'smartpay',
            method: method
        })
    }

    getPaymentMethods = () => {

        // set url
        let paymentMethodsUrl = `${process.env.API_URL}/v1/properties/${this.props.property.id}/payment-methods/`;

        // get locale
        const locale = navigator.language.toLowerCase() || navigator.userLanguage.toLowerCase();

        // get promoCode
        let promoCode = null;
        if (this.props.promo.discount) {
            promoCode = this.props.promo.discount.code
        }

        // create json
        const postData = {
            cart: this.props.cart,
            currency: this.props.query.currency,
            locale: locale,
            promo_code: promoCode
        }

        const postJson = JSON.stringify(postData);

        // do request
        const self = this;
        axios.post(paymentMethodsUrl, postJson)
            .then(function (response) {
                if(response.status === 200) {
                    self.setState({methods: response.data});
                }
            })
            .catch(function (error) {
                alert(error);
            });

    };

    // getFriendlyProviderName = (name) => {
    //     const names = {
    //         'applepay': 'Apple Pay',
    //         'bancontact': 'Bancontact',
    //         'creditcard': 'Creditcard',
    //         'ideal': 'iDeal',
    //         'paypal': 'PayPal',
    //     }
    //     return names[name];
    // }
    //
    // setPaymentMethod = (method) => {
    //     this.setState({selectedMethod: method});
    //     this.props.setPayment({
    //         provider: 'mollie',
    //         method: method
    //     })
    // }

    render = () => {
        return (
            <ThemeProvider theme={this.theme}>

                {this.state.methods.length > 0 ? (
                    <div className="cart-payment-mollie">
                        {this.state.methods.map((method, index) => {
                            return (
                                <PaymentMethod key={index} className={method === this.state.selectedMethod ? `cart-payment-mollie-method active mb-md` : `cart-payment-mollie-method mb-md`} onClick={() => {this.setPaymentMethod(method)}}>
                                    <img src={`https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${this.getMethodName(method)}.svg`} alt={method}/>
                                    <span className="ml-sm">{this.getFriendlyProviderName(method)}</span>
                                </PaymentMethod>
                            )
                        })}
                    </div>
                ) : (
                    <div className="cart-payment-mollie">
                        <div className="text-blink">
                            {translate('loading-payment-methods', this.props.query.language, this.props.translations)}
                        </div>

                    </div>
                )}

            </ThemeProvider>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        cart: state.cart,
        exchanges: state.exchanges,
        property: state.property,
        promo: state.promo,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(CartPaymentSmartPay);
