import React from "react";
import {motion} from "framer-motion"
import Button from "./Button";
import {connect} from "react-redux";
import {setShowExtras} from "../../actions/settings";
import { withRouter } from "react-router-dom"
import {getContent} from "../../utils/content";
import Currency from "./Currency";
import moment from "moment";
import {addExtraToItem, removeExtraFromItem} from "../../actions/cart";
import translate from "../../i18n/translate";

const variants = {
    open: {opacity: 1, y: 0},
    closed: {opacity: 0, y: '-100%'}
};

class Extras extends React.Component {

    state = {
        isOpen: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.settings.showExtras && !this.state.isOpen) {
            this.setState({isOpen: true});
        } else if (!this.props.settings.showExtras && this.state.isOpen) {
            this.setState({isOpen: false});
        }
    }

    calculateExtraTotal = (price, calculation) => {

        // get latest cart item
        let lastCartItem = this.props.cart[this.props.cart.length - 1];

        if (lastCartItem) {

            // number of night
            const startDate = moment(lastCartItem.checkin);
            const endDate = moment(lastCartItem.checkout);
            const nights = endDate.diff(startDate, 'days');

            switch (calculation) {

                case 'per_stay':
                    return price;

                case 'per_night':
                    return parseFloat(price * nights);

                case 'per_person':
                    return parseFloat(price * lastCartItem.adults);

                case 'per_person_per_night':
                    return parseFloat(parseFloat(price * lastCartItem.adults) * nights);

                default:
                    return price;
            }
        }
    }

    addToExtras = (extraId) => {
        let lastCartItem = this.props.cart[this.props.cart.length - 1];
        if (lastCartItem) {
            this.props.dispatch(addExtraToItem(lastCartItem.id, extraId))
        }
    }

    removeFromExtras = (extraId) => {
        let lastCartItem = this.props.cart[this.props.cart.length - 1];
        if (lastCartItem) {
            this.props.dispatch(removeExtraFromItem(lastCartItem.id, extraId));
        }
    }

    isExtraAdded = (extraId) => {

        let lastCartItem = this.props.cart[this.props.cart.length - 1];
        for (const cartItem of this.props.cart) {
            if (cartItem.id === lastCartItem.id) {
                if (cartItem['extras'] !== undefined) {
                    for (const extra of cartItem['extras']) {
                        if (extra === extraId) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    continueToCart = () => {

        // hide extra's
        this.props.dispatch(setShowExtras(false));

        // redirect to cart
        this.props.history.push(`/${this.props.property.id}/cart/`);

    }

    render = () => {

        return (

            <motion.div
                id="extras"
                animate={this.state.isOpen ? "open" : "closed"}
                variants={variants}
                transition={{duration: 0.2}}
            >

                {this.props.property.extras.map((extra, index) => {

                    if (!this.isExtraAdded(extra.id)) {
                        return (
                            <div className="extra" key={index}>
                                <div className="extra-content">
                                    <span>{getContent(extra.names, this.props.query.language)}</span>
                                    <div>{getContent(extra.descriptions, this.props.query.language)}</div>
                                </div>
                                <div>
                                    <span className="mr-md"><Currency>{this.calculateExtraTotal(extra.price, extra.calculation)}</Currency></span>
                                    <Button type="primary" onClick={() => {this.addToExtras(extra.id)}}>{translate('add', this.props.query.language, this.props.translations)}</Button>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className="extra added" key={index}>
                                <div className="extra-content">
                                    <span>{getContent(extra.names, this.props.query.language)}</span>
                                    <div>{getContent(extra.descriptions, this.props.query.language)}</div>
                                </div>
                                <div>
                                    <span className="mr-md"><Currency>{this.calculateExtraTotal(extra.price, extra.calculation)}</Currency></span>
                                    <Button onClick={() => {this.removeFromExtras(extra.id)}}>{translate('remove', this.props.query.language, this.props.translations)}</Button>
                                </div>
                            </div>
                        )
                    }

                })}

                <div className="extra text-right">
                    &nbsp;
                    <Button onClick={this.continueToCart}>{translate('continue', this.props.query.language, this.props.translations)}</Button>
                </div>

            </motion.div>

        );

    };
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        settings: state.settings,
        property: state.property,
        query: state.query,
        translations: state.translations,
        extras: state.extras,
    };
};

export default withRouter(connect(mapStateToProps)(Extras));
