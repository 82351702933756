export const getTotalPriceByRateAndGuest = (rate, guestType, guestAmount) => {

    // get dates
    let totalRatePrice = 0;

    if (rate === null) {
        return 0;
    }

    const dates = rate.dates;

    for (const dateKey in dates) {
        if (dates.hasOwnProperty(dateKey)) {

            // loop to get guest specific price
            // get guests
            const date = dates[dateKey];
            const guests = date.guests;
            for (const guestKey in guests) {
                if (guests.hasOwnProperty(guestKey)) {

                    // get guest
                    const guest = guests[guestKey]
                    if (guest.guest_type === guestType && guest.guest_amount === guestAmount) {
                        totalRatePrice += guest.price;
                    }
                }
            }
        }
    }

    return totalRatePrice;

};


export const calculateDiscount = (discountState, originalRate, numberOfPersons, numberOfNights, isPricePerNight) => {

    // check if there is a discount active
    let discountRate = 0;

    // fixed promo
    if (discountState.type === 'fixed') {
        discountRate = discountState.amount;
        if (discountState.is_per_person) {
            discountRate = parseFloat(discountRate * numberOfPersons);
        }
        if (discountState.is_per_night) {
            discountRate = parseFloat(discountRate * numberOfNights);
        }
    } else {
        discountRate = parseFloat(parseFloat(originalRate / 100) * discountState.amount);
    }

    if (isPricePerNight) {
        discountRate = parseFloat(discountRate / numberOfNights);
    }

    // calculate discounted rate
    const discountedRate = parseFloat(originalRate - discountRate);

    return {
        originalRate: originalRate,
        discountedRate: discountedRate,
    }

}
