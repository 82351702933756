import {convertCurrency} from "../utils/convert";

export const getFees = (fees, price, numberOfNights, numberOfPersons, defaultCurrency, exchanges) => {

    // add fees
    let feeTotals = 0;
    for (const fee of fees) {

        // get fixed fee amount
        let feeAmount;
        const feeObject = fee.fee;
        if (feeObject.unit === 'f' && defaultCurrency !== 'EUR') {
            feeAmount = convertCurrency(exchanges, feeObject.amount, defaultCurrency, 'EUR')
        } else if (feeObject.unit === 'p') {
            feeAmount = (price / 100) * feeObject.amount;
        } else {
            feeAmount = feeObject.amount;
        }

        // calculate is_per_night
        if (feeObject.is_per_night) {
            feeAmount = feeAmount * numberOfNights;
        }

        // calculate is_per_person
        if (feeObject.is_per_person) {
            feeAmount = feeAmount * numberOfPersons;
        }

        // add to fee totals
        feeTotals = feeTotals + feeAmount;
    }

    return feeTotals;

}