import React from "react";
import connect from "react-redux/es/connect/connect";
import translate from "../../../i18n/translate";
import {addItem, removeItem} from "../../../actions/items";

class ItemOccupancyRoom extends React.Component {

    state = {
        id: Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
        numberOfAdults: 0,
        numberOfChildren: 0,
        numberOfInfants: 0,
    }

    componentDidMount = () => {
        this.addUpdateItem();
        this.setInitialNumberOfAdults();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.state.numberOfAdults !== prevState.numberOfAdults || this.state.numberOfChildren !== prevState.numberOfChildren || this.state.numberOfInfants !== prevState.numberOfInfants) {
            this.addUpdateItem();
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(removeItem({
            id: this.state.id,
            adults: this.state.numberOfAdults,
            children: this.state.numberOfChildren,
            infants: this.state.numberOfInfants
        }))
    }

    setInitialNumberOfAdults = () => {
        if(2 >= this.props.item.min_occupancy && 2 <= this.props.item.max_occupancy) {
            this.setState({numberOfAdults: 2});
        } else {
            this.setState({numberOfAdults: this.props.item.min_occupancy});
        }
    }

    addUpdateItem = () => {
        this.props.dispatch(addItem({
            id: this.state.id,
            adults: this.state.numberOfAdults,
            children: this.state.numberOfChildren,
            infants: this.state.numberOfInfants,
        }));
    }

    setNumberOfAdults = (event) => {
        const totalOccupancy = parseInt(this.state.numberOfChildren) + parseInt(event.target.value);
        if (totalOccupancy > this.props.item.max_occupancy) {
            const newNumberOfChildren = parseInt(this.props.item.max_occupancy) - parseInt(event.target.value);
            this.setState({numberOfChildren: newNumberOfChildren});
        }
        this.setState({numberOfAdults: parseInt(event.target.value)});
    }

    setNumberOfChildren = (event) => {

        // test
        const totalOccupancy = parseInt(this.state.numberOfAdults) + parseInt(event.target.value);

        if (this.props.item.max_occupancy <= parseInt(event.target.value)) {
            return;
        }

        if (totalOccupancy > this.props.item.max_occupancy) {
            const newNumberOfAdults = parseInt(this.state.numberOfAdults) - parseInt(event.target.value);
            this.setState({numberOfAdults: parseInt(newNumberOfAdults)});
        }
        this.setState({numberOfChildren: parseInt(event.target.value)});
    }

    setNumberOfInfants = (event) => {
        this.setState({numberOfInfants: parseInt(event.target.value)});
    }

    getPersonsArray = (personType, startAt) => {

        let counter = startAt;
        let personsArray = [];
        let maxOccupancy;

        switch(personType) {

            case 'adults':
                maxOccupancy = this.props.item.max_occupancy + 1;
                break;

            case 'children':
                maxOccupancy = this.props.item.max_children + 1;
                break;

            case 'infants':
                maxOccupancy = this.props.item.max_infants + 1;
                break;

            default:
                maxOccupancy = this.props.item.max_occupancy + 1;

        }

        while (counter < maxOccupancy) {
            personsArray.push(counter);
            counter += 1;
        }

        return personsArray;
    }

    render = () => {

        return (

            <div className="item-room-group p-lg">

                <span className="pb-sm">{translate('room', this.props.query.language, this.props.translations)} {this.props.number}</span>

                <div className="item-room-occupancy-group">

                    <div className="item-room-occupancy pb-sm mr-lg">
                        <div className="mt-md mb-sm">{translate('adults', this.props.query.language, this.props.translations)}</div>
                        <select onChange={this.setNumberOfAdults} value={this.state.numberOfAdults}>
                            {this.getPersonsArray('adults', this.props.item.min_occupancy).map((number, numberKey) =>
                                <option key={numberKey} value={number}>{number}</option>
                            )}
                        </select>
                    </div>

                    {this.props.item.max_children > 0 && (
                        <div className="item-room-occupancy pb-sm mr-lg">
                            <div className="mt-md mb-sm">{translate('children', this.props.query.language, this.props.translations)}</div>
                            <select onChange={this.setNumberOfChildren} value={this.state.numberOfChildren}>
                                {this.getPersonsArray('children', 0).map((number, numberKey) =>
                                    <option key={numberKey} value={number}>{number}</option>
                                )}
                            </select>
                        </div>
                    )}

                    {this.props.item.max_infants > 0 && (
                        <div className="item-room-occupancy pb-sm">
                            <div className="mt-md mb-sm">{translate('infants', this.props.query.language, this.props.translations)}</div>
                            <select onChange={this.setNumberOfInfants} value={this.state.numberOfInfants}>
                                {this.getPersonsArray('infants', 0).map((number, numberKey) =>
                                    <option key={numberKey} value={number}>{number}</option>
                                )}
                            </select>
                        </div>
                    )}

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(ItemOccupancyRoom);
