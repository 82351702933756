import React from "react";
import {connect} from "react-redux";
import styled, {ThemeProvider} from "styled-components";
import { darken } from 'polished';

const PrimaryButton = styled.span`
    background-color: ${props => props.theme.primary};
    color: #FFFFFF;
    &:hover {
        background-color: ${props => props.theme.primaryHover};
    }
    &:active {
        background-color: ${props => props.theme.primaryActive};
    }
    span {
        background: blue;
    }
`;


class Button extends React.Component {

    theme = {
        primary: this.props.property.settings.colors.primary,
        primaryHover: darken(0.1, this.props.property.settings.colors.primary),
        primaryActive: darken(0.2, this.props.property.settings.colors.primary),
    };

    render = () => {

        switch (this.props.type) {

            case 'primary':
                return (
                    <ThemeProvider theme={this.theme}>
                        <PrimaryButton onClick={this.props.onClick} className={`button ${this.props.block ? ' block' : ''}`}>{this.props.children}</PrimaryButton>
                    </ThemeProvider>
                )


            default:
                return (
                    <span onClick={this.props.onClick} className="button">{this.props.children}</span>
                )
        }

    };
}


const mapStateToProps = (state) => {
    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(Button);

