import React from "react";
import {connect} from "react-redux";
import translate from "../../../../i18n/translate";
import CartPaymentCreditCard from "./CartPaymentCreditCard";
import CartPaymentMollie from "./CartPaymentMollie";
import CartPaymentSmartpay from "./CartPaymentSmartpay";
import CartPaymentSmartpayCCG from "./CartPaymentSmartpayCCG";


class CartPayment extends React.Component {

    componentDidMount = () => {
        if (!this.props.totals.payment_required) {
            this.props.setPayment({
                provider: 'at-hotel'
            })
        } else {
            this.props.setPayment(false);
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (!this.props.totals.payment_required) {
            this.props.setPayment({
                provider: 'at-hotel'
            })
        } else {
            this.props.setPayment(false);
        }
    }

    render = () => {

        return (
            <div className="cart-payment p-lg mb-lg">

                <h2 className="title">{translate('payment', this.props.query.language, this.props.translations)}</h2>


                {this.props.totals.payment_required ? (

                    <div className="cart-payment-provider">

                        {this.props.property.payment.provider === 'credit-card-guarantee' && (
                            <CartPaymentCreditCard setPayment={this.props.setPayment}/>
                        )}

                        {this.props.property.payment.provider === 'mollie' && (
                            <CartPaymentMollie setPayment={this.props.setPayment}/>
                        )}

                        {this.props.property.payment.provider === 'smartpay' && (
                            <CartPaymentSmartpay setPayment={this.props.setPayment}/>
                        )}

                        {this.props.property.payment.provider === 'smartpay-ccg' && (
                            <CartPaymentSmartpayCCG setPayment={this.props.setPayment}/>
                        )}

                    </div>

                ) : (

                    <div className="cart-payment-provider">
                        {translate('pay-at-hotel', this.props.query.language, this.props.translations)}:<br/>
                        <span>{this.props.property.address.phone}</span>
                    </div>

                )}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        cart: state.cart,
        exchanges: state.exchanges,
        property: state.property,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(CartPayment);
