import React from "react";
import connect from "react-redux/es/connect/connect";
import translate from "../../../i18n/translate";
import {getContent} from "../../../utils/content";
import Button from "../../generic/Button";
import {addToCart} from "../../../actions/cart";
import {withRouter} from "react-router-dom"
import Currency from "../../generic/Currency";
import ItemPackageOccupancyRoom from "./ItemPackageOccupancyRoom";
import {calculateDiscount, getTotalPriceByRateAndGuest} from "../../../utils/rates";
import ReactHtmlParser from "react-html-parser";
import {getFees} from "../../../i18n/fees";
import TagManager from "react-gtm-module";


class ItemPackageOccupancy extends React.Component {

    state = {
        numberOfPackageRooms: 1,
        items: []
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.items !== prevState.items) {

        }

    }

    getTotalPriceForPackageRoomRate = () => {

        //getTotalPriceByRateAndGuest(rate, 'child', item.children);
        let adultsTotal = 0;
        let childrenTotal = 0;
        let infantsTotal = 0;
        let numberOfPersons = 0;
        for (const itemKey in this.state.items) {
            if (this.state.items.hasOwnProperty(itemKey)) {

                // get item
                const item = this.state.items[itemKey];
                const rate = this.props.packageRoom.rate;

                // check if children are set
                let additionalAdults = 0;
                if (item.children > 0) {

                    // get child price
                    const childPrice = getTotalPriceByRateAndGuest(rate, 'child', item.children);
                    if (childPrice === 0) {

                        // add additional adults
                        additionalAdults = item.children;

                    } else {
                        // add to children total
                        childrenTotal += childPrice;
                    }

                }

                // get infants price
                if (item.infants > 0) {
                    infantsTotal += getTotalPriceByRateAndGuest(rate, 'infant', item.infants);
                }

                // get adults price
                adultsTotal += getTotalPriceByRateAndGuest(rate, 'adult', item.adults + additionalAdults);

                // add to numberOfPersons
                numberOfPersons = parseInt(item.adults + item.children);

            }
        }

        // return totals
        const totals = adultsTotal + childrenTotal + infantsTotal;

        // get number of nights
        const numberOfNights = this.props.query.checkout.diff(this.props.query.checkin, 'days');

        // parse price
        let parsedPrice = (totals / 100);

        // get fees
        const feeTotals = getFees(
            this.props.item.fees,
            parsedPrice,
            numberOfNights,
            numberOfPersons,
            this.props.property.settings.default_currency,
            this.props.exchanges,
        );

        // add fees to parsedPrice
        parsedPrice = parsedPrice + feeTotals;

        // check if price per night is set
        if (this.props.property.settings.interface.show_price_per_night) {
            parsedPrice = parseFloat(parsedPrice / numberOfNights);
        }

        // add discount if set
        let discount = null;
        if (this.props.promo.discount) {
            const numberOfNights = this.props.query.checkout.diff(this.props.query.checkin, 'days');
            discount = calculateDiscount(this.props.promo.discount, parsedPrice, numberOfPersons, numberOfNights);
            return {
                parsedPrice: parsedPrice,
                discountedPrice: discount.discountedRate,
            };
        } else {
            return {
                parsedPrice: parsedPrice
            };
        }

    }

    getPackageRoomInfo = () => {
        let roomInfo = null;
        for (const roomKey in this.props.property.rooms) {
            if (this.props.property.rooms.hasOwnProperty(roomKey)) {
                if (this.props.property.rooms[roomKey].id === this.props.packageRoom.id) {
                    roomInfo = this.props.property.rooms[roomKey];
                }
            }
        }
        return roomInfo;
    }

    getPackageRoomOccupancy = (roomInfo) => {
        if (roomInfo.min_occupancy === roomInfo.max_occupancy) {
            return roomInfo.min_occupancy;
        } else {
            return roomInfo.min_occupancy + '-' + roomInfo.max_occupancy;
        }
    }

    getNumberOfAvailablePackageRoomsArray = (packageRoom) => {
        let numberCounter = 1;
        let numberOfRoomsArray = [];
        while (packageRoom.quantity >= numberCounter && numberCounter <= 10) {
            numberOfRoomsArray.push(numberCounter);
            numberCounter += 1;
        }
        return numberOfRoomsArray;
    }

    getNumberOfPackageRoomsList = () => {

        let numberOfRoomList = [];
        let count = 0
        while (this.state.numberOfPackageRooms > count) {
            count += 1;
            numberOfRoomList.push(count);
        }
        return numberOfRoomList;

    }

    setNumberOfPackageRooms = (event) => {
        this.setState({numberOfPackageRooms: event.target.value});
    }

    addToCart = () => {

        // loop each item
        for (const itemKey in this.state.items) {

            if (this.state.items.hasOwnProperty(itemKey)) {

                // get item
                const item = this.state.items[itemKey];

                // // go and add to cart
                const cartItem = {
                    id: Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
                    checkin: this.props.query.checkin.format('YYYY-MM-DD'),
                    checkout: this.props.query.checkout.format('YYYY-MM-DD'),
                    type: 'room',
                    roomId: this.props.packageRoom.id,
                    rateId: this.props.packageRoom.rate.id,
                    packageId: this.props.item.id,
                    adults: item.adults,
                    children: item.children,
                    infants: item.infants,
                    extras: [],
                    fees: this.props.item.fees,
                }

                // send datalayer
                let dataLayer = {
                    'event': 'add_to_cart',
                    'ecommerce': {
                        'items': {
                            'item': {
                                'item_name': this.props.item.external_name,
                                'affiliation': 'Paxxio OBE'
                            }
                        }
                    }
                }

                // set tag manager
                TagManager.dataLayer({dataLayer: dataLayer});

                // add to cart
                this.props.dispatch(addToCart(cartItem));

                // go to cart page
                this.props.history.push(`/${this.props.property.id}/cart/`);

            }
        }
    }



    addToItems = (packageItem) => {

        // newItems
        let newItems = [];

        // check if item exists
        for (const itemKey in this.state.items) {
            if (this.state.items.hasOwnProperty(itemKey)) {
                if (this.state.items[itemKey].id !== packageItem.id) {
                    newItems.push(this.state.items[itemKey]);
                }
            }
        }

        // add item
        newItems.push(packageItem);

        // set state
        this.setState({items: newItems});

    }

    removeFromItems = (packageItemId) => {

        // newItems
        let newItems = [];

        // check if item exists
        for (const itemKey in this.state.items) {
            if (this.state.items.hasOwnProperty(itemKey)) {
                if (this.state.items[itemKey].id !== packageItemId) {
                    newItems.push(this.state.items[itemKey]);
                }
            }
        }

        // set state
        this.setState({items: newItems});
    }


    render = () => {


        if (this.props.packageRoom.available) {

            return (

                <div className="item-package-room p-lg mt-lg">

                    <h3 className="mb-md">{getContent(this.getPackageRoomInfo(this.props.packageRoom).names, this.props.query.language)}</h3>

                    <div className="tag mb-md">
                        <i className="fas fa-user"/>
                        <span>{this.getPackageRoomOccupancy(this.getPackageRoomInfo(this.props.packageRoom))}</span>
                    </div>

                    <div>
                        {ReactHtmlParser(getContent(this.getPackageRoomInfo(this.props.packageRoom).descriptions, this.props.query.language))}
                    </div>

                    <div className="item-package-room-amount mt-lg">
                        <span>{translate('number-of-rooms', this.props.query.language, this.props.translations)}</span>
                        <select className="mt-md" onChange={this.setNumberOfPackageRooms}>
                            {this.getNumberOfAvailablePackageRoomsArray(this.props.packageRoom).map((number, numberKey) =>
                                <option key={numberKey} value={number}>{number}</option>
                            )}
                        </select>
                    </div>

                    {this.getNumberOfPackageRoomsList().map((number, numberKey) =>
                        <ItemPackageOccupancyRoom key={numberKey} number={number} item={this.props.packageRoom} addToItems={this.addToItems} removeFromItems={this.removeFromItems}/>
                    )}

                    <div className="item-package-room-cta mt-lg">

                        <div className="item-package-room-cta-price">

                            {this.props.promo.discount ? (
                                <span className="discounted-rate">
                                    <h3><Currency>{this.getTotalPriceForPackageRoomRate().parsedPrice}</Currency> <Currency>{this.getTotalPriceForPackageRoomRate().discountedPrice}</Currency></h3>
                                </span>
                            ) : (

                                <h3><Currency>{this.getTotalPriceForPackageRoomRate().parsedPrice}</Currency></h3>
                            )}

                            {this.props.property.settings.interface.show_price_per_night && (
                                <span className="item-rat-per-night">&nbsp;/&nbsp;{translate('per-night', this.props.query.language, this.props.translations)}</span>
                            )}
                        </div>

                        <Button onClick={this.addToCart} type={'primary'}>{translate('book-now', this.props.query.language, this.props.translations)}</Button>

                    </div>

                </div>

            )
        } else {
            return <span/>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        exchanges: state.exchanges,
        promo: state.promo,
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default withRouter(connect(mapStateToProps)(ItemPackageOccupancy));
