import React from "react";
import {connect} from "react-redux";
import {motion} from "framer-motion";
import {setCartOpen} from "../../actions/settings";
import styled, {ThemeProvider} from "styled-components";
import {Link} from "react-router-dom";

const variants = {
    open: {opacity: 1, x: 0},
    closed: {opacity: 0, x: '100%'},
}

const OptionDiv = styled.div`
    i.fa-check-circle {
        color: ${props => props.theme.colors.primary};
    }
    &:hover {
        i.fa-circle {
            color: ${props => props.theme.colors.primary};
        }
    }
`;

class Cart extends React.Component {

    theme = {
        colors: this.props.property.settings.colors,
    };

    render = () => {

        return (

            <ThemeProvider theme={this.theme}>

                <motion.nav animate={this.props.settings.cartOpen ? 'open' : 'closed'} variants={variants} id="cart">

                    <div className="cart-close">
                        <i onClick={() => {this.props.dispatch(setCartOpen(false))}} className="far fa-times"/>
                    </div>

                    <div>Nothing</div>

                    <Link to={`/${this.props.property.id}/cart/`}>Cart</Link>

                </motion.nav>
            </ThemeProvider>

        );
    };
}


const mapStateToProps = (state) => {
    return {
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(Cart);
