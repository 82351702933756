export const addExtraToItem = (itemId, extraId) => ({
    type: 'ADD_EXTRA_TO_ITEM',
    itemId: itemId,
    extraId: extraId,
});

export const removeExtraFromItem = (itemId, extraId) => ({
    type: 'REMOVE_EXTRA_FROM_ITEM',
    itemId: itemId,
    extraId: extraId,
});

export const addToCart = (item) => ({
    type: 'ADD_TO_CART',
    item: item,
});

export const removeFromCart = (item) => ({
    type: 'REMOVE_FROM_CART',
    item: item,
});

export const emptyCart = () => ({
    type: 'EMPTY_CART',
});
