import React from "react";

const variants = {
    open: {opacity: 1, scale: 1},
    closed: {opacity: 0, scale: 0}
};

export default class Modal extends React.Component {

    state = {
        isOpen: this.props.isOpen
    };

    closeAndCallback = () => {
        this.setState({isOpen: false});
        this.props.closeCallback();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({isOpen: this.props.isOpen});
        }
    }

    render = () => {

        if (this.state.isOpen) {
            return (
                <div className="modal-overlay">
                    <div className="modal-container">
                        <div className="modal-header m-lg">

                            <h3 className={this.props.isError ? 'error' : ''}>{this.props.title}</h3>

                            {this.props.canClose && (
                                <i onClick={this.closeAndCallback} className="fas fa-times"/>
                            )}

                            {this.props.showLoading && (
                                <i className="fas fa-spinner-third"/>
                            )}

                        </div>
                        <div className="modal-body m-lg">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            );
        } else {
            return <span/>
        }


    };
}
