import React from "react";
import {connect} from "react-redux";
import styled, {ThemeProvider} from "styled-components";
import axios from "axios";
import translate from "../../../../i18n/translate";
import ReactHtmlParser from "react-html-parser";


const PaymentMethod = styled.div`
    cursor: pointer;
    &.active {
        border: 0.2rem solid ${props => props.theme.colors.primary} !important;
    }
`;

class CartPaymentSmartPayCCG extends React.Component {

    state = {
        selectedMethod: null,
        methods: ['preauth'],
    }

    theme = {
        colors: this.props.property.settings.colors,
    };

    componentDidMount = () => {
        setTimeout(() => {
            this.setPaymentMethod();
        }, 500);
    }


    setPaymentMethod = () => {
        this.setState({selectedMethod: 'preauth'});
        this.props.setPayment({
            provider: 'smartpay-ccg',
            method: 'preauth'
        });
    }

    render = () => {
        return (
            <ThemeProvider theme={this.theme}>
                {ReactHtmlParser(translate('preauth-description', this.props.query.language, this.props.translations))}
            </ThemeProvider>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        cart: state.cart,
        exchanges: state.exchanges,
        property: state.property,
        promo: state.promo,
        query: state.query,
        rooms: state.rooms,
        settings: state.settings,
        translations: state.translations,
    };
};

export default connect(mapStateToProps)(CartPaymentSmartPayCCG);
