import React from "react";
import moment from "moment";
import styled, {ThemeProvider} from "styled-components";
import {lighten} from "polished";
import axios from "axios";


const DayDiv = styled.div`
    &:hover, &.first, &.last {
        background-color: ${props => props.theme.highlightColor};
    }
    &.selected {
        background-color: ${props => lighten(0.1, props.theme.highlightColor)};
    }
`;

export default class DateRangePicker extends React.Component {

    state = {
        calendars: null,
        containerWidth: null,
        currentAction: 'setCheckin',
        currentDate: null,
        currentCalendar: null,
        currentCheckin: null,
        currentCheckout: null,
        currentPosition: null,
        initialPositionSet: false,
        monthYears: [],
        positionLeft: 0,
        availability: null,
    };

    theme = {
        highlightColor: this.props.highlightColor,
    };

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    componentDidMount = () => {
        if (!this.state.calendars) {
            this.initialCalenders();
            this.initialDates();
        }
    };

    componentDidUpdate = (prevProps, prevState) => {

        // check if container is ready
        if (!this.state.containerWidth && this.containerRef.current) {

            // set container width
            this.setContainerWidth();

            // set initial position
            this.setInitialPosition();
        }

        // check if position has changed
        if (this.state.currentPosition !== prevState.currentPosition) {
            this.moveToPosition(false);
            this.setCalendarAvailability();
        }

        // check if locale changed
        if (prevProps.locale !== this.props.locale) {
            this.setMonthYears();
            this.initialCalenders();
            this.initialDates();
        }

    };

    initialCalenders = () => {

        // set initial state
        this.setState({
            currentDate: this.props.checkin,
        });

        // create calendars
        let calendars = [];
        const numberOfMonths = 19;
        const initDate = moment().locale(this.props.locale);
        for (let i = 0; i < numberOfMonths; i++) {
            calendars.push(this.createCalendar(initDate));
            initDate.add(1, 'month');
        }

        // set initial monthYears
        this.setMonthYears();

        // set state
        this.setState({
            calendars: calendars,
        });
    };

    initialDates = () => {
        this.setState({
            currentCheckin: this.props.checkin,
            currentCheckout: this.props.checkout,
        })
    };

    setMonthYears = () => {

        // creat monthYears
        let monthYears = [];
        const numberOfMonths = 19;
        const initDate = moment().locale(this.props.locale);
        for (let i = 0; i < numberOfMonths; i++) {
            monthYears.push(initDate.format('MMMM YYYY'));
            initDate.add(1, 'month');
        }

        // set state
        this.setState({
            monthYears: monthYears
        });

    }

    createCalendar = (date) => {

        let calendar = [];
        const startDay = date.clone().startOf('month').startOf('week');
        const endDay = date.clone().endOf('month').endOf('week');
        let dateLess = startDay.clone().subtract(1, 'day');

        while (dateLess.isBefore(endDay, 'day')) {
            calendar.push({
                days: Array(7).fill(0).map(() => dateLess.add(1, 'day').clone())
            });
        }

        return calendar;

    };

    move = (direction) => {

        // set new position
        let prevPosition = this.state.currentPosition;
        let newPosition = 0;
        if (direction === 'next') {
            newPosition = this.state.currentPosition + 1;
        } else {
            newPosition = this.state.currentPosition - 1;
        }

        // check min/max position
        const minPosition = 0;
        const maxPosition = (19  - this.props.numberOfCalendars);

        // set currentPosition
        if (newPosition < minPosition || newPosition > maxPosition) {
            this.setState({currentPosition: prevPosition});
            this.setContainerHeight(prevPosition);
        } else {
            this.setState({currentPosition: newPosition});
            this.setContainerHeight(newPosition);
        }

    };

    moveToPosition = () => {

        // get container dimensions
        let newLeft = 0;
        const containerRect = this.containerRef.current.getBoundingClientRect();
        const singleCalendarWidth = (containerRect.width / this.props.numberOfCalendars);
        for (let i = 0; i < this.state.currentPosition; i++) {
            if (!this.state.initialPositionSet) {
                newLeft = newLeft + containerRect.width;
            } else {
                newLeft = newLeft + singleCalendarWidth;
            }
        }

        // create negative value
        newLeft = (-newLeft);

        // update left
        this.containerRef.current.style.left = newLeft + 'px';

        // update state
        this.setState({
            initialPositionSet: true,
            positionLeft: newLeft
        });

    };

    setContainerHeight = (position) => {

        // get positions
        let visiblePositions = [];
        for (let i = 0; i < this.props.numberOfCalendars; i++) {
            visiblePositions.push(position + i);
        }

        // get highest number of weeks
        let highestNumberOfWeeks = 0;
        visiblePositions.forEach(visiblePosition => {
            const numberOfWeeks = this.state.calendars[visiblePosition].length;
            if (numberOfWeeks > highestNumberOfWeeks) {
                highestNumberOfWeeks = numberOfWeeks;
            }
        });

        // set container height based on number of weeks
        if (highestNumberOfWeeks === 5) {
            this.containerRef.current.style.height = '425px';
        } else {
            this.containerRef.current.style.height = '485px';
        }
    };

    setContainerWidth = () => {
        if (this.props.numberOfCalendars) {
            const newContainerWidth = this.containerRef.current.offsetWidth * this.props.numberOfCalendars;
            this.containerRef.current.style.width = newContainerWidth + 'px';
            this.setState({containerWidth: newContainerWidth});
        } else {
            this.setState({containerWidth: this.containerRef.current.style.width});
        }
    };

    setInitialPosition = () => {

        // get position of current calendar
        let positionCount = 0;
        const calendars = this.containerRef.current.children;
        for (let calendar of calendars) {
            if (calendar.classList.contains('initial')) {
                break;
            }
            positionCount++;
        }

        // set initial height
        this.setContainerHeight(positionCount);

        // set current position
        this.setState({currentPosition: positionCount});
    };

    getClasses = (date, week, calendar) => {

        let classes = '';

        // check checkin
        if (date.startOf('day').isSame(this.state.currentCheckin.startOf('day'))) {
            classes += 'first ';
        }

        // check checkout
        if (date.startOf('day').isSame(this.state.currentCheckout.startOf('day'))) {
            classes += 'last ';
        }

        // check active
        if (date.startOf('day').isBetween(this.state.currentCheckin.startOf('day'), this.state.currentCheckout.startOf('day'))) {
            classes += 'selected ';
        }

        // check if other month
        if (date.format('YYYY-MM') !== calendar[2].days[0].format('YYYY-MM')) {
            classes += 'hidden ';
        }

        // check disabled
        if (moment().isSameOrAfter(date.clone().add(1, 'day'))) {
            classes += 'disabled ';
        }

        // check end of year
        if (date.startOf('day').isAfter(moment().add(18, 'months').startOf('day'))) {
            classes += 'disabled ';
        }

        // check if availability is loaded
        if (!this.state.availability) {
            classes += 'disabled ';
        }

        // check if date is unavailable
        if (this.state.availability) {
            const date_string = date.format('YYYY-MM-DD');
            const date_available = this.state.availability[date_string];
            if (date_available === false) {
                classes += 'unavailable ';
            }
        }

        return classes;
    };

    getWeekDays = () => {
        let weekDays = [];
        const startOfWeek = moment().locale(this.props.locale).startOf('week');
        for (let i = 0; i < 7; i++) {
            weekDays.push(startOfWeek.format('dd'));
            startOfWeek.add(1, 'day');
        }
        return weekDays;
    };

    isCurrentCalendar = (calendar) => {
        const calendarMonthYear = calendar[1].days[0].format('MM-YY');
        const checkinMonthYear = this.props.checkin.format('MM-YY');
        return calendarMonthYear === checkinMonthYear;
    };

    selectDate = (date, calendarPosition) => {

        switch (this.state.currentAction) {

            case 'setCheckin':

                const checkinDate = date.clone();
                const checkoutDate = date.clone().add(this.props.minLos, 'days');

                if (date.startOf('day').isBefore(moment().startOf('day'))) {
                    break;
                }

                if (date.clone().add(this.props.minLos, 'days').startOf('day').isAfter(moment().add(18, 'months').startOf('day'))) {
                    break;
                }

                this.setState({
                    currentAction: 'setCheckout',
                    currentCheckin: checkinDate,
                    currentCheckout: checkoutDate,
                    currentPosition: calendarPosition < 18 ? calendarPosition : 17
                });
                this.props.onCheckinChange(checkinDate, checkoutDate);
                break;

            case 'setCheckout':

                if (date.startOf('day').isBefore(moment().startOf('day'))) {
                    break;
                }

                if (date.startOf('day').isBefore(this.state.currentCheckin.clone().add(this.props.minLos, 'days').startOf('day'))) {
                    if (date.startOf('day').isBefore(this.state.currentCheckin.startOf('day'))) {
                        if (date.startOf('day').isAfter(this.state.currentCheckin.clone().subtract(this.props.minLos, 'days').startOf('day'))) {
                            break;
                        }
                    } else {
                        break;
                    }
                }

                if (date.isBefore(this.state.currentCheckin)) {

                    const checkinDate = date.clone();
                    const checkoutDate = this.state.currentCheckin.clone();

                    this.setState({
                        currentAction: 'setCheckin',
                        currentCheckin: date.clone(),
                        currentCheckout: this.state.currentCheckin.clone(),
                    });

                    this.props.onCheckoutChange(checkinDate, checkoutDate);

                } else {

                    const checkinDate = this.state.currentCheckin.clone();
                    const checkoutDate = date.clone();

                    this.setState({
                        currentAction: 'setCheckin',
                        currentCheckout: date.clone(),
                    });

                    this.props.onCheckoutChange(checkinDate, checkoutDate);

                }

                break;
        }
    };

    setCalendarAvailability = () => {

        // reset availability
        this.setState({availability: null});

        // get values
        const monthYear = this.state.monthYears[this.state.currentPosition];
        const monthYearSplit = monthYear.split(' ');

        // set startDate
        const startDate = moment().locale(this.props.locale)
        startDate.date(1);
        startDate.month(monthYearSplit[0]);
        startDate.year(monthYearSplit[1]);

        // set endDate
        const endDate = startDate.clone().add(this.props.numberOfCalendars-1, 'months').endOf('month');

        // do call
        axios.get(`${process.env.API_URL}/v1/properties/${this.props.propertyId}/calendar/?start=${startDate.format('YYYY-MM-DD')}&end=${endDate.format('YYYY-MM-DD')}`,)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({availability: response.data.calendar});
                } else {
                    alert('Something wen\'t wrong');
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }

    render = () => {

        if (this.state.calendars) {

            return (

                <div>
                    <div className="date-range-overlay" onClick={this.props.toggleStayPopover}/>
                    <div className="date-range-picker">

                        <div className="navigation">
                            <span className="prev" onClick={() => {
                                this.move('prev')
                            }}>
                                <i className="far fa-chevron-left"/>
                            </span>
                            <span className="next" onClick={() => {
                                this.move('next')
                            }}>
                                <i className="far fa-chevron-right"/>
                            </span>
                        </div>

                        <div className="overflow">

                            <div className="container" ref={this.containerRef}>
                                {this.state.calendars.map((calendar, calendarKey) =>

                                    <div key={calendarKey} className={`calendar ${this.isCurrentCalendar(calendar) ? 'initial' : ''}`}>

                                        <div className="month-year">
                                            {this.state.monthYears[calendarKey]}
                                        </div>

                                        <div className="week-days">
                                            {this.getWeekDays().map((weekDay, weekDayKey) =>
                                                <div key={weekDayKey} className="week-day">
                                                    {weekDay}
                                                </div>
                                            )}
                                        </div>

                                        {calendar.map((week, weekKey) =>
                                            <div key={weekKey} className="week">
                                                {week.days.map((day, dayKey) =>
                                                    <ThemeProvider key={dayKey} theme={this.theme}>
                                                        <DayDiv
                                                            onClick={() => this.selectDate(day, calendarKey)}
                                                            className={`day ${this.getClasses(day, week, calendar)}`}>
                                                            <span>{day.format('D')}</span>
                                                        </DayDiv>
                                                    </ThemeProvider>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <span/>;
        }

    };
}

