import moment from "moment";

const defaultState = () => {

    return {
        checkin: moment().locale('nl'),
        checkout: moment().locale('nl').add(1, 'days'),
        adults: 2,
        children: 0,
        infants: 0,
        language: 'nl',
        currency: 'EUR',
    }
};

export default (state = defaultState(), action) => {

    switch (action.type) {

        case 'SET_DATES':

            return {
                ...state,
                checkin: action.checkin,
                checkout: action.checkout,
            };

        case 'SET_ADULTS':
            return {
                ...state,
                adults: action.adults,
            };

        case 'SET_CHILDREN':
            return {
                ...state,
                children: action.children,
            };

        case 'SET_INFANTS':
            return {
                ...state,
                infants: action.infants,
            };

        case 'SET_LANGUAGE':

            const newCheckin = state.checkin.clone().locale(action.language)
            const newCheckout = state.checkout.clone().locale(action.language)

            return {
                ...state,
                language: action.language,
                checkin: newCheckin,
                checkout: newCheckout,
            };

        case 'SET_CURRENCY':
            return {
                ...state,
                currency: action.currency,
            };

        default:
            return state;
    }
};

