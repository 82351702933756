import axios from "axios";
import {
    setCorporateMessageShow,
    setCorporatePasswordInvalid,
    setCorporatePasswordValid
} from "./settings";

export const setAvailability = (availability) => ({
    type: 'SET_AVAILABILITY',
    availability: availability,
});

export const resetAvailability = () => ({
    type: 'RESET_AVAILABILITY',
});

export const startSetAvailability = (propertyId, checkin, checkout, cp=null) => {

    return (dispatch) => {

        // reset rooms
        dispatch(resetAvailability());

        // do request
        const checkinString = checkin.format('YYYY-MM-DD')
        const checkoutString = checkout.format('YYYY-MM-DD')

        // create url
        let resourceUrl = `${process.env.API_URL}/v1/properties/${propertyId}/availability/?checkin=${checkinString}&checkout=${checkoutString}`;

        // check if cp needs to be added
        if (cp !== null) {
            resourceUrl += `&cp=${cp}`;
        }

        axios.get(resourceUrl)
            .then(function (response) {
                if (response.status === 200) {

                    dispatch(setAvailability(response.data));

                    if (cp !== null) {
                        if (response.data.corporate_error) {
                            dispatch(setCorporatePasswordInvalid(true));
                        } else {
                            dispatch(setCorporatePasswordValid(true));
                        }
                        dispatch(setCorporateMessageShow(true));
                    }

                } else {
                    alert('Something wen\'t wrong');
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }
};
