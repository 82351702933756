const defaultState = () => {
    return [];
};

export default (state = defaultState(), action) => {

    let stateClone = [...state];
    let newItems = [];

    switch (action.type) {

        case 'ADD_EXTRA_TO_ITEM':

            if (stateClone) {
                for (const itemKey in stateClone) {
                    if (stateClone.hasOwnProperty(itemKey)) {
                        if (stateClone[itemKey].id === action.itemId) {
                            stateClone[itemKey]['extras'].push(action.extraId)
                            newItems.push(stateClone[itemKey])
                        } else {
                            newItems.push(stateClone[itemKey])
                        }
                    }
                }
            }

            return newItems;

        case 'REMOVE_EXTRA_FROM_ITEM':

            if (stateClone) {
                for (const itemKey in stateClone) {
                    if (stateClone.hasOwnProperty(itemKey)) {
                        if (stateClone[itemKey].id.toString() === action.itemId.toString()) {
                            const index = stateClone[itemKey]['extras'].indexOf(action.extraId);
                            if (index !== -1) {
                                stateClone[itemKey]['extras'].splice(index, 1);
                            }
                            newItems.push(stateClone[itemKey])
                        } else {
                            newItems.push(stateClone[itemKey])
                        }
                    }
                }
            }

            return newItems;

        case 'ADD_TO_CART':

            // check current state, and delete if needed
            if (stateClone) {
                for (const itemKey in stateClone) {
                    if (stateClone.hasOwnProperty(itemKey)) {
                        if (stateClone[itemKey].id !== action.item.id) {
                            newItems.push(stateClone[itemKey]);
                        }
                    }
                }
            }

            // add to items
            newItems.push(action.item);

            // return state
            return newItems;

        case 'REMOVE_FROM_CART':

            // check current state, and delete
            if (stateClone) {
                for (const itemKey in stateClone) {
                    if (stateClone.hasOwnProperty(itemKey)) {
                        if (stateClone[itemKey].id !== action.item.id) {
                            newItems.push(stateClone[itemKey]);
                        }
                    }
                }
            }

            // return state
            return newItems;

        case 'EMPTY_CART':

            return defaultState();

        default:
            return state;
    }
};
