import React from "react";
import {connect} from "react-redux";
import {motion} from "framer-motion"
import {Col} from "react-grid-system";
import {getContent} from "../../../utils/content";
import translate from "../../../i18n/translate";
import styled, {ThemeProvider} from "styled-components";
import {Link} from "react-router-dom";
import Button from "../../generic/Button";
import Currency from "../../generic/Currency";
import {calculateDiscount, getTotalPriceByRateAndGuest} from "../../../utils/rates";
import TagManager from "react-gtm-module";

const variants = {
    visible: {opacity: 1, scale: 1},
    hidden: {opacity: 0, scale: 0}
};

const PackageDealTag = styled.div`
    background-color: ${props => props.theme.colors.primary};
`;

const StartingAtDiv = styled.div`
    color: ${props => props.theme.colors.primary};
`;

const PackageHighlightDiv = styled.div`
    border-color: ${props => props.theme.colors.primary};
`;

class PackageListItem extends React.Component {

    state = {
        packageInfo: null,
        nights: null,
    }

    theme = {
        colors: this.props.property.settings.colors,
    };

    componentDidMount = () => {
        this.setState({
            packageInfo: this.getPackageInfo(),
            nights: this.props.query.checkout.diff(this.props.query.checkin, 'days')
        });
    }

    getPackageInfo = () => {
        let packageInfo = null;
        for (const packageKey in this.props.property.packages) {
            if (this.props.property.packages.hasOwnProperty(packageKey)) {
                if (this.props.property.packages[packageKey].id === this.props.package.id) {
                    packageInfo = this.props.property.packages[packageKey];
                }
            }
        }
        return packageInfo;
    }

    getLowestPrice = () => {

        // get lowest price
        let lowestOccupancy = false;
        let lowestPrice = false;
        if (this.props.package.rooms) {
            for (const roomIndex in this.props.package.rooms) {
                if (this.props.package.rooms.hasOwnProperty(roomIndex)) {

                    // get prices
                    const room = this.props.package.rooms[roomIndex];
                    const totalPrice = getTotalPriceByRateAndGuest(room.rate, 'adult', room.min_occupancy);
                    if (!lowestPrice || totalPrice < lowestPrice) {
                        lowestPrice = totalPrice;
                    }
                    if (!lowestOccupancy || room.min_occupancy < lowestOccupancy) {
                        lowestOccupancy = room.min_occupancy;
                    }
                }

            }
        }

        if (lowestPrice) {

            // return lowest for room
            lowestPrice = (lowestPrice / 100);

            // get discount
            let discount = null;
            if (this.props.promo.discount) {
                discount = calculateDiscount(this.props.promo.discount, lowestPrice, lowestOccupancy, this.state.nights)
            }

            // return
            if (this.props.property.settings.interface.show_price_per_night) {
                if (discount) {
                    return (
                         <span className="discounted-rate">
                             <Currency>{parseFloat(discount.originalRate / this.state.nights)}</Currency> <Currency>{parseFloat(discount.discountedRate / this.state.nights)}</Currency>
                         </span>
                    )
                } else {
                    return <Currency>{parseFloat(lowestPrice / this.state.nights)}</Currency>;
                }
            } else {
                if (discount) {
                    return (
                        <span className="discounted-rate">
                            <Currency>{discount.originalRate}</Currency> <Currency>{discount.discountedRate}</Currency>
                        </span>
                    )
                } else {
                    return <Currency>{lowestPrice}</Currency>;
                }
            }
        } else {
            return null;
        }
    }

    getOccupancy = () => {

        if (this.props.package.rooms) {
            let lowestOccupancy = null;
            let highestOccupancy = null;
            for (const packageRoom of this.props.package.rooms) {
                if (!lowestOccupancy || packageRoom.min_occupancy < lowestOccupancy) {
                    lowestOccupancy = packageRoom.min_occupancy;
                }
                if (!highestOccupancy || packageRoom.max_occupancy > highestOccupancy) {
                    highestOccupancy = packageRoom.max_occupancy;
                }
            }
            if (lowestOccupancy === highestOccupancy) {
                return lowestOccupancy;
            } else {
                return lowestOccupancy + '-' + highestOccupancy;
            }
        } else {
            return null;
        }
    }

    hasSelectedFilters = () => {

        // check setting filters
        if (!this.props.settings.filters) {
            return true;
        }

        // get package filters
        let packageFilters = [];
        if (this.state.packageInfo.filters) {
            for (const packageFilterKey in this.state.packageInfo.filters) {
                if (this.state.packageInfo.filters.hasOwnProperty(packageFilterKey)) {
                    packageFilters.push(`dynamic-${this.state.packageInfo.filters[packageFilterKey].id}`);
                }
            }
        }

        // add package type
        packageFilters.push('type-packages');

        // add occupancy to available filters
        if (this.props.package.rooms) {

            // get lowest and highest occupancy
            let lowestOccupancy = null;
            let highestOccupancy = null;
            for (const packageRoom of this.props.package.rooms) {
                if (!lowestOccupancy || packageRoom.min_occupancy < lowestOccupancy) {
                    lowestOccupancy = packageRoom.min_occupancy;
                }
                if (!highestOccupancy || packageRoom.max_occupancy > highestOccupancy) {
                    highestOccupancy = packageRoom.max_occupancy;
                }
            }

            for (let i = lowestOccupancy; i <= highestOccupancy; i++) {
                packageFilters.push(`occupancy-${i}`);
            }
        }

        // check filters
        for (const settingsFilterKey in this.props.settings.filters) {
            if (this.props.settings.filters.hasOwnProperty(settingsFilterKey)) {
                const settingsFilter = this.props.settings.filters[settingsFilterKey];
                if (!packageFilters.includes(settingsFilter)) {
                    return false;
                }
            }
        }

        // everything is fine
        return true;

    }

    render = () => {

        this.getOccupancy();

        if (this.state.packageInfo && this.hasSelectedFilters()) {

            return (

                <ThemeProvider theme={this.theme}>

                    <Col xl={4} lg={6} md={12}>


                        <motion.div
                            animate={{opacity: 1}}
                            whileHover={{y: -3}}
                            className={!this.props.package.available ? `list-item unavailable` : `list-item`}
                        >

                            <div className="image border-radius">
                                <img src={this.state.packageInfo.images[0].file}
                                     alt={this.state.packageInfo.images[0].description}/>
                            </div>

                            <div className="item-title">
                                <h3>{getContent(this.state.packageInfo.names, this.props.query.language, this.state.packageInfo.external_name)}</h3>
                            </div>

                            <div className="item-tags">

                                <PackageDealTag className="tag primary mr-xs">
                                    <i className="fas fa-gift"/>
                                    <span>{translate('package-deal', this.props.query.language, this.props.translations)}</span>
                                </PackageDealTag>

                                {this.props.package.los !== 0 && (
                                    <div className="tag mr-xs">
                                        <i className="fas fa-info-circle"/>
                                        <span>
                                            {this.props.package.los + 1}&nbsp;
                                            {translate('day-stay', this.props.query.language, this.props.translations)}
                                        </span>
                                    </div>
                                )}

                                {!this.props.package.available && (
                                    <div className="tag error">
                                        <i className="fas fa-frown"/>
                                        <span>{translate('unavailable', this.props.query.language, this.props.translations)}</span>
                                    </div>
                                )}

                            </div>

                            <div className="item-cta">

                                {this.props.package.available ? (
                                    <div className="item-rate">
                                        <StartingAtDiv className="item-starting-at">{translate('starting-at', this.props.query.language, this.props.translations)}</StartingAtDiv>
                                        {this.props.property.settings.interface.show_price_per_night ? (
                                            <div className="item-price">
                                                {this.getLowestPrice()}
                                                <div> / {translate('per-night', this.props.query.language, this.props.translations)}</div>
                                            </div>
                                        ) : (
                                            <div className="item-price">
                                                {this.getLowestPrice()}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="item-rate">
                                        <StartingAtDiv className="item-starting-at">{translate('starting-at', this.props.query.language, this.props.translations)}</StartingAtDiv>
                                        <div className="item-price">0</div>
                                    </div>
                                )}

                                <div className="item-button">
                                    <Link to={`/${this.props.property.id}/packages/${this.props.package.id}/`}>
                                        {this.props.package.available ? (
                                            <Button
                                                type={'primary'}>{translate('show-rates', this.props.query.language, this.props.translations)}</Button>
                                        ) : (
                                            <Button
                                                type={'primary'}>{translate('show', this.props.query.language, this.props.translations)}</Button>
                                        )}
                                    </Link>
                                </div>
                            </div>

                        </motion.div>

                    </Col>
                </ThemeProvider>
            );
        } else {
            return <span/>
        }
    };
}

const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        promo: state.promo,
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};


export default connect(mapStateToProps)(PackageListItem);
