import React from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom"
import ItemOccupancyPackage from "./ItemPackageOccupancy";

class ItemPackageRooms extends React.Component {

    state = {
        selectedPackageAvailability: null,
        items: []
    };

    componentDidMount = () => {
        if (!this.state.selectedPackageAvailability && this.props.availability) {
            this.setState({selectedPackageAvailability: this.getPackageAvailability()});
        }
    }

    getPackageAvailability = () => {
        for (const packageKey in this.props.availability.packages) {
            if (this.props.availability.packages.hasOwnProperty(packageKey)) {
                if (this.props.availability.packages[packageKey].id === this.props.item.id) {
                    return this.props.availability.packages[packageKey];
                }
            }
        }
        return null;
    }


    render = () => {

        if (this.state.selectedPackageAvailability) {

            return (
                <div className="item-package-rooms mb-xl">
                    {this.state.selectedPackageAvailability.rooms.map((packageRoom, packageRoomKey) =>
                        <ItemOccupancyPackage key={packageRoomKey} item={this.props.item} packageRoom={packageRoom}/>
                    )}
                </div>
            )
        } else {
            return <span/>
        }


    }
}

const mapStateToProps = (state) => {
    return {
        availability: state.availability,
        items: state.items,
        property: state.property,
        query: state.query,
        settings: state.settings,
        translations: state.translations,
    };
};

export default withRouter(connect(mapStateToProps)(ItemPackageRooms));
